
import Vue from 'vue';
import { Report } from '../../store/modules/scheduledreports/types';
import DataTableFooter from '../components/charts/tables/DataTableFooter.vue';
import utils from '../../util';

export default Vue.extend({
  data(): {
    sortDesc: Array<boolean>;
    totalRecords: number;
    selectedItemsPer: number;
    showDeleteConfirmation: boolean;
    reportToDelete: Report | null;
    search: string;
    searchTimer: Function | null;
    itemsPerPage: number;
    pageNumber: number;
    activeTab: number;
    tabsNames: string[];
  } {
    return {
      sortDesc: [false],
      totalRecords: 10,
      selectedItemsPer: 100,
      showDeleteConfirmation: false,
      reportToDelete: null,
      search: '',
      searchTimer: null,
      itemsPerPage: 10,
      pageNumber: 1,
      activeTab: 0,
      tabsNames: ['advertiser_reports', 'my_reports'],
    };
  },
  props: ['reports', 'loading', 'myReports'],
  components: { DataTableFooter },
  mounted() {
    const newQuery = {
      ...this.$router.currentRoute.query,
      tab: this.tabsNames[0],
    };
    this.$router.push({ query: newQuery });
  },
  methods: {
    rowClick(report: Report): void {
      if (this.canRead) {
        this.loadReport(report.PropertyId);
      }
    },
    loadReport(id: string): void {
      let query;
      if (this.$route.query?.advertiser) {
        query = { reportId: id, advertiser: this.$route.query?.advertiser };
      } else if (this.$route.query?.agency) {
        query = { reportId: id, agency: this.$route.query?.agency };
      }
      this.$router.push({ name: 'scheduledreport', query });
    },
    getIntervalString(report: Report): string {
      const interval = report.IntervalType?.toLowerCase();
      const oneTime = report?.OneTimeOnly ? ' - One Time' : '';
      if (report.IntervalType) {
        //  NOTE: eventually handle 'daily'
        let dayOf = '';
        if (interval === 'weekly' && report.DayOfWeek) {
          dayOf = `(${report.DayOfWeek})`;
        } else if (interval === 'monthly' && report.DayOfMonth) {
          dayOf = `(${report.DayOfMonth})`;
        } else if (interval === 'quarterly' && report.DayOfQuarter) {
          dayOf = `(${report.DayOfQuarter})`;
        }
        return `${report.IntervalType}  ${dayOf}${oneTime}`;
      }
      return '-';
    },
    updatePage(page: number): void {
      this.pageNumber = page;
      const offset = (this.pageNumber - 1) * this.itemsPerPage;
      this.$emit('update-results', { search: this.search, offset });
    },
    showDeleteDialog(report: Report): void {
      this.reportToDelete = report;
      this.showDeleteConfirmation = true;
    },
    deleteReport(): void {
      if (!this.canEdit) return;
      // pass the report ID, report Name, and advertiser Name to mixpanel
      this.$emit('delete-report', {
        reportId: this.reportToDelete.PropertyId,
        reportName: this.reportToDelete.Name,
        advertiser: this.$route.query?.advertiser,
        search: this.search,
        createdBy: this.reportToDelete.CreatedBy,
      });
      setTimeout(() => {
        this.pageNumber = 1;
        this.reportToDelete = null;
      }, 500);
    },
    getProductNames(products: string[]): string {
      return products.map((tactic: string) => utils.getTacticName(tactic)).join(', ');
    },
    changeTab(tabsName) {
      const newQuery = {
        ...this.$router.currentRoute.query,
        tab: tabsName,
      };
      this.$router.push({ query: newQuery });
      this.updatePage(1);
    },
  },
  computed: {
    headers(): Array<{ text: string; align?: string; sortable?: boolean; value: string }> {
      const arr = [
        { text: 'Name of the Report', value: 'Name', sortable: false },
        { text: 'Advertiser', value: 'ParentAdvertiser.Name', sortable: false },
        { text: 'Scheduled', value: 'IntervalType', sortable: false },
        { text: 'Send to ', value: 'EmailTo', sortable: false },
        { text: 'Product', value: 'Products', sortable: false },
        { text: 'Actions', value: 'Actions', sortable: false },
      ];
      return arr;
    },
    hasEnoughData(): boolean {
      if (this.activeTab === 0) {
        return this.reports?.results?.length > 0;
      } else {
        return this.myReports?.results?.length > 0;
      }
    },
    numberOfPages(): number {
      if (this.activeTab === 0) {
        return Math.ceil(this.reports?.total / this.itemsPerPage);
      } else {
        return Math.ceil(this.myReports?.total / this.itemsPerPage);
      }
    },
    canRead(): boolean {
      return utils.hasRight(this.$store.getters.user, ['AGENCY_READ_SCHEDULED_REPORT', 'READ_SCHEDULED_REPORT']);
    },
    canEdit(): boolean {
      return utils.hasRight(this.$store.getters.user, ['AGENCY_EDIT_SCHEDULED_REPORT', 'EDIT_SCHEDULED_REPORT']);
    },
  },
  watch: {
    search: {
      handler(): void {
        clearTimeout(this.searchTimer);
        if (this.search.length >= 3 || this.search.length === 0) {
          this.searchTimer = setTimeout(() => {
            this.pageNumber = 1;
            this.$emit('update-results', { search: this.search, offset: 0 });
          }, 500);
        }
      },
    },
  },
});
