
import Vue from 'vue';
import { ThemeConfig } from './plugins/vuetify/themes/variables';
import HeaderLogoC360 from './pages/components/toolbar/headerLogoC360.vue';
import Status from './pages/global/status.vue';
import { C360Provider, getCssVar, defineAbilityFor } from '@c360/ui';
import analytics from './mixins/analytics';
import jwtDecode from 'jwt-decode';

import util from './util';

let mutations: () => void;
((): void => {
  // todo -> utils
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const throttle = (type: string, name: string, obj: any): void => {
    obj = obj || window;
    let running = false;
    const func = (): void => {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(() => {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    obj.addEventListener(type, func);
  };
  throttle('resize', 'optimizedResize', window);
})();

export default {
  components: {
    HeaderLogoC360,
    C360Provider,
    AppGlobals: () => import('./pages/global/globals.vue'),
    C360Footer: () => import('./pages/global/footerC360.vue'),
    Status,
  },
  mixins: [analytics],
  data(): {
    waitingForDashboard: boolean;
    themeName: string;
    primaryColor: string;
    refreshToken: string;
  } {
    return {
      waitingForDashboard: false,
      themeName: '',
      primaryColor: '#b3b3b3',
      refreshToken: '',
    };
  },
  computed: {
    accessToken() {
      return localStorage.getItem('ah:accessToken');
    },
    refreshTokenLS() {
      return localStorage.getItem('ah:refreshToken');
    },
    ability() {
      return defineAbilityFor({
        isSuperUser: this.$store.state?.customer?.user?.is_superuser ?? false,
        isAgencyAdmin: util.hasRight(this.$store.state?.customer?.user, ['AGENCY_ADMIN']) ?? false,
        products: this.$store.state?.customer?.user?.products ?? [],
        permissions: this.$store.state?.customer?.user?.permissions ?? [],
        activeAgencyName: this.$store.state?.customer?.user?.active_agency_id ?? '',
        tenantsCount: this.$store.state?.customer?.user?.AvailableAgencies?.length ?? 1,
      });
    },
    c360Path(): string {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
    error(): string {
      return this.$store.state.customer?.error?.message || '';
    },
    waiting(): boolean {
      return this.waitingForDashboard || this.$store.state.customer?.waitingForAuth;
    },
    appClass(): Array<string> {
      const classes = {
        theme: this.Theme?.name,
        env: util.getEnvForProductLink(true) !== 'prod' ? 'adjusted' : '',
      };
      return [classes.theme, classes.env];
    },
    buildInfo(): string {
      if (process && process.env && process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION.length > 8) {
        return `v.1.${process.env.VUE_APP_BUILD_VERSION.substr(3, 6)}`;
      }
      return 'n/a';
    },
  },
  watch: {
    '$store.state.customer.user.accessToken': {
      handler(newState: boolean): void {
        this.refreshToken = newState;
        this.handleMixpanelSuperprops(newState);
      },
    },
    '$route.query.t': {
      handler(newToken: string, oldToken: string): void {
        if (newToken && newToken !== oldToken) {
          this.nothingToRender = false;
          this.getSharedDashboard({ token: newToken });
        }
      },
      immediate: false,
    },
    '$route.query.gct': {
      handler(newToken: string, oldToken: string): void {
        if (newToken && newToken !== oldToken) {
          this.nothingToRender = false;
          this.getSharedGoogleCredentials(newToken, false);
        }
      },
      immediate: false,
    },
    '$route.query.gctnew': {
      handler(newToken: string, oldToken: string): void {
        if (newToken && newToken !== oldToken) {
          this.nothingToRender = false;
          this.getSharedGoogleCredentials(newToken, true);
        }
      },
      immediate: false,
    },
    '$route.params.shortId': {
      handler(newToken: string, oldToken: string): void {
        if (newToken && newToken !== oldToken) {
          this.nothingToRender = false;
          this.waitingForDashboard = true;
          this.getSharedDashboard({ shortId: newToken });
        }
      },
      immediate: false,
    },
  },
  created(): void {
    const theme: ThemeConfig = Vue.prototype.Theme;
    if (!theme?.font?.family?.toLowerCase().includes('roboto')) {
      // if using a font other than Roboto, write the font link to the <head>
      let googleFontUrl = 'https://fonts.googleapis.com/css?family=';
      switch (theme?.font?.family) {
        case '"Lato", sans-serif':
          googleFontUrl = `${googleFontUrl}Lato:100,300,400,700,900`;
          break;
        case '"Montserrat", sans-serif':
          googleFontUrl = `${googleFontUrl}Montserrat:100,300,400,500,700,900`;
          break;
        default:
          googleFontUrl = `${googleFontUrl}Roboto:100,300,400,500,700,900`;
          break;
      }
      const fontLink = document.createElement('link');
      fontLink.href = googleFontUrl;
      fontLink.rel = 'stylesheet';
      document.head.append(fontLink);
    }

    // change favicon if Theme has it set
    const favicon = document.querySelector("link[rel~='icon']") as HTMLAnchorElement;
    favicon.href = Vue.prototype.$c360.favicon;
    document.getElementsByTagName('meta')['theme-color'].content = getCssVar('--c360-background-primary');
  },
  mounted(): void {
    this.refreshToken = this.$store.state?.customer?.user?.refreshToken;
    if (this.$route.query.t || this.$route.query.gct || this.$route.query.gctnew) {
      this.waitingForDashboard = true;
    }

    setInterval(this.observeDom, 1000);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutations = this.$store.subscribe((mutation: any) => {
      switch (mutation.type) {
        case 'SET_SHARE_CONTENT_LOADING':
          this.waitingForDashboard = !!mutation.payload;
          break;
        default:
          break;
      }
    });

    // if after a few seconds of coming in, we don't have a user, check if something is broken
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const authCheck: any = this.$store.state.customer.user;
      if (!authCheck) {
        this.$store.dispatch('getAuth').catch((error: Error) => {
          this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
        });
      }
    }, 5000);

    setTimeout(() => {
      this.primaryColor = getCssVar('--c360-color-compulse');
    }, 200);
  },
  beforeDestroy(): void {
    mutations();
  },
  methods: {
    handleMixpanelSuperprops(token = ''): void {
      const decoded_data = jwtDecode(token);
      const station_id = decoded_data?.active_station_id;

      if (station_id) {
          this.$store.dispatch('getStation', station_id).then(station => {
          decoded_data['active_station_name'] = station?.name;
          this.registerMixpanelSuperprops(decoded_data);
        });
      } else {
        this.registerMixpanelSuperprops(decoded_data);
      }
    },
    getSharedDashboard({ token, shortId }: { token?: string; shortId?: string }): void {
      this.$store.dispatch('setSharedView');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const applyDashboard = async (d: any): Promise<void> => {
        this.$store.dispatch('setAdvertisers', [d.advertiser]);
        await this.$store
          .dispatch('getLayoutsGQL', {
            agency: d.theme,
            advertiserId: d.aid,
            tactic: d?.tab,
            token: d?.etoken,
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((layout: any): void => {
            if (layout) {
              d.layout = layout;
              this.$store.dispatch('setLayout', layout).then().catch();
              return;
            }
            // reject(new Error('no layout for user'));
          })
          .catch();

        this.$store.dispatch('setAdPerformance', d.adData);
        this.$store.dispatch('setCampaignAdPerformance', d.adData);

        this.$store.dispatch('setCampaigns', {
          campaignlist: d?.Campaign,
          defaultdaterange: d?.daterange,
        });

        this.$store.dispatch('setFilters', {
          CampTypes: d?.CampTypes,
          StateList: d?.StateList,
        });

        let campaignslist;

        if (d && d.Campaign && Array.isArray(d.Campaign) && d.Campaign.length > 0) {
          const viewCampaignsList: string[] = d.viewCampaigns ? d.viewCampaigns?.split(',') : null;
          const selectedCampaignsList: string[] = d.selectedCampaigns ? d.selectedCampaigns?.split(',') : null;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const campaigns = viewCampaignsList ? d.Campaign.filter((c: any) => viewCampaignsList.includes(c.id)) : [];
          // if no selected campaigns found use shared campaigns
          const selected = selectedCampaignsList
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              d.Campaign.filter((c: any) => selectedCampaignsList.includes(c.id))
            : campaigns;
          campaignslist = d.Campaign.map(c => c.id).join(',');
          if (selected) {
            this.$store.dispatch('setSelectedCampaigns', selected);
          }
          if (campaigns) {
            this.$store.dispatch('setSharedCampaigns', campaigns);
          }
        }
        // set custome range type if start and endate present
        if (!d?.daterange && d?.startdate && d?.enddate) d.daterange = 'customRange';
        this.$store
          .dispatch('setSelection', {
            advertiserName: d.advertiserName,
            advertiserLogo: d?.advertiserLogo,
            agencyPartner: d.agencyPartner,
            advertiserId: d.aid,
            theme: d.theme,
            daterange: d.daterange,
            campaigns: d.campaigns ? d.campaigns : campaignslist,
            startdate: d.startdate,
            enddate: d.enddate,
            baseDate: d.baseDate,
            campaigndaterange: d.campaigndaterange,
            campaignstartdate: d.campaignstartdate,
            campaignenddate: d.campaignenddate,
            tab: d.tab,
            viewCampaigns: d.viewCampaigns,
            allDateAccess: d.allDateAccess,
            summaryView: d.summaryView,
          })
          .then(() => {
            this.$store.dispatch('setSharedSelection', d);
            this.$store.dispatch('setSharedLinkReady');
          })
          .catch((exp: Error) => {
            // eslint-disable-next-line no-console
            console.error(exp);
          });
      };
      this.$store
        .dispatch('getSharedDashboard', { token, shortId })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((d: any) => {
          if (!d) {
            // eslint-disable-next-line no-console
            console.error('getSharedDashboard failed');
            return;
          }
          if (d && d.layout && d.layout.sections && d.layout.sections.length > 0) {
            if (shortId) {
              applyDashboard(d);
            } else {
              this.$router
                .replace({
                  name: 'share',
                })
                .then(() => {
                  applyDashboard(d);
                });
            }
          } else {
            applyDashboard(d);
          }
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
    getSharedGoogleCredentials(token: string, isNew: boolean): void {
      this.$store
        .dispatch('getSharedGoogleCredentials', { token })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((d: any) => {
          if (!d) {
            // eslint-disable-next-line no-console
            console.error('getSharedGoogleCredentials failed');
            this.$store.dispatch('showError', 'getSharedGoogleCredentials failed');
            return;
          }

          if (d && d.id) {
            this.$store.dispatch('setGoogleCredentialsId', d.id);
            this.$store.dispatch('setSharedLinkReady');
            if (isNew) {
              this.$router.replace({
                name: 'googlecredentialsnew',
                query: {
                  site: this.$route.query?.site,
                  agency: this.$route.query?.agency,
                  framed: this.$route.query?.framed,
                },
              });
            } else {
              this.$router.replace({
                name: 'googlecredentials',
                query: {
                  site: this.$route.query?.site,
                  agency: this.$route.query?.agency,
                  framed: this.$route.query?.framed,
                },
              });
            }
          } else {
            this.$store.dispatch('showError', 'getSharedGoogleCredentials failed');
            return;
          }
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
  },
};
