
import Vue from 'vue';
import { Advertiser } from '../../../types/advertisers';
import xlsx from 'json-as-xlsx';
import { format } from 'date-fns';
import utils from '../../../util';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'AdvertiserList',
  props: [
    'advertiser',
    'advertiserName',
    'advertiserData',
    'partnerFilter',
    'currentSection',
    'campaignData',
    'advertisersOnly',
  ],
  components: {
    SearchBar: () => import('./searchBar.vue'),
    ExportMenu: () => import('../../components/toolbar/menus/exportMenu.vue'),
    C360Icon,
  },
  data: (): {
    selectedItem: Number;
    selectedPartners: object | null;
    optimumSportsFormattedName: String;
  } => ({
    selectedItem: -1,
    selectedPartners: null,
    optimumSportsFormattedName: 'Optimum_Sports',
  }),
  computed: {
    areAdvertisersExportable(): boolean {
      if (this.loading) return false;
      return this.isShowAdvertiserList
        ? this.selectedPartners?.value === this.optimumSportsFormattedName
        : this.$store.getters.user.Agency === this.optimumSportsFormattedName;
    },
    agencyName(): string {
      const user = this.$store.getters.user;
      return this.isShowAdvertiserList ? this.selectedPartners.value : user.Agency;
    },
    loading(): boolean {
      return this.$store.state.linearAdmin?.loading;
    },
    advertiserTotal(): string | null {
      return this.advertiserData?.total ? `(${this.advertiserData?.total})` : null;
    },
    advertiserPageCount(): string | null {
      return this.advertiserData?.total > 100
        ? 'Showing the first 100 advertisers. Please search to narrow your results.'
        : null;
    },
    hasCampaignData(): boolean {
      return this.campaignData?.length;
    },
    formatedExportData() {
      if (!this.hasCampaignData) return null;
      return {
        advertiserId: this.advertiser,
        advertiserName: this.advertiserName,
        campaigns: '',
        daterange: 'alltime',
        tab: 'summary',
      };
    },
    filterMessage(): string {
      return this.$store.state.linearAdmin?.loading ? 'Loading filter' : 'N/A';
    },
    isShowAdvertiserList(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['SHOW_ADVERTISER_LIST']);
    },
    hasData(): boolean {
      return this.advertiserData && this.advertiserData.advertisers && this.advertiserData.advertisers?.length > 0;
    },
  },
  methods: {
    async getAgencyAdvertisers(): Promise<void> {
      if (!this.agencyName) return;
      this.$store.dispatch('showError', {
        message: 'Export started, please wait for download to start...',
        errorType: 'success',
      });
      await this.$store.dispatch('linearAdmin/getAgencyAdvertisers', { agency: this.agencyName }).then(res => {
        if (res.error) {
          this.$store.dispatch('showError', {
            message: 'No advertisers found for this agency',
            errorType: 'error',
          });
          return;
        }
        this.generateXLSX(res);
      });
    },
    generateXLSX(rawData: object[]): void {
      const preparedData = [];
      for (const entry of rawData) {
        const temp = {};
        for (const [key, value] of Object.entries(entry)) {
          const formattedKey = key.toLowerCase().replaceAll(' ', '_');
          temp[formattedKey] = value.value;
        }
        preparedData.push(temp);
      }
      const data = [
        {
          sheet: `${this.agencyName} advertisers`,
          columns: [
            { label: 'Advertiser Name', value: 'advertiser_name' },
            { label: 'Product Name', value: 'product_name' },
            { label: 'Campaign Name', value: 'campaign_name' },
            { label: 'Start Date', value: 'start_date' },
            { label: 'End Date', value: 'end_date' },
            { label: 'Household Impressions', value: 'household_impressions' },
            { label: 'Age 18-49 Impressions', value: 'age_18-49_impressions' },
            { label: 'Age 25-54 Impressions', value: 'age_25-54_impressions' },
            { label: 'Impressions', value: 'impressions' },
            { label: 'Clicks', value: 'clicks' },
            { label: 'CTR', value: 'ctr' },
            { label: '100% Viewed', value: '100_viewed' },
            { label: 'VCR', value: 'vcr' },
            { label: 'Times Aired', value: 'times_aired' },
            { label: 'Visits', value: 'visits' },
          ],
          content: preparedData,
        },
      ];

      const settings = {
        fileName: `${this.agencyName}_advertisers_summary_${format(new Date(), 'yyyy-MM-dd-HH-mm-ss')}`,
        extraLength: 3,
        writeMode: 'writeFile',
        writeOptions: {},
      };

      xlsx(data, settings);
    },
    isSelected(id: string): boolean {
      return this.advertiser === id;
    },
    advertiserSelect(advertiser: Advertiser): void {
      this.$emit('advertiserSelect', advertiser);
    },
    updateSearch(params: { [key: string]: string }): void {
      this.$emit('updateSearch', params);
    },
    changePartner(params: { [key: string]: string }): void {
      this.$emit('changePartner', params);
    },
    openAdvertiser(advertiser: Advertiser): void {
      this.$emit('openAdvertiser', advertiser);
    },
    formatElement(data): Array<object> {
      if (!data) return [];
      const items = Array.isArray(data) ? data : [data];
      return items.map(d => {
        return {
          value: d.Value_Agency_Id,
          text: `${d.Value_Agency_Id} (${d.Count})`,
        };
      });
    },
  },
  watch: {
    advertiserData: {
      handler(): void {
        this.selectedItem = -1;
      },
    },
  },
});
