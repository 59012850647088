
import Vue from 'vue';
import utils from '../../../util';

export default Vue.extend({
  inheritAttrs: false,
  name: 'siteTrafficBar',
  props: {
    values: Object,
    hideText: Boolean,
    standAlone: Boolean,
    gradient: Array,
  },
  computed: {
    metricValueClasses(): string[] {
      const classes: string[] = [];
      classes.push('grey--text');
      classes.push('text-subtitle-2');
      classes.push('font-weight-medium');
      return classes;
    },
    progressContainerStyles(): object {
      return {
        background: '#efefef',
        height: this.barHeight ? this.barHeight : '15px',
      };
    },
    progressStyles(): object {
      let bgColor;
      // console.log(this.gradient);
      if (this.gradient) bgColor = `linear-gradient(98deg,${this.gradient[0]} 24%, ${this.gradient[1]} 100%)`;
      else bgColor = this.Theme.light.progressBarGradient;

      const styles = {
        background: bgColor,
      };
      return styles;
    },
  },
  methods: {
    formatNumber(num: string): string {
      return utils.formatNumberWithCommas(parseInt(num));
    },
  },
});
