import requests from './requests';
import { logError } from '../../index';
import CONSTANTS from './constants';
import { GetReportPayload, AgencySearchPayload, AdvertiserSearchPayload } from './types';
import { AxiosResponse } from 'axios';

export default {
  async getAgencyReports({ commit }, payload: AgencySearchPayload): Promise<object> {
    try {
      if (typeof payload === 'undefined') {
        commit(CONSTANTS.SET_ERROR, 'Missing required search criteria');
        return;
      }
      if (!payload.agency) {
        commit(CONSTANTS.SET_ERROR, 'Missing agency ID');
        return;
      }

      // only add params that were passed in
      const params = [];
      params.push(`agency=${payload.agency}`);
      params.push(`search=${payload.search || ''}`);
      if (payload.offset) params.push(`offset=${payload.offset}`);

      commit(CONSTANTS.SET_REPORTS, null);
      commit(CONSTANTS.SET_LOADING, true);
      commit(CONSTANTS.SET_ERROR, null);
      const url = `/api/scheduledReportSearchAgency/?${params.join('&')}`;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any> = await requests.searchReports(url);

      if (response.data?.error) {
        commit(CONSTANTS.SET_ERROR, response.data.error);
        return;
      }

      commit(CONSTANTS.SET_REPORTS, response.data);
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.scheduledreports.getReports');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async getMyAgencyReports({ commit }, payload: AgencySearchPayload): Promise<object> {
    try {
      if (typeof payload === 'undefined') {
        commit(CONSTANTS.SET_ERROR, 'Missing required search criteria');
        return;
      }
      if (!payload.agency) {
        commit(CONSTANTS.SET_ERROR, 'Missing agency ID');
        return;
      }

      // only add params that were passed in
      const params = [];
      params.push(`agency=${payload.agency}`);
      params.push(`search=${payload.search || ''}`);
      if (payload.offset) params.push(`offset=${payload.offset}`);

      params.push('filterByCurrentUser=true');

      commit(CONSTANTS.SET_MY_REPORTS, null);
      commit(CONSTANTS.SET_LOADING, true);
      commit(CONSTANTS.SET_ERROR, null);
      const url = `/api/scheduledReportSearchAgency/?${params.join('&')}`;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any> = await requests.searchReports(url);

      if (response.data?.error) {
        commit(CONSTANTS.SET_ERROR, response.data.error);
        return;
      }

      commit(CONSTANTS.SET_MY_REPORTS, response.data);
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.scheduledreports.getReports');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async getAdvertiserReports({ commit }, payload: AdvertiserSearchPayload): Promise<object> {
    try {
      if (typeof payload === 'undefined') {
        commit(CONSTANTS.SET_ERROR, 'Missing required search criteria');
        return;
      }
      if (!payload.advertiserId) {
        commit(CONSTANTS.SET_ERROR, 'Missing advertiser ID');
        return;
      }

      // only add params that were passed in
      const params = [];
      params.push(`advertiserId=${payload.advertiserId}`);
      params.push(`search=${payload.search || ''}`);
      if (payload.offset) params.push(`offset=${payload.offset}`);

      commit(CONSTANTS.SET_REPORTS, null);
      commit(CONSTANTS.SET_LOADING, true);
      commit(CONSTANTS.SET_ERROR, null);
      const url = `/api/scheduledReportSearchAdvertiser/?${params.join('&')}`;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any> = await requests.searchReports(url);

      if (response.data?.error) {
        commit(CONSTANTS.SET_ERROR, response.data.error);
        return;
      }

      commit(CONSTANTS.SET_REPORTS, response.data);
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.scheduledreports.getReports');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async getMyAdvertiserReports({ commit }, payload: AdvertiserSearchPayload): Promise<object> {
    try {
      if (typeof payload === 'undefined') {
        commit(CONSTANTS.SET_ERROR, 'Missing required search criteria');
        return;
      }
      if (!payload.advertiserId) {
        commit(CONSTANTS.SET_ERROR, 'Missing advertiser ID');
        return;
      }

      // only add params that were passed in
      const params = [];
      params.push(`search=${payload.search || ''}`);
      if (payload.offset) params.push(`offset=${payload.offset}`);

      params.push('filterByCurrentUser=true');

      commit(CONSTANTS.SET_MY_REPORTS, null);
      commit(CONSTANTS.SET_LOADING, true);
      commit(CONSTANTS.SET_ERROR, null);
      const url = `/api/scheduledReportSearchAdvertiser/?${params.join('&')}`;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any> = await requests.searchReports(url);

      if (response.data?.error) {
        commit(CONSTANTS.SET_ERROR, response.data.error);
        return;
      }

      commit(CONSTANTS.SET_MY_REPORTS, response.data);
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.scheduledreports.getReports');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async getSingleReport({ commit }, payload: GetReportPayload): Promise<object> {
    try {
      commit(CONSTANTS.SET_LOADING, true);
      commit(CONSTANTS.SET_ERROR, null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await requests.getReport(`/api/scheduledReportSingleReport/`, payload);
      return req.data;
    } catch (err) {
      commit(CONSTANTS.SET_ERROR, err.message);
      throw logError(err, 'store.scheduledreports.getReport');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async createReport({ commit }, payload): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_LOADING, true);
      commit(CONSTANTS.SET_ERROR, null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await requests.createReport('/api/scheduledReportCreate', payload);
      if (req.data?.ErrorMessage) {
        commit(CONSTANTS.SET_ERROR, req.data.ErrorMessage);
        return;
      }
      return req.data;
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.scheduledreports.createReport');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async updateReport({ commit }, payload): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_LOADING, true);
      commit(CONSTANTS.SET_ERROR, null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await requests.updateReport('/api/scheduledReportUpdate', payload);
      if (req.data?.ErrorMessage) {
        commit(CONSTANTS.SET_ERROR, req.data.ErrorMessage);
        return;
      }
      return req.data;
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.scheduledreports.updateReport');
    } finally {
      commit(CONSTANTS.SET_LOADING, false);
    }
  },
  async deleteReport({ commit }, payload: GetReportPayload): Promise<object> {
    try {
      commit(CONSTANTS.SET_LOADING, true);
      commit(CONSTANTS.SET_ERROR, null);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await requests.deleteReport('/api/scheduledReportDelete/', payload);
      return req.data;
    } catch (err) {
      commit(CONSTANTS.SET_ERROR, err.message);
      commit(CONSTANTS.SET_LOADING, false);
      throw logError(err, 'store.scheduledreports.deleteReport');
    } finally {
      // turn off loading after we get the updated list.
      // commit(CONSTANTS.SET_LOADING, false);
    }
  },
  setSuccessMessage({ commit }, message) {
    commit(CONSTANTS.SET_SUCCESS_MESSAGE, message);
  },
};
