
import Vue from 'vue';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'SearchBar',
  props: ['placeholder'],
  data: (): {
    search: string;
    searchTimer: Function | null;
  } => ({
    search: '',
    searchTimer: null,
  }),
  components: { C360Icon },
  computed: {
    agency(): string {
      return this.$store.getters.user?.Agency;
    },
  },
  methods: {},
  watch: {
    search: {
      handler(): void {
        clearTimeout(this.searchTimer);
        if (this.search.length >= 3 || this.search.length === 0) {
          this.searchTimer = setTimeout(() => {
            this.$emit('changeParams', this.search);
          }, 500);
        }
      },
    },
  },
});
