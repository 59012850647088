export default {
  SET_CONVERSIONS: 'SET_CONVERSIONS',
  SET_DAILY_PERFORMANCE: 'SET_DAILY_PERFORMANCE',
  SET_GEO_PERFORMANCE: 'SET_GEO_PERFORMANCE',
  SET_GEO_LIST_PERFORMANCE: 'SET_GEO_LIST_PERFORMANCE',

  SET_CONVERSIONS_CACHE: 'SET_CONVERSIONS_CACHE',
  SET_IS_CONVERSIONS_CACHED: 'SET_IS_CONVERSIONS_CACHED',

  SET_CONVERSIONS_ERROR: 'SET_CONVERSIONS_ERROR',
  SET_DAILY_PERFORMANCE_ERROR: 'SET_DAILY_PERFORMANCE_ERROR',
  SET_GEO_PERFORMANCE_ERROR: 'SET_GEO_PERFORMANCE_ERROR',
  SET_GEO_LIST_PERFORMANCE_ERROR: 'SET_GEO_LIST_PERFORMANCE_ERROR',

  SET_IS_CONVERSIONS_LOADING: 'SET_IS_CONVERSIONS_LOADING',
  SET_IS_DAILY_PERFORMANCE_LOADING: 'SET_IS_DAILY_PERFORMANCE_LOADING',
  SET_IS_GEO_PERFORMANCE_LOADING: 'SET_IS_GEO_PERFORMANCE_LOADING',
  SET_IS_GEO_LIST_PERFORMANCE_LOADING: 'SET_IS_GEO_LIST_PERFORMANCE_LOADING',

  SET_CONVERSIONS_BREAKDOWN: 'SET_CONVERSIONS_BREAKDOWN',
  SET_CONVERSIONS_BREAKDOWN_ERROR: 'SET_CONVERSIONS_BREAKDOWN_ERROR',
  SET_IS_CONVERSIONS_BREAKDOWN_LOADING: 'SET_IS_CONVERSIONS_BREAKDOWN_LOADING',

  SET_ORDER_CONVERSIONS: 'SET_ORDER_CONVERSIONS',
  SET_ORDER_CONVERSIONS_CACHE: 'SET_ORDER_CONVERSIONS_CACHE',
  SET_IS_ORDER_CONVERSIONS_CACHED: 'SET_IS_ORDER_CONVERSIONS_CACHED',
  SET_ORDER_CONVERSIONS_ERROR: 'SET_ORDER_CONVERSIONS_ERROR',
  SET_IS_ORDER_CONVERSIONS_LOADING: 'SET_IS_ORDER_CONVERSIONS_LOADING',
};
