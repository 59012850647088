
import Vue from 'vue';
import lineAndBarChart from './lineAndBarChartNew.vue';
import lineChart from './lineChart.vue';
import analytics from '../../../../mixins/analytics';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import utils from '../../../../util';
import Tooltips from '../tooltip/chartTooltip.vue';

export default Vue.extend({
  name: 'genericLineBarChartNew',
  components: { lineAndBarChart, lineChart, EditModuleBtn, Tooltips },
  inheritAttrs: false,
  mixins: [analytics],
  props: ['sectionConfig', 'componentConfig', 'title', 'dataSource', 'theme', 'breakpoints', 'isExporting'],
  data: (): {
    activeTab: number;
    dropdownMenuOpen: boolean;
    selectedLine: string | null;
    menuLegendKeys: string[];
    componentHeight: number;
    fixedHeight: number;
    data: object[] | null;
    isLoading: boolean;
    tabsNames: string[];
  } => ({
    activeTab: 0,
    selectedLine: null,
    dropdownMenuOpen: false,
    menuLegendKeys: [],
    componentHeight: 600,
    fixedHeight: 541,
    data: null,
    isLoading: false,
    tabsNames: ['impressions', 'conversions'],
  }),
  async created() {
    if (this.hasCustomizedFeedSource && !this.validFeedSource) {
      this.setDisplay(false);
      return;
    } else {
      this.setDisplay(true);
      await this.fetchData();
    }
  },
  watch: {
    async $route() {
      if (this.hasCustomizedFeedSource && !this.validFeedSource) {
        this.$emit('set-display', false);
        return;
      } else {
        this.setDisplay(true);
        await this.fetchData();
      }
    },
  },
  computed: {
    isShared(): boolean {
      return this.$store.state.customer?.sharedDashboard;
    },
    feedSources(): string[] {
      return utils.feedSources(this);
    },
    hasCustomizedFeedSource(): boolean {
      return this.componentConfig?.feedSource?.length || this.componentConfig?.feedSourceToExclude?.length;
    },
    exportData() {
      return this.$store.state.customer?.exportingData;
    },
    areConversions(): boolean {
      return this?.data?.some(obj => obj?.Conversions) || this.isExporting;
    },
    validFeedSource(): boolean {
      if (!this.feedSources) return true;
      if (this.componentConfig?.feedSourceToExclude?.length) {
        return !this.componentConfig.feedSourceToExclude.some(item => this.feedSources.includes(item));
      }
      if (this.componentConfig?.feedSource?.length) {
        return this.componentConfig.feedSource.some(item => this.feedSources.includes(item));
      }
      return false;
    },
    dataFilledTooltips() {
      let tips = utils.dataFillTooltips(this.componentConfig?.tooltips, [
        { key: 'count', value: this.data?.length - 1 },
      ]);

      const allZeroResponseRate = this.data?.every(data => data.ResponseRate === 0);
      if (allZeroResponseRate) {
        tips = tips.filter(tip => !['Response Rate'].includes(tip.title));
      }
      const allZeroResponseNumber = this.data?.every(data => data.ResponseNumber === 0);
      if (allZeroResponseNumber) {
        tips = tips.filter(tip => !['TV Responses'].includes(tip.title));
      }

      return tips;
    },
  },
  methods: {
    setDisplay(display: boolean): void {
      this.$emit('set-display', display);
    },
    changeTab(name: string): void {
      this.analyticTrack(this.trackValue.DAILY_PERFORMANCE_MODULE, 'View switched', {
        AdvertiserName: this.$store.state?.advertiser?.advertiserInfo?.data?.name,
        CampaignID: this.$store.state?.customer?.campaignAdPerformance?.CampaignList?.[0]?.CampaignId,
        CampaignName: this.$store.state?.customer?.campaignAdPerformance?.CampaignList?.[0]?.FriendlyName,
        CurrentView: name,
      });
    },
    async fetchData(): Promise<void> {
      const type = this.isShared
        ? this.$store.state.customer.sharedSelection?.tab.toUpperCase()
        : this.$route.query?.tab?.toUpperCase();

      try {
        this.isLoading = true;

        const payload = this.isShared
          ? {
              daterange: this.$store.state.customer.sharedSelection?.campaigndaterange || '',
              advertiserId: this.$store.state.customer.sharedSelection?.aid || '',
              startdate: this.$store.state.customer.sharedSelection?.campaignstartdate || '',
              enddate: this.$store.state.customer.sharedSelection?.campaignenddate || '',
              campaignsIds: this.$store.state.customer.sharedSelection?.viewCampaigns.split(','),
              token: this.$store.state.customer.user.token,
            }
          : {
              daterange: this.$route.query?.daterange || 'alltime',
              advertiserId: this.$route.query?.id || '',
              startdate: this.$route.query?.campaignstartdate || '',
              enddate: this.$route.query?.campaignenddate || '',
              campaignsIds: this.$route.query?.viewCampaigns?.split(','),
            };
        const { data } = await this.$store.dispatch('performance/getDailyPerformance', {
          ...payload,
          type,
        });

        this.data = data?.[type];
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
