
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import utils from '../../../../util';
import { EChartsOption } from 'echarts/types/dist/shared';
import SideSummary from '../overviews/sideSummary.vue';
import Tooltips from '../tooltip/chartTooltip.vue';
import ExportDownloadBtn from '../buttons/exportDownloadBtn.vue';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import _clone from 'lodash.clonedeep';
import { C360Icon } from '@c360/ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ECharts = (window as any).echarts || undefined;
if (ECharts === undefined) {
  // eslint-disable-next-line no-console
  console.error('ECharts is not defined');
}

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'genericBarChart',
  components: { NoChartData, SideSummary, Tooltips, ExportDownloadBtn, EditModuleBtn, C360Icon },
  props: [
    'sectionConfig',
    'componentConfig',
    'dataSource',
    'title',
    'colors',
    'theme',
    'commonTweaks',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'componentHeight',
    'componentChartData',
    'loadingProps',
  ],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
    width: string;
    height: string;
    currPage: number;
    itemsPerPage: number;
  } => ({
    chartInstance: undefined,
    width: 'auto',
    height: '475px',
    currPage: 0,
    itemsPerPage: 6,
  }),
  watch: {
    chartData: 'initChart',
    '$store.state.customer.theme': 'initChart',
    chartColors: 'initChart',
  },
  mounted() {
    this.initChart();
    window.addEventListener('optimizedResize', this.onWindowResize);
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.initChart, true);
  },
  beforeDestroy() {
    unwatchDataChanges();
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  computed: {
    isOrder(): boolean {
      return this.$route.query?.tab?.includes('order');
    },
    loading(): boolean {
      if (this.isOrder) {
        return this.loadingProps;
      }
      return utils.isWaitingOnData(this);
    },
    additionalMetrics(): [string] {
      return this?.componentConfig?.additionalMetrics || [];
    },
    hasAdditionalData(): boolean {
      const sourceData = utils.adDataForKey(this, this.dataSource);
      return this?.componentConfig?.additionalMetrics && sourceData?.some(row => row?.isEnriched);
    },
    chartClass(): string {
      let css = 'generic-bar-chart';
      if (this.componentConfig.class) {
        css = css + ' ' + this.componentConfig.class;
      }
      if (this.isExporting) {
        css = css + ' exporting';
      }
      return css;
    },
    chartConfig(): EChartsOption {
      let lineColor = '#000';
      if (this.isExporting) {
        lineColor = '#333';
      }

      const styleConfig = {
        c360: {
          legend: {
            bottom: 3,
            left: 10,
            textStyle: {
              fontWeight: 600,
            },
          },
          grid: {
            top: 30,
            left: 80,
          },
          series: {
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 2,
            },
            backgroundStyle: {
              color: 'rgba(238, 238, 238, 1)',
            },
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(238, 238, 238, 1)',
            },
          },
        },
      };

      const currentStyle = styleConfig.c360;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isOrder = this.isOrder;

      const cfg: any = {
        tooltip: {},
        series: [],
        dataset: {},
        legend: {
          icon: 'circle',
          selectedMode: this.selectMode,
          ...currentStyle.legend,
        },
        grid: this.isExporting
          ? {
              containLabel: true,
              left: this.componentConfig.canvasPosition?.left || 0,
              top: this.componentConfig.canvasPosition?.top || 10,
              right: this.componentConfig.canvasPosition?.right || 0,
              bottom: 30,
              height: this.componentConfig.canvasPosition?.height,
            }
          : { containLabel: this.isHorizontal, ...currentStyle.grid },
        xAxis: {
          type: this.isHorizontal ? 'value' : 'category',
          axisLabel: {
            rotate: this.componentConfig.xAxisRotate ? 25 : 0,
            color: '#000',
            formatter: function (value) {
              if (isOrder) return utils.formatNumberWithCommas(value);
              if (value.length > 20) return `${value.substring(0, 12)}...`;

              return value;
            },
          },
          axisTick: {
            show: !!this.isExporting,
            alignWithLabel: true,
            lineStyle: {
              show: true,
              color: lineColor,
              type: 'solid',
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: lineColor,
              type: 'solid',
            },
          },
          splitLine: currentStyle.splitLine,
        },
        yAxis: {
          type: this.isHorizontal ? 'category' : 'value',
          show: this.isHorizontal,
          inverse: this.isHorizontal,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: !this.isHorizontal,
            lineStyle: {
              color: lineColor,
              type: 'solid',
            },
          },
          axisLabel: {
            color: '#000',
            padding: [0, 15, 0, 20],
          },
        },
        color: this.colorset,
      };

      cfg.textStyle = {
        fontFamily: 'Inter',
      };

      if (this.isHorizontal) {
        cfg.barWidth = 10;
      }
      if (this.tweaks.includes('percentageData')) {
        cfg.tooltip = {
          formatter: function (params): string | null {
            try {
              const value = params.data[params.seriesIndex + 1];
              const strValue = Number(value) && value % 1 !== 0 ? `${value}%` : `${value}`;
              return `${params.seriesName}<br />${params.marker} ${params.name} <b>${strValue}</b>`;
            } catch (exp) {
              // eslint-disable-next-line no-console
              console.error('tooltip formatter', exp, params);
            }
            return null;
          },
        };
        cfg.yAxis.axisLabel = {
          formatter: function (value) {
            try {
              return `ID: ${value}`;
            } catch (exp) {
              // eslint-disable-next-line no-console
              console.error('yaxislabel formatter', exp, value);
            }
            return null;
          },
        };
        cfg.xAxis.axisLabel = {
          formatter: function (value) {
            try {
              return `${value}%`;
            } catch (exp) {
              // eslint-disable-next-line no-console
              console.error('xaxislabel formatter', exp, value);
            }
            return null;
          },
        };
      }

      if (this.tweaks.includes('rsnbackfill')) {
        if (this.isExporting) {
          cfg.yAxis.axisLabel = {
            formatter: function (value) {
              try {
                if (value && typeof value === 'string') {
                  const label = value.split('-');
                  return `${label[0]} \n\n\n ${label[1]} ${label[2]}`;
                }
              } catch (exp) {
                // eslint-disable-next-line no-console
                console.error('yaxislabel formatter', exp, value);
              }
              return null;
            },
          };
        } else {
          cfg.yAxis.axisLabel = {
            formatter: function (value) {
              try {
                if (value && typeof value === 'string') {
                  const label = value.split('-');
                  return `${label[0] ? label[0] : ''} ${label[1] ? `\n ${label[1]}` : ''} ${label[2] ? label[2] : ''}`;
                }
              } catch (exp) {
                // eslint-disable-next-line no-console
                console.error('yaxislabel formatter', exp, value);
              }
              return null;
            },
          };
        }
        cfg.tooltip = {
          formatter: function (params): string | null {
            try {
              const mappedName = utils.headerNamesMap(params.seriesName);
              const value = utils.formatNumberWithCommas(params.data[params.seriesIndex + 1]);
              return `${params.marker}: ${mappedName}<br />${params.name}: <strong>${value}</strong>`;
            } catch (exp) {
              // eslint-disable-next-line no-console
              console.error('tooltip formatter', exp, params);
            }
            return null;
          },
        };
      }

      if (this.tweaks.includes('weekly')) {
        cfg.tooltip = {
          formatter: function (params): string | null {
            try {
              const mappedName = utils.headerNamesMap(params.seriesName);
              const value = utils.formatNumberWithCommas(params.data[params.seriesIndex + 1]);
              return `${params.marker}: ${mappedName}<br /> ${params.name}: <strong>${value}</strong>`;
            } catch (exp) {
              // eslint-disable-next-line no-console
              console.error('tooltip formatter', exp, params);
            }
            return null;
          },
        };
        cfg.yAxis = {
          type: 'value',
          show: true,
          inverse: false,
          axisTick: {
            show: true,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: lineColor,
              type: 'solid',
            },
          },
          axisLabel: {
            color: '#000',
            padding: [0, 15, 0, 0],
            formatter: function (params): string | null {
              try {
                const val = utils.formatValueEstimate(params);
                return val;
              } catch (exp) {
                // eslint-disable-next-line no-console
                console.error('axis label formatter', exp, params);
              }
              return null;
            },
          },
        };
        cfg.dataset = { source: this.chartData };
        if (Array.isArray(this.chartData) && this.chartData.length) {
          // add a bar for every datapoint
          const barAmount = this.chartData[0].length - 1;
          for (let i = 0; i < barAmount; i++)
            cfg.series.push({
              type: 'bar',
              animation: !this.isExporting,
              barWidth: 25,
              barGap: '20%',
              itemStyle: {
                normal: {
                  barBorderRadius: [5, 5, 0, 0],
                },
              },
            });
        }
        return cfg;
      } else if (this.componentConfig.stack) {
        cfg.legend.data = this.stackChartData.legendKeys;
        cfg.xAxis.data = this.stackChartData.xAxisData;
        cfg.series = this.stackChartData.series;
      } else {
        const source = this.pagination ? this.filteredData : this.chartData;
        cfg.dataset = { source };

        if (Array.isArray(this.chartData) && this.chartData.length) {
          // add a bar for every datapoint
          const barAmount = this.chartData[0].length - 1;
          for (let i = 0; i < barAmount; i++)
            cfg.series.push({
              type: 'bar',
              animation: !this.isExporting,
              barGap: '30%',
              itemStyle: {
                normal: {
                  barBorderRadius: this.isHorizontal ? [0, 5, 5, 0] : [5, 5, 0, 0],
                  ...currentStyle.series.itemStyle,
                },
              },
              showBackground: true,
              backgroundStyle: currentStyle.series.backgroundStyle,
            });
        }
      }
      return cfg;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartData(): any {
      let sourceData = utils.adDataForKey(this, this.dataSource);
      if (this.isOrder) {
        sourceData = this.componentChartData;
      }
      // console.log('sourceData is', sourceData);
      if (!sourceData || !Array.isArray(sourceData)) {
        return [];
      }

      const categoryName = this.componentConfig.barCategory;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const chartData: any[] = [];
      let configLegendKeys = this.componentConfig.legendKeys || [];
      if (!this.hasAdditionalData) {
        configLegendKeys = configLegendKeys.filter(key => !this.additionalMetrics.includes(key));
      }
      // first array is legend key, first string is axis label
      let legendKeys = ['', ...configLegendKeys];
      // get legend key friendly names
      legendKeys = legendKeys.map(key => utils.headerNamesMap(key));
      chartData.push(legendKeys);

      // chart data is array where first string is the category name
      // followed by number(s) for each legend key
      if (this.tweaks.includes('daybackfill')) {
        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].forEach((day: string) => {
          const barData = [day];
          const found = sourceData.find(s => s.Day === day || s.weekday === day);
          configLegendKeys.forEach(key => {
            let dayData = found ? found[key] : 0;
            if (typeof dayData === 'string' && dayData.indexOf('%') > -1) {
              dayData = parseFloat(dayData);
            }
            barData.push(dayData);
          });
          chartData.push(barData);
        });
      } else if (this.tweaks.includes('monthbackfill')) {
        ['Jan', 'Feb', 'Mar', 'Apr', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].forEach((month: string) => {
          const barData = [month];
          const found = sourceData.find(s => s.Month === month);
          configLegendKeys.forEach(key => {
            let monthData = found ? found[key] : 0;
            if (typeof monthData === 'string' && monthData.indexOf('%') > -1) {
              monthData = parseFloat(monthData);
            }
            barData.push(monthData);
          });
          chartData.push(barData);
        });
      } else if (this.tweaks.includes('hourimpressions')) {
        for (let i = 0; i < 23; i++) {
          const hour = utils.formatHour(parseInt(`${i}`, 10), false);
          const found = sourceData.find(h => h.Hour === `${i}`);
          const barData = [`${hour}`];
          configLegendKeys.forEach(key => {
            let hourData = found ? found[key] : 0;
            if (typeof hourData === 'string' && hourData.indexOf('%') > -1) {
              hourData = parseFloat(hourData);
            }
            barData.push(hourData);
          });
          chartData.push(barData);
        }
      } else if (this.tweaks.includes('daypartbackfill')) {
        let dayparts = ['Morning', 'MidDay', 'Afternoon', 'Night', 'Overnight'];
        if (this.isSimplifi) {
          dayparts = ['12am to 4am', '4am to 8am', '8am to 12pm', '12pm to 4pm', '4pm to 8pm', '8pm to 12am'];
        }
        dayparts.forEach((daypart: string) => {
          const barData = [daypart];
          const found = sourceData.find(dp => dp.Daypart === daypart);
          configLegendKeys.forEach(key => {
            let daypartData = found ? found[key] : 0;
            if (typeof daypartData === 'string' && daypartData.indexOf('%') > -1) {
              daypartData = parseFloat(daypartData);
            }
            barData.push(daypartData);
          });
          chartData.push(barData);
        });
      } else {
        sourceData.forEach(dataPoint => {
          let test = dataPoint[categoryName] || '';
          if (test.length > 26) {
            test = test.substring(0, 24) + '...';
          }
          const barData = [test];
          configLegendKeys.forEach(key => {
            let dataValue = dataPoint[key];
            if (typeof dataValue === 'string' && dataValue.indexOf('%') > -1) {
              dataValue = parseFloat(dataValue);
            }
            barData.push(dataValue);
          });
          chartData.push(barData);
        });
      }
      return chartData;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stackChartData(): any {
      const sourceData = utils.adDataForKey(this, this.dataSource);
      if (!sourceData || !Array.isArray(sourceData)) {
        return [];
      }
      const categoryName = this.componentConfig.barCategory;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const chartData: any = {
        legendKeys: this.hasAdditionalData
          ? this.componentConfig.legendKeys
          : this.componentConfig.legendKeys.filter(key => !this.additionalMetrics.includes(key)),
        xAxisData: [],
        series: [],
      };

      chartData.series = chartData.legendKeys.map((key: string) => {
        return {
          name: key,
          type: 'bar',
          stack: 'true',
          data: [],
        };
      });

      // series data:
      // each obj is for each value (aka legend key) and its array corresponds to each x-axis datapoint (respective to order)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sourceData.forEach((item: any) => {
        // add x-axis data category
        chartData.xAxisData.push(item[categoryName]);

        chartData.series.forEach(legendKey => {
          legendKey.data.push(item[legendKey.name]);
        });
      });
      // console.log('chartData.xAxisData', chartData.xAxisData);
      // console.log('chartData.series', chartData.series);
      return chartData;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filteredData(): any {
      // filter if pagination
      let barData = _clone(this.chartData).splice(1);

      barData = barData.filter((item, i) => {
        const aboveMin = i >= this.currPage * this.itemsPerPage;
        const belowMax = i < (this.currPage + 1) * this.itemsPerPage;
        return aboveMin && belowMax;
      });

      return [['', ...this.legendData], ...barData];
    },
    canvasStyle(): object {
      return {
        width: this.width,
        height: this.componentConfig.canvasHeight || this.height,
      };
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) return true;
      return this.componentConfig.stack ? !!this.stackChartData : this.chartData.length > 0;
    },
    showNoDataChart(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      } else if ((this.componentConfig.hideIfNoData && !this.hasEnoughData) || this.isPrinting) {
        return false;
      }
      return true;
    },
    canExportToXLS(): boolean {
      if (this.isOrder) return false;
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return this.hasEnoughData && !!this.componentConfig?.exportableTableData;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    tweaks(): string[] {
      const commonTweaks = this.commonTweaks || this.componentConfig.commonTweaks;
      if (!commonTweaks) {
        return [];
      }
      if (Array.isArray(commonTweaks)) {
        return commonTweaks;
      }
      return [commonTweaks];
    },
    chartTitle(): string {
      if (this.dataSource === 'SEO.ByMonthGoogleRankings') {
        const aggregate = utils.adDataForKey(this, 'SEOTotal.Aggregate');
        return `Google Rankings ${aggregate}`;
      }
      return this.componentConfig.title;
    },
    isHorizontal(): boolean {
      return this.componentConfig.horizontal;
    },
    sideMetricsConfig(): object | null {
      const { sideTotalMetrics } = this.componentConfig;
      if (sideTotalMetrics && sideTotalMetrics.metrics?.length && sideTotalMetrics.dataSource) {
        return this.componentConfig.sideTotalMetrics;
      }
      return null;
    },
    pagination(): boolean {
      return this.componentConfig.pagination;
    },
    disabledPrev(): boolean {
      return this.currPage === 0;
    },
    disabledNext(): boolean {
      return this.currPage === this.totalPages - 1;
    },
    totalPages(): number {
      return Math.ceil((this.chartData.length - 1) / this.itemsPerPage);
    },
    currentPageNumbers(): string {
      const firstNum = this.currPage * this.itemsPerPage + 1;
      let secondNum = (this.currPage + 1) * this.itemsPerPage;
      if (this.currPage === this.totalPages - 1 || this.totalPages === 1) {
        secondNum = this.chartData.length - 1;
      }
      return `${firstNum} - ${secondNum} of ${this.chartData.length - 1}`;
    },
    legendData() {
      let keys = this.componentConfig.legendKeys;
      if (!this.hasAdditionalData) {
        keys = keys.filter(key => !this.additionalMetrics.includes(key));
      }
      const legend = keys.map(key => utils.headerNamesMap(key));
      return legend;
    },
    colorset(): Array<string> {
      let colors = ['#00b0ff', '#ff5252', '#ffc400', '#00c853', '#2979ff', '#ab47bc'];
      if (this.componentConfig.chartColors) {
        // use configurability override if defined
        colors = this.componentConfig.chartColors;
      }
      return colors;
    },
    chartColors(): Array<string> {
      return this.componentConfig.chartColors;
    },
    selectMode(): boolean | string {
      let mode: boolean | string = true;
      if (this.legendData.includes('Imp %') || this.legendData.includes('VCR')) {
        mode = 'single';
      }
      return mode;
    },
    isSimplifi(): boolean {
      const feeds = utils.feedSources(this);
      return feeds.includes('SIMPLIFI');
    },
    isPrinting() {
      return this.$route.query.print === 'true';
    },
  },
  methods: {
    initChart(): void {
      setTimeout(() => {
        try {
          if (!this.hasEnoughData) {
            this.$emit('rendered', { empty: true });
            return;
          }
          if (!this.$refs.canvas) {
            this.$emit('rendered', { empty: true });
            return;
          }
          this.chartInstance = ECharts.init(this.$refs.canvas);
          if (this.chartInstance) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const c = this.chartInstance as any;
            if (this.isXLS) {
              this.getTableData();
            } else {
              c.on('finished', () => {
                this.$emit('rendered', { empty: false });
              });
              c.on('rendered', () => {
                this.$emit('rendered', { empty: false });
              });
            }
            c.setOption(this.chartConfig);
          } else {
            // eslint-disable-next-line no-console
            console.error('initChart', 'failed to create instance');
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('initChart', error);
        }
      }, 10);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          this.chartInstance.resize();
        }
      }, 250);
    },
    getTableData(): void {
      let headers: string[] = [];
      if (this.componentConfig.exportTweaks?.addDayOfTheWeekColumn) {
        headers.push('Day');
      } else if (this.tweaks.includes('rsnbackfill')) {
        headers.push('RSN');
        headers.push('League');
        headers.push('Team');
      } else {
        headers.push('n/a'); // can we know?
      }
      let keys = this.componentConfig.legendKeys;
      if (!this.hasAdditionalData) {
        keys = keys.filter(key => !this.additionalMetrics.includes(key));
      }
      keys = keys.map(key => utils.headerNamesMap(key.toLowerCase()));
      headers = [...headers, ...keys];

      let data = [];
      if (this.chartData.length > 1) {
        data = this.chartData.slice(1);
      }
      if (this.tweaks.includes('rsnbackfill')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data = data.map((r: any[]) => {
          const labels = r[0].split('-').map(s => s.trim());
          while (labels.length < 3) {
            labels.push('N/A');
          }
          const values = r.slice(1);
          return [...labels, ...values];
        });
        // data = data.map(r => [...r[0].split('-'), ...r]);
      }
      // console.log('line chart export', data);
      if (data.length === 0) {
        this.$emit('rendered', { empty: true });
      } else {
        this.$emit('rendered', { empty: false, headers, data, config: this.componentConfig });
      }
    },
    changePage(direction: number): void {
      if (direction > 0 && !this.disabledNext) {
        this.currPage += 1;
      } else if (direction < 0) {
        this.currPage -= 1;
      }
      this.initChart();
    },
  },
});
