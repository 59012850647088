
import { CampaignDetails } from '../../store/modules/customer/types';
import utils from '../../util';

export default {
  name: 'main-footer',
  props: ['reversed', 'onLogin'],
  computed: {
    buildInfo(): string {
      if (process && process.env && process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION.length > 8) {
        return `v.1.${process.env.VUE_APP_BUILD_VERSION.substr(3, 6)}`;
      }
      return 'n/a';
    },
    sources(): string {
      let sources = '';

      // Replace feeds/sources from Dashboard BE
      const sourceReplaceMap = {
        TVSQUARED: 'InnovidXP',
      };

      if (this.feedSources.length > 0) {
        sources = this.feedSources.map(feed => sourceReplaceMap[feed] || feed).join(', ');
      }
      if (this.dataSources.length > 0) {
        if (sources.length) {
          sources += ' | ';
        }
        sources += this.dataSources.map(source => sourceReplaceMap[source] || source).join(', ');
      }
      if (this.layout) {
        if (sources.length) {
          sources += ' | ';
        }
        sources += `${this.layout.type} v${this.layout.Version}`;
      }
      return sources;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    layout(): any {
      return this.$store.state?.customer?.dynamicLayout?.layoutCustomizations;
    },
    isSummaryPage(): boolean {
      return this.$route?.query?.tab === 'summary';
    },
    feedSources(): Array<string> {
      if (this.isExporting || this.isSummaryPage) {
        return [];
      }
      const feeds = utils.feedSources(this);
      return feeds;
    },
    dataSources(): Array<string> {
      if (this.isSummaryPage) {
        const summaryDataSource = this.$store.state?.customer?.summaryPerformance?.isDataAPI ? 'dataApi' : '';
        return [summaryDataSource];
      } else {
        if (!Array.isArray(this.$store.state?.customer?.campaignAdPerformance?.CampaignList)) {
          return [];
        }
        const dataSources: Set<string> = new Set();
        this.$store.state.customer.campaignAdPerformance.CampaignList.forEach((campaign: CampaignDetails) => {
          if (campaign.DataSource) {
            if (Array.isArray(campaign.DataSource)) {
              for (const s of campaign.DataSource) {
                dataSources.add(s);
              }
            } else if (typeof campaign.DataSource === 'string' && campaign.DataSource.length) {
              dataSources.add(campaign.DataSource);
            }
          }
        });
        return Array.from(dataSources);
      }
    },
  },
};
