
import Vue from 'vue';
import { checkPermissions } from '@point/auth';

export default Vue.extend({
  data: (): {
    error: string | null;
    showError: boolean;
  } => ({
    error: null,
    showError: false,
  }),
  components: {
    AgencyDetailsNew: () => import('./agencyDetailsNew.vue'),
    FacebookDetailsNew: () => import('./credentials/facebookDetailsNew.vue'),
    SiteImpactDetailsNew: () => import('./credentials/siteImpactDetailsNew.vue'),
    CallRailDetailsNew: () => import('./credentials/callRailDetailsNew.vue'),
  },
  computed: {
    loading(): boolean {
      return this.$store.state.agencyAdmin.loading;
    },
  },
  methods: {
    hasIndividualRight(roles: Array<string>): boolean {
      return checkPermissions(this.$store.state.customer.user.accessToken, roles, false);
      // return utils.hasRight(this.$store.state.customer.user, roles);
    },
    backToList(): void {
      this.$router.replace({ name: 'agencylist' });
    },
    openLinearAdmin(): void {
      this.$router.push({ name: 'linearadmin' });
    },
  },
});
