var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-btn',{attrs:{"color":"grey lighten-2 text-transform-capitalize"},on:{"click":function($event){return _vm.openDialog()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("tune")]),_vm._v(" Summary Options ")],1),_c('v-dialog',{attrs:{"width":750},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"px-7"},[_c('v-card-title',{staticClass:"pa-0 pb-3"},[_vm._v(" Summary Options ")]),_c('p',{staticClass:"grey--text mb-3"},[_vm._v("Map")]),_c('v-row',{staticClass:"pl-3"},[_c('v-checkbox',{attrs:{"dense":"","label":"Hide map"},on:{"change":function($event){{
              _vm.setOverride();
              _vm.trackToggleMap();
            }}},model:{value:(_vm.selection.hideSummaryMap),callback:function ($$v) {_vm.$set(_vm.selection, "hideSummaryMap", $$v)},expression:"selection.hideSummaryMap"}})],1),_c('p',{staticClass:"grey--text mb-5 mt-7"},[_vm._v("Products to show")]),_c('v-row',{staticClass:"product-options-container"},_vm._l((_vm.availableProducts),function(product){return _c('v-btn',{key:product.analyticsType,class:{
            'disabled-product': _vm.isProductDisabled(product),
            'cant-select': _vm.oneProductLeft && !_vm.isProductDisabled(product),
          },on:{"click":function($event){return _vm.toggleProduct(product)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.isProductDisabled(product) ? 'visibility_off' : 'check')+" ")]),_c('span',{staticClass:"product-title"},[_vm._v(" "+_vm._s(product.title))]),(_vm.oneProductLeft && !_vm.isProductDisabled(product))?_c('span',{staticClass:"disabled-tooltip"},[_vm._v(" At least one product must be enabled ")]):_vm._e()],1)}),1),_c('p',{staticClass:"grey--text mb-5 mt-7"},[_vm._v("Metrics to show")]),_c('v-row',{staticClass:"product-options-container"},_vm._l((_vm.availableMetrics),function(metric){return _c('v-btn',{key:metric.analyticsType,class:{
            'disabled-product': _vm.isMetricDisabled(metric),
            'cant-select': _vm.oneMetricLeft && !_vm.isMetricDisabled(metric),
          },on:{"click":function($event){return _vm.toggleMetric(metric)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.isMetricDisabled(metric) ? 'visibility_off' : 'check')+" ")]),_c('span',{staticClass:"product-title"},[_vm._v(" "+_vm._s(metric.title))]),(_vm.oneMetricLeft && !_vm.isMetricDisabled(metric))?_c('span',{staticClass:"disabled-tooltip"},[_vm._v(" At least one metric must be enabled ")]):_vm._e()],1)}),1),_c('v-card-actions',{staticClass:"summary-options-actions py-3"},[_c('v-btn',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","disabled":_vm.disableSave},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }