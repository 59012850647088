
import Vue from 'vue';
import * as echarts from 'echarts';

export default Vue.extend({
  name: 'stackedBarChart',
  props: {
    chartData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => {
    return {
      chart: null as echarts.ECharts | null,
    };
  },
  mounted() {
    this.initChart();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    initChart() {
      if (!this.$refs.chartRef) {
        setTimeout(() => {
          this.initChart();
        }, 500);
        return;
      }

      this.chart = echarts.init(this.$refs.chartRef as HTMLElement);
      this.setChartData();
    },

    setChartData() {
      if (!this.chart) return;
      this.chart.setOption(this.chartData);
    },

    handleResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
});
