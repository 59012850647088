import axios, { AxiosResponse, AxiosError } from 'axios';
import { Theme } from '@point/utility-classes';
import { ActionContext } from 'vuex';
import { logError } from '../../index';
import { StateType, AuthUser, SetCampaignFriendlyName } from './types';
import { User, UserAccessGroup, Rights, UserRoles, AdvertiserGroup } from '../../../types/users';
import CONSTANTS from './constants';
import ERRORS from '../../../errors';
import { Campaign } from '../../../types/filters';
import { LayoutSection } from '../../../types/layout';
import requests from './requests';

import DashboardActions from './actions/dashboards';
import LayoutActions from './actions/layout';
import CampaignNotesActions from './actions/campaignNotes';
import { session_id } from '../../../main';

import util from '../../../util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let urlCache: any = {};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let latestApiCalls: any = {};

export default {
  // starting to split out actions into modules
  ...DashboardActions,
  ...LayoutActions,
  ...CampaignNotesActions,
  showError({ commit }: ActionContext<StateType, StateType>, _error: object | string | null): void {
    if (!_error) {
      commit(CONSTANTS.SET_ERROR, null);
    } else if (typeof _error === 'string') {
      commit(CONSTANTS.SET_ERROR, { error: _error });
    } else if (typeof _error === 'object') {
      commit(CONSTANTS.SET_ERROR, _error);
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDebugFlag({ commit }, keyval: any): void {
    commit(CONSTANTS.SET_DEBUG_FLAG, keyval);
  },
  clickedTacticFromSidebar({ commit }, tactic?: string): void {
    commit(CONSTANTS.SET_CLICKED_TACTIC_FROM_SIDEBAR, tactic);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelection({ commit }, selection: any): void {
    commit(CONSTANTS.SET_SELECTION, selection);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSharedSelection({ commit }, selection: any): void {
    commit(CONSTANTS.SET_SHARED_SELECTION, selection);
  },
  resetCustomer({ commit }): void {
    commit(CONSTANTS.SET_DATERANGES, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_AD_PERFORMANCE, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_SUMMARY_PERFORMANCE, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_ALL_TIME_CAMPAIGNS, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_AD_PERFORMANCE_LOADING, false);
    commit(CONSTANTS.SET_SUMMARY_PERFORMANCE_LOADING, false);
    commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
    commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
    commit(CONSTANTS.SET_POLYGONS, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_POLYGONS_LOADING, false);
    commit(CONSTANTS.SET_DMA_POLYGONS, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_DMA_POLYGONS_LOADING, false);
    commit(CONSTANTS.SET_ZIP_POLYGONS, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_ZIP_POLYGONS_LOADING, false);
    commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
    commit(CONSTANTS.SET_NAV_TAB, '');
    commit(CONSTANTS.SET_VALIDATED_PRODUCT_SECTIONS, null);
    commit(CONSTANTS.SET_STATIC_LAST_MODIFIED_DATE, '');
    commit(CONSTANTS.SET_SELECTION, {
      SFLT: [],
      advertiserName: null,
      advertiserId: null,
      daterange: null,
      view: null,
      creative: [],
      campaigns: null,
    });
    latestApiCalls.getAdPerformance = null;
    latestApiCalls.getSummary = null;
    latestApiCalls.getAllTimeAdPerformance = null;
    latestApiCalls.getSummaryPerformance = null;
    latestApiCalls.getCampaignAdPerformance = null;
    latestApiCalls.getCampaigns = null;
    latestApiCalls.getTacticSummaryPerformance = null;
    latestApiCalls.getDateRanges = null;
    latestApiCalls.getAllTimeCampaigns = null;
    latestApiCalls.getAdvertiserInfo = null;
  },
  resetAdPerformance({ commit }): void {
    latestApiCalls.getAdPerformance = null;
    latestApiCalls.getSummary = null;
    latestApiCalls.getSummaryPerformance = null;
    latestApiCalls.getCampaignAdPerformance = null;
    latestApiCalls.getTacticSummaryPerformance = null;
    commit(CONSTANTS.SET_AD_PERFORMANCE, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_AD_PERFORMANCE_LOADING, false);
    commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE_LOADING, false);
  },
  resetCampaignAdPerformance({ commit }): void {
    latestApiCalls.getCampaignAdPerformance = null;
    commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE_LOADING, false);
  },
  resetAdvertiserPolygons({ commit }): void {
    commit(CONSTANTS.SET_POLYGONS, { loaded: false, error: 'empty' });
    commit(CONSTANTS.SET_POLYGONS_LOADING, false);
  },
  resetAllCampaigns(): void {
    latestApiCalls.getCampaigns = null;
    latestApiCalls.getAllTimeCampaigns = null;
  },
  setAdPerformance({ commit }, adPerf): void {
    commit(CONSTANTS.SET_AD_PERFORMANCE, { ...adPerf, loaded: true });
    commit(CONSTANTS.SET_AD_PERFORMANCE_LOADING, false);
  },
  setAllTimeAdPerformance({ commit }, adPerf): void {
    commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE, { ...adPerf, loaded: true });
    commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
  },
  setCampaignAdPerformance({ commit }, adPerf): void {
    commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE, { ...adPerf, loaded: true });
    commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE_LOADING, false);
  },
  /* start cache */
  async generateCache(): Promise<void> {
    axios.get('/api/generateCache');
  },
  /* end cache */

  async getSummaryPerformance(
    { commit, state },
    { advertiserId, daterange, startdate, enddate, token },
  ): Promise<void> {
    if (!advertiserId || !daterange) {
      // eslint-disable-next-line no-console
      console.error('getSummaryPerformance missing inputs', advertiserId, daterange, startdate, enddate);
      commit(CONSTANTS.SET_SUMMARY_PERFORMANCE, { loaded: true, error: 'empty' });
      commit(CONSTANTS.SET_SUMMARY_PERFORMANCE_LOADING, false);
      return;
    }

    try {
      let reportUrl = '/api/getSummaryPerformance?';
      reportUrl += `a=${advertiserId}`;
      if (daterange === 'customRange') {
        reportUrl += `&startdate=${startdate}`;
        reportUrl += `&enddate=${enddate}`;
      } else {
        reportUrl += `&daterange=${daterange}`;
      }
      if (token) reportUrl += `&token=${token}`;

      const cacheKey = `getSummaryPerformance:${reportUrl}`;
      if (latestApiCalls.getSummaryPerformance === cacheKey) {
        return state.summaryPerformance;
      }
      latestApiCalls.getSummaryPerformance = cacheKey;

      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cached: any = (urlCache as any)[cacheKey];
        if (cached) {
          const obj = JSON.parse(cached);
          if (obj) {
            commit(CONSTANTS.SET_SUMMARY_PERFORMANCE, obj);
            commit(CONSTANTS.SET_SUMMARY_PERFORMANCE_LOADING, false);
          }
          return;
        }
      } catch (exp) {
        logError(exp, 'store.getSummaryPerformance cache');
      }

      // commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      commit(CONSTANTS.SET_SUMMARY_PERFORMANCE_LOADING, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const summary: AxiosResponse<any> = await axios.post(reportUrl);
      // commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (summary && summary.data) {
        if (summary.data.ErrorMessage) {
          // eslint-disable-next-line no-console
          console.error('invalid api response for getSummaryPerformance', summary.data);
          commit(CONSTANTS.SET_SUMMARY_PERFORMANCE, { error: summary.data.ErrorMessage, loaded: true });
          commit(CONSTANTS.SET_SUMMARY_PERFORMANCE_LOADING, false);
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = { ...summary.data, loaded: true };
        commit(CONSTANTS.SET_SUMMARY_PERFORMANCE, data);
        commit(CONSTANTS.SET_SUMMARY_PERFORMANCE_LOADING, false);
        return;
      }
      // eslint-disable-next-line no-console
      console.error('invalid api response for getSummaryPerformance', summary);
      commit(CONSTANTS.SET_SUMMARY_PERFORMANCE, { error: 'no data', loaded: true });
      commit(CONSTANTS.SET_SUMMARY_PERFORMANCE_LOADING, false);
    } catch (exp) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = { loaded: true, error: exp.message };
      commit(CONSTANTS.SET_SUMMARY_PERFORMANCE, data);
      commit(CONSTANTS.SET_SUMMARY_PERFORMANCE_LOADING, false);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getSummaryPerformance');
    }
  },

  async getAdPerformance(
    { commit, state },
    { advertiserId, daterange, startdate, enddate, campaigns, tactic, reload = false },
  ): Promise<any> {
    if (!advertiserId || !daterange) {
      commit(CONSTANTS.SET_AD_PERFORMANCE, { loaded: true, error: 'empty' });
      commit(CONSTANTS.SET_AD_PERFORMANCE_LOADING, false);
      return;
    }

    let isAllTime = false;
    try {
      let reportUrl = '/api/getAdPerformance?';
      reportUrl += `a=${advertiserId}`;
      if (tactic) {
        reportUrl += `&tactic=${tactic}`;
      }

      if (daterange === 'customRange') {
        reportUrl += `&startdate=${startdate}`;
        reportUrl += `&enddate=${enddate}`;
      } else {
        reportUrl += `&daterange=${daterange}`;
        if (daterange === 'alltime' && state.allTimeCampaigns?.loaded) {
          let allCampaignList = '';
          if (
            state.allTimeCampaigns.campaignlist &&
            Array.isArray(state.allTimeCampaigns.campaignlist) &&
            state.allTimeCampaigns.campaignlist.length > 0
          ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            allCampaignList = state.allTimeCampaigns.campaignlist.map((c: any) => c.id).join(',');
          }
          if (campaigns === allCampaignList) {
            isAllTime = true;
          }
        }
      }

      const cacheKey = `url:${reportUrl} campaigns:${campaigns}`;
      if (latestApiCalls.getAdPerformance === cacheKey && !reload) {
        return;
      }
      latestApiCalls.getAdPerformance = cacheKey;

      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cached: any = (urlCache as any)[cacheKey];
        if (cached && !reload) {
          const obj = JSON.parse(cached);
          if (obj) {
            commit(CONSTANTS.SET_AD_PERFORMANCE, obj);
            commit(CONSTANTS.SET_AD_PERFORMANCE_LOADING, false);
            if (isAllTime) {
              commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE, obj);
              commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
            }
          }
          return;
        }
      } catch (exp) {
        logError(exp, 'store.getAdPerformance cache');
      }

      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      commit(CONSTANTS.SET_AD_PERFORMANCE_LOADING, true);
      if (isAllTime) {
        commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, true);
      }
      const headers = { session_id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const adPerf: AxiosResponse<any> = await axios.post(reportUrl, { campaigns }, { headers });
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (adPerf && adPerf.data) {
        if (adPerf.data.ErrorMessage) {
          // eslint-disable-next-line no-console
          console.error('invalid api response for getAdPerformance', adPerf.data);
          commit(CONSTANTS.SET_AD_PERFORMANCE, { error: adPerf.data.ErrorMessage, loaded: true });
          commit(CONSTANTS.SET_AD_PERFORMANCE_LOADING, false);
          if (isAllTime) {
            commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
          }
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = { ...adPerf.data, loaded: true };
        commit(CONSTANTS.SET_AD_PERFORMANCE, data);
        commit(CONSTANTS.SET_AD_PERFORMANCE_LOADING, false);
        if (isAllTime) {
          commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE, data);
          commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (urlCache as any)[cacheKey] = JSON.stringify(data);
        return;
      }
      // eslint-disable-next-line no-console
      console.error('invalid api response for getAdPerformance', adPerf);
      commit(CONSTANTS.SET_AD_PERFORMANCE, { error: 'no data', loaded: true });
      commit(CONSTANTS.SET_AD_PERFORMANCE_LOADING, false);
      if (isAllTime) {
        commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
      }
    } catch (exp) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = { loaded: true, error: exp.message };
      commit(CONSTANTS.SET_AD_PERFORMANCE, data);
      commit(CONSTANTS.SET_AD_PERFORMANCE_LOADING, false);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (isAllTime) {
        commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
      }
      throw logError(exp, 'store.getAdPerformance');
    }
  },
  async getCampaignAdPerformance(
    { commit, rootState },
    {
      advertiserId,
      campaigns,
      campaignsWithTactic,
      daterange,
      campaigndaterange,
      campaignstartdate,
      campaignenddate,
      token,
      reload,
      currentNavTab,
      ignoreSelection,
      isReport,
    },
  ): // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Promise<any> {
    // This reset is necessary because some components are utilizing data from 'adPerformance'.
    // Originally, 'adPerformance' was expected to contain data for all campaigns.
    // However, currently, it only contains data for the first campaign or the currently selected campaign.
    // Therefore, instead of 'adPerformance', components should use 'campaignAdPerformance'.
    // only if not in shared
    const isShared = rootState.customer.sharedDashboard;
    if (!isShared) {
      commit(CONSTANTS.SET_AD_PERFORMANCE, { loaded: true, error: 'empty' });
    }
    // End of magic reset

    if (!advertiserId) {
      commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE, { loaded: true, error: 'empty' });
      commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE_LOADING, false);
      return;
    }

    try {
      let reportUrl = '/api/getAdPerformance?';
      reportUrl += `a=${advertiserId}`;

      if (!campaigndaterange && !daterange) {
        // use all time if none specified
        reportUrl += '&daterange=alltime';
      } else if (campaigndaterange === 'customRange') {
        reportUrl += `&startdate=${campaignstartdate}`;
        reportUrl += `&enddate=${campaignenddate}`;
      } else {
        reportUrl += `&daterange=${daterange}`;
      }

      const cacheKey = `url:${reportUrl} campaigns:${campaigns}`;
      if (latestApiCalls.getCampaignAdPerformance === cacheKey && !reload) {
        return;
      }
      latestApiCalls.getCampaignAdPerformance = cacheKey;

      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cached: any = (urlCache as any)[cacheKey];
        if (cached && !reload) {
          const obj = JSON.parse(cached);
          if (obj) {
            // set static_date in cache hit case
            commit(CONSTANTS.SET_STATIC_LAST_MODIFIED_DATE, obj.LastModifiedDate);
            commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE, obj);
            commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE_LOADING, false);
          }
          return;
        }
      } catch (exp) {
        logError(exp, 'store.getCampaignAdPerformance cache');
      }

      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE_LOADING, true);


      // to help data-api, decorate campaign IDs with their tactic
      // todo: once we use data-api everywhere, pass this list from caller
      const allCampaigns = rootState.customer?.campaigns?.campaignlist;
      const mappedCampaigns =
        campaignsWithTactic ||
        campaigns.split(',').map((id: string) => {
          if (Array.isArray(allCampaigns)) {
            const found = currentNavTab
              ? allCampaigns.find(
                  c =>
                    c.id === id &&
                    c.CampaignType.toLowerCase() === util.dataKeyBySectionIdMap(currentNavTab).toLowerCase(),
                )
              : allCampaigns.find(c => c.id === id);
            if (found?.CampaignType) {
              return { id, type: found.CampaignType };
            }
          }
          return { id };
        });

      const headers = { session_id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const adPerf: AxiosResponse<any> = await axios.post(
        reportUrl,
        { campaigns: mappedCampaigns, token, isReport },
        { headers },
      );
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (adPerf && adPerf.data) {
        if (adPerf.data.ErrorMessage) {
          // eslint-disable-next-line no-console
          console.error('invalid api response for getCampaignAdPerformance', adPerf.data);
          commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE, { error: adPerf.data.ErrorMessage, loaded: true });
          commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE_LOADING, false);
          return;
        }
        // by the time we come back, make sure we still expect that campaign data

        if (
          !ignoreSelection &&
          rootState.filters.selectedCampaigns?.map((c: Campaign) => c.id).join(',') !== campaigns
        ) {
          // eslint-disable-next-line no-console
          console.log('got data for campaign', campaigns, ' but now waiting for ', rootState.filters.selectedCampaigns);
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = { ...adPerf.data, loaded: true };
        commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE, data);
        commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE_LOADING, false);

        if (daterange === 'alltime' && campaigndaterange !== 'customRange') {
          // this is for DASH-2146, to get around a BE bug
          let newDate = adPerf.data.LastModifiedDate;
          // PROD-6623: use field from 'Total' section if available
          const totalObjectName = Object.keys(adPerf.data).find(key => key.toLowerCase().endsWith('total'));
          const totalLastUpdatedDate = adPerf.data[totalObjectName]?.LastModifiedDate;
          if (totalLastUpdatedDate && totalLastUpdatedDate !== null && totalLastUpdatedDate !== undefined) {
            newDate = totalLastUpdatedDate;
          }
          // dispatch('setStaticLastModifiedDate', newDate);
          commit(CONSTANTS.SET_STATIC_LAST_MODIFIED_DATE, newDate);
        } else {
          // set initial static_date same as in adPerf
          commit(CONSTANTS.SET_STATIC_LAST_MODIFIED_DATE, data.LastModifiedDate);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (urlCache as any)[cacheKey] = JSON.stringify(data);
        return;
      }
      // eslint-disable-next-line no-console
      console.error('invalid api response for getCampaignAdPerformance', adPerf);
      commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE, { error: 'no data', loaded: true });
      commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE_LOADING, false);
    } catch (exp) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = { loaded: true, error: exp.message };
      commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE, data);
      commit(CONSTANTS.SET_CAMPAIGN_AD_PERFORMANCE_LOADING, false);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getCampaignAdPerformance');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAllTimeAdPerformance({ commit, state }, { advertiserId, daterange, campaigns }): Promise<any> {
    if (!advertiserId || !daterange) {
      commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE, { loaded: true, error: 'empty' });
      commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
      return;
    }

    try {
      const reportUrl = `/api/getAdPerformance?a=${advertiserId}&daterange=${daterange}`;
      const cacheKey = `getAllTimeAdPerformance:${reportUrl}`;
      if (latestApiCalls.getAllTimeAdPerformance === cacheKey) {
        return state.allTimeAdPerformance;
      }
      latestApiCalls.getAllTimeAdPerformance = cacheKey;

      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cached: any = (urlCache as any)[cacheKey];
        if (cached) {
          const obj = JSON.parse(cached);
          if (obj) {
            commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE, obj);
            commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
          }
          return;
        }
      } catch (exp) {
        logError(exp, 'store.getAllTimeAdPerformance cache');
      }

      commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, true);

      const headers = { session_id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const adPerf: AxiosResponse<any> = await axios.post(reportUrl, { campaigns }, { headers });
      if (adPerf && adPerf.data) {
        if (adPerf.data.ErrorMessage) {
          // eslint-disable-next-line no-console
          console.error('invalid api response for getAllTimeAdPerformance', adPerf.data);
          commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE, { error: adPerf.data.ErrorMessage, loaded: true });
          commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = { ...adPerf.data, loaded: true };
        commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE, data);
        commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (urlCache as any)[cacheKey] = JSON.stringify(data);
        return;
      }
      // eslint-disable-next-line no-console
      console.error('invalid api response for getAllTimeAdPerformance', adPerf);
      commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE, { error: 'no data', loaded: true });
      commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
    } catch (exp) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = { loaded: true, error: exp.message };
      commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE, data);
      commit(CONSTANTS.SET_ALL_TIME_AD_PERFORMANCE_LOADING, false);
      throw logError(exp, 'store.getAllTimeAdPerformance');
    }
  },
  updateCampaigns({ commit }, campaigns): void {
    commit(CONSTANTS.UPDATE_CAMPAIGNS, campaigns);
  },
  setCampaigns({ commit }, campaigns): void {
    commit(CONSTANTS.SET_CAMPAIGNS, campaigns);
    // commit(CONSTANTS.SET_CAMPAIGNS, {
    //   campaignlist: payload.campaignlist,
    //   defaultdaterange: payload.defaultdaterange,
    //   loaded: true,
    // });
  },
  async resetCampaigns({ commit, dispatch }): Promise<void> {
    commit(CONSTANTS.SET_CAMPAIGNS, { campaignlist: null, defaultdaterange: null, loaded: false });
    dispatch('resetCampaignFilters'); // to filters store module
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getCampaigns({ commit, dispatch }, params): Promise<any> {
    if (!params.advertiserId) {
      // eslint-disable-next-line no-console
      console.error('store.getCampaigns', 'missing advertiserid');
      return;
    }
    try {
      let isAllTime = false;
      const { daterange, startdate, enddate, archiveStatus } = params;
      const { types = '', dmas = '', cache = true, skipCached } = params;
      let campaignUrl = `/api/getCampaigns/${params.advertiserId}?types=${types}&dmas=${dmas}`;
      if (daterange === 'customRange') {
        campaignUrl += `&startdate=${startdate}`;
        campaignUrl += `&enddate=${enddate}`;
      } else {
        campaignUrl += `&daterange=${daterange}`;
        if (daterange === 'alltime' && types.length === 0 && dmas.length === 0) {
          isAllTime = true;
        }
      }

      if (archiveStatus) {
        campaignUrl += `&archiveStatus=${archiveStatus}`;
      }

      // NOTE: selecting customRange more than once (different dates) appears to be the
      // 'same' api call when its not. When customRange selected, always call api not cache
      // otherwise customer.campaignlist stays null from reset
      if (latestApiCalls.getCampaigns === campaignUrl && cache && !skipCached) {
        return;
      }
      latestApiCalls.getCampaigns = campaignUrl;
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cached: any = (urlCache as any)[`${campaignUrl}${JSON.stringify(params.campaigns)}`];

        if (!skipCached && cached) {
          const obj = JSON.parse(cached);
          if (obj) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = {
              startDate: obj.StartDate,
              endDate: obj.EndDate,
              campaignlist: obj.Campaign,
              defaultdaterange: obj.DateRange,
              loaded: true,
            };
            commit(CONSTANTS.SET_CAMPAIGNS, data);
            dispatch('readCampaigns', obj); // to customer store
            if (isAllTime) {
              commit(CONSTANTS.SET_ALL_TIME_CAMPAIGNS, data);
            }
            return { data };
          }
          return;
        }
      } catch (exp) {
        logError(exp, 'store.getCampaigns cache');
      }
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      const headers = { session_id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: AxiosResponse<any> = await axios.post(campaignUrl, { campaigns: params.campaigns }, { headers });
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (urlCache as any)[campaignUrl] = JSON.stringify(res);

      if (res) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (urlCache as any)[campaignUrl] = JSON.stringify(res.data);

        if (res.data) {
          const data: any = {
            startDate: res.data.StartDate,
            endDate: res.data.EndDate,
            campaignlist: res.data.Campaign,
            defaultdaterange: res.data.DateRange,
            loaded: true,
            CampTypes: res.data?.CampTypes,
            Campaign: res.data?.Campaign,
          };

          commit(CONSTANTS.SET_CAMPAIGNS, data);
          dispatch('readCampaigns', res.data); // to customer store
          if (isAllTime) {
            commit(CONSTANTS.SET_ALL_TIME_CAMPAIGNS, data);
          }
          return { data };
        } else {
          commit(CONSTANTS.SET_CAMPAIGNS, null);
        }
      }
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getCampaigns');
    }
  },
  async archiveCampaign({ commit, dispatch }, { callback, ...payload }): Promise<void> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/archiveCampaign', payload);

      await dispatch('resetAllCampaigns');

      if (callback) {
        await callback();
      }
      return req.data;
    } catch (error) {
      commit(CONSTANTS.SET_ERROR, error.message);
      throw logError(error, 'store.customer.archiveCampaign');
    } finally {
    }
  },
  async getCampaignNarrativeSummary({ commit }, payload): Promise<void> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/getCampaignNarrativeSummary', payload);

      return req.data;
    } catch (error) {
      throw logError(error, 'store.customer.getCampaignNarrativeSummary');
    }
  },
  async getTacticSummary({ commit, state }, params): Promise<void> {
    if (!params.tactic || !params.advertiserId) {
      // eslint-disable-next-line no-console
      console.error('getTacticSummary missing inputs', params.tactic, params.advertiserId);
      commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE, { loaded: true, error: 'empty' });
      commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE_LOADING, false);
      return;
    }

    try {
      // passing a token for sharing.
      const t = params.token ? `&token=${params.token}` : '';
      const reportUrl = `/api/getTacticSummary?tactic=${params.tactic}&advertiserId=${params.advertiserId}&dateRange=alltime${t}`;

      const cacheKey = `getTacticSummaryPerformance:${reportUrl}`;
      if (latestApiCalls.getTacticSummaryPerformance === cacheKey && !params.force) {
        return state.tacticSummaryPerformance;
      }
      latestApiCalls.getTacticSummaryPerformance = cacheKey;
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cached: any = (urlCache as any)[cacheKey];
        if (cached) {
          const obj = JSON.parse(cached);
          if (obj) {
            commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE, obj);
            commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE_LOADING, false);
          }
          return;
        }
      } catch (exp) {
        logError(exp, 'store.getTacticSummaryPerformance cache');
      }
      commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE_LOADING, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const summary: AxiosResponse<any> = await axios.get(reportUrl);

      const { body } = summary.data;
      commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE, body);
      commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE_LOADING, false);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
    } catch (exp) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = { loaded: true, error: exp.message };
      commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE, data);
      commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE_LOADING, false);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getTacticSummary');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async setTacticSummary({ commit, state }, summaryData): Promise<void> {
    commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE, summaryData);
    commit(CONSTANTS.SET_TACTIC_SUMMARY_PERFORMANCE_LOADING, false);
  },
  async setCampaignFriendlyName(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    params: SetCampaignFriendlyName,
  ): Promise<{ success: boolean; error?: string }> {
    if (!params.advertiserId || !params.campaignId || !params.friendlyName) {
      const error = 'Missing required field';
      logError(error, 'store.setCampaignFriendlyName');
      return { success: false, error };
    }
    try {
      const url = `/api/setCampaignFriendlyName`;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any> = await requests.setCampaignFriendlyName(url, params);

      urlCache = {};
      latestApiCalls = {};

      if (response.data?.Attributes) return { success: true };
      if (response.data?.error) throw new Error(response.data.error);
      else throw new Error('Failed request');
    } catch (err) {
      logError(err, 'store.setCampaignFriendlyName catch');
      return { success: false, error: err.message };
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAllTimeCampaigns({ commit, state }, params): Promise<any> {
    if (!params.advertiserId) {
      // eslint-disable-next-line no-console
      console.error('store.getAllTimeCampaigns', 'missing advertiserid');
      return;
    }
    try {
      const { daterange } = params;
      const campaignUrl = `/api/getCampaigns/${params.advertiserId}?daterange=${daterange}`;

      if (latestApiCalls.getAllTimeCampaigns === campaignUrl) {
        return state.allTimeCampaigns;
      }
      latestApiCalls.getAllTimeCampaigns = campaignUrl;

      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cached: any = (urlCache as any)[`${campaignUrl}${JSON.stringify(params.campaigns)}`];
        if (cached) {
          const obj = JSON.parse(cached);
          if (obj) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = {
              startDate: obj.StartDate,
              endDate: obj.EndDate,
              campaignlist: obj.Campaign,
              defaultdaterange: obj.DateRange,
              loaded: true,
            };
            commit(CONSTANTS.SET_ALL_TIME_CAMPAIGNS, data);
            return data;
          }
          return {
            campaignlist: [],
            error: 'no data',
            loaded: true,
          };
        }
      } catch (exp) {
        logError(exp, 'store.getAllTimeCampaigns cache');
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post(campaignUrl, { campaigns: params.campaigns });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (urlCache as any)[campaignUrl] = JSON.stringify(req);
      if (req && req.data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (urlCache as any)[campaignUrl] = JSON.stringify(req.data);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = {
          startDate: req.data.StartDate,
          endDate: req.data.EndDate,
          campaignlist: req.data.Campaign,
          defaultdaterange: req.data.DateRange,
          loaded: true,
        };

        commit(CONSTANTS.SET_ALL_TIME_CAMPAIGNS, data);
        return data;
      }
    } catch (exp) {
      throw logError(exp, 'store.getAllTimeCampaigns');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setCampaignSpendType({ commit }, payload: { data: any }): Promise<{ error: string }> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/setCampaignSpendType', payload.data);

      if (req.status === 200 && req.data?.SpendRate) {
        commit(CONSTANTS.SET_CAMPAIGN_SPEND_RATE, req.data.SpendRate);
      }

      return req.data;
    } catch (exp) {
      commit(CONSTANTS.SET_ERROR, exp.message);
      throw logError(exp, 'store.customer.setCampaignSpendType');
    } finally {
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getDMAPolygons({ commit }): Promise<any> {
    commit(CONSTANTS.SET_DMA_POLYGONS_LOADING, true);
    try {
      const dmasUrl = '/api/test_geo.json';

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cached: any = (urlCache as any)[dmasUrl];
      if (cached) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const obj: any = JSON.parse(cached);
        if (obj && obj.data) {
          if (obj.data.ErrorMessage) {
            // eslint-disable-next-line no-console
            console.error('invalid cached SET_DMAPOLYGONS', obj.data);
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const data: any = { ...obj.data, loaded: true };
          commit(CONSTANTS.SET_DMA_POLYGONS, data);
          commit(CONSTANTS.SET_DMA_POLYGONS_LOADING, false);
          return;
        }
        // eslint-disable-next-line no-console
        console.error('invalid cached SET_POLYGONS', obj);
        return;
      }

      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const polygonResponse: AxiosResponse<any> = await axios.get(dmasUrl);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (urlCache as any)[dmasUrl] = JSON.stringify(polygonResponse);
      if (polygonResponse && polygonResponse.data) {
        if (polygonResponse.data.ErrorMessage) {
          // eslint-disable-next-line no-console
          console.error('invalid api response for test_dmas', polygonResponse.data);
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = { ...polygonResponse.data, loaded: true };
        commit(CONSTANTS.SET_DMA_POLYGONS, data);
        commit(CONSTANTS.SET_DMA_POLYGONS_LOADING, false);
        return;
      }
      // eslint-disable-next-line no-console
      console.error('invalid api response for test_dmas', polygonResponse);
    } catch (exp) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = { loaded: true, error: exp.message };
      commit(CONSTANTS.SET_DMA_POLYGONS, data);
      commit(CONSTANTS.SET_DMA_POLYGONS_LOADING, false);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getDMAPolygons');
    }
  },
  setFetching({ commit }, payload: { cid: string; completed: boolean; url?: string }): void {
    commit(CONSTANTS.SET_FETCHING, { ...payload });
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPolygons({ commit }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(CONSTANTS.SET_POLYGONS_LOADING, true);
      try {
        const dmasUrl = '/api/getPolygons';

        const cacheKey = `getPolygons:"${JSON.stringify(payload)}`;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cached: any = (urlCache as any)[cacheKey];
        if (cached) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const obj: any = JSON.parse(cached);
          if (obj && obj.data) {
            if (obj.data.ErrorMessage) {
              // eslint-disable-next-line no-console
              console.error('invalid cached getPolygons', obj.data);
              resolve(obj.data);
              return;
            }
            commit(CONSTANTS.SET_POLYGONS_LOADING, false);
            resolve(obj.data);
            return;
          }
          // eslint-disable-next-line no-console
          console.error('invalid cached getPolygons', obj);
          resolve(cached);
          return;
        }
        const headers = { session_id };
        commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
        axios
          .post(dmasUrl, payload, { headers })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((polygonResponse: AxiosResponse<any>) => {
            commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (urlCache as any)[cacheKey] = JSON.stringify(polygonResponse);
            if (polygonResponse && polygonResponse.data) {
              if (polygonResponse.data.ErrorMessage) {
                // eslint-disable-next-line no-console
                console.error('invalid api response for getPolygons', polygonResponse.data);
                resolve(polygonResponse.data);
              }
              commit(CONSTANTS.SET_POLYGONS_LOADING, false);
              resolve(polygonResponse.data);
              return;
            }
            // eslint-disable-next-line no-console
            console.error('invalid api response for getPolygons', polygonResponse);
            reject(new Error('invalid api response'));
            return;
          })
          .catch((exp: AxiosError) => {
            commit(CONSTANTS.SET_POLYGONS_LOADING, false);
            commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
            reject(logError(exp, 'store.getDMAPolygons'));
          });
      } catch (exp) {
        commit(CONSTANTS.SET_POLYGONS_LOADING, false);
        commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
        reject(logError(exp, 'store.getDMAPolygons'));
      }
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getGeocodes({ commit }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(CONSTANTS.SET_GEOCODES_LOADING, true);
      try {
        const url = '/api/getGeocodes';

        const cacheKey = `getGeocodes:"${JSON.stringify(payload)}`;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const cached: any = (urlCache as any)[cacheKey];
        if (cached) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const obj: any = JSON.parse(cached);
          if (obj && obj.data) {
            if (obj.data.ErrorMessage) {
              // eslint-disable-next-line no-console
              console.error('invalid cached getGeocodes', obj.data);
              resolve(obj.data);
              return;
            }
            commit(CONSTANTS.SET_GEOCODES_LOADING, false);
            resolve(obj.data);
            return;
          }
          // eslint-disable-next-line no-console
          console.error('invalid cached getGeocodes', obj);
          resolve(cached);
          return;
        }
        const headers = { session_id };
        commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

        axios
          .post(url, payload, { headers })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((geocodesResponse: AxiosResponse<any>) => {
            commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (urlCache as any)[cacheKey] = JSON.stringify(geocodesResponse);
            if (geocodesResponse && geocodesResponse.data) {
              if (geocodesResponse.data.ErrorMessage) {
                // eslint-disable-next-line no-console
                console.error('invalid api response for getGeocodes', geocodesResponse.data);
                resolve(geocodesResponse.data);
              }
              commit(CONSTANTS.SET_GEOCODES_LOADING, false);
              resolve(geocodesResponse.data);
              return;
            }
            // eslint-disable-next-line no-console
            console.error('invalid api response for getGeocodes', geocodesResponse);
            reject(new Error('invalid api response'));
            return;
          })
          .catch((exp: AxiosError) => {
            commit(CONSTANTS.SET_GEOCODES_LOADING, false);
            commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
            reject(logError(exp, 'store.getGeocodes'));
          });
      } catch (exp) {
        commit(CONSTANTS.SET_GEOCODES_LOADING, false);
        commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
        reject(logError(exp, 'store.getGeocodes'));
      }
    });
  },
  async getStation({ commit, state }, id): Promise<any> {
    try {
      const params = { id };

      const baseUrl = '/api/getStation';
      const queryString = new URLSearchParams(params).toString();

      const url = `${baseUrl}?${queryString}`;

      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(url);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (urlCache as any)[url] = JSON.stringify(req);
      if (req && req.data) return req.data;

      return { list: [], error: 'no data', loaded: true };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getStation');
    }
  },
  async getAdvertiserGeoSummary({ commit, state }, params): Promise<any> {
    try {
      const { products, isShared, query } = params;

      const payload = {
        advertiserId: state.selection.advertiserId,
        products: products.join(','),
        daterange: query?.summarydaterange || state.selection.daterange,
        endDate: query?.summaryenddate || state.selection.enddate,
        startDate: query?.summarystartdate || state.selection.startdate,
        isShared,
      };

      if (payload.daterange === 'customRange') {
        delete payload.daterange;
      } else {
        delete payload.startDate;
        delete payload.endDate;
      }

      const baseUrl = '/api/getAdvertiserGeoSummary';
      const queryString = new URLSearchParams(payload).toString();

      const url = `${baseUrl}?${queryString}`;

      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(url);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (urlCache as any)[url] = JSON.stringify(req);
      if (req && req.data) return req.data;

      return { list: [], error: 'no data', loaded: true };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getAdvertiserGeoSummary');
    }
  },
  async getAdvertiserTotalSummary({ commit, state }, params: any): Promise<any> {
    try {
      const { products, isShared, query } = params;
      const payload = {
        advertiserId: state.selection.advertiserId,
        products: products.join(','),
        daterange: query?.summarydaterange || state.selection.daterange,
        endDate: query?.summaryenddate || state.selection.enddate,
        startDate: query?.summarystartdate || state.selection.startdate,
        isShared,
      };

      if (payload.daterange === 'customRange') {
        delete payload.daterange;
      } else {
        delete payload.startDate;
        delete payload.endDate;
      }

      const baseUrl = '/api/getAdvertiserTotalSummary';
      const queryString = new URLSearchParams(payload).toString();

      const url = `${baseUrl}?${queryString}`;

      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(url);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (urlCache as any)[url] = JSON.stringify(req);
      if (req && req.data) {
        commit(CONSTANTS.SET_SUMMARY_PERFORMANCE, req.data);

        return req.data;
      }

      return { list: [], error: 'no data', loaded: true };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getAdvertiserTotalSummary');
    }
  },
  resetDateRanges({ commit }): void {
    commit(CONSTANTS.SET_DATERANGES, { loaded: false, error: 'empty' });
  },
  async getProductsForListOnSummary({ commit, state }, params): Promise<any> {
    try {
      const { advertiserId, daterange, isShared, view } = params;
      const req: AxiosResponse<any> = await axios.post('/api/getProductsForListOnSummary', {
        advertiserId,
        daterange,
        isShared,
        view,
      });
      return req.data;
    } catch (exp) {
      throw logError(exp, 'store.getProductsForListOnSummary');
    }
  },

  async getSummaryCampaignList({ commit, state }, params): Promise<any> {
    try {
      const urlparams = {
        advertiserId: params.advertiserId,
        daterange: params.daterange,
        enddate: params.enddate,
        startdate: params.startdate,
        productList: params.tactic,
        offset: params.offset || '0',
        limit: params.limit || '5',
        search: params.search || '',
        isShared: params.isShared,
      };

      const req: AxiosResponse<any> = await axios.post('/api/getSummaryCampaignList', urlparams);
      return req.data;
    } catch (exp) {
      throw logError(exp, 'store.getSummaryCampaignList');
    }
  },
  setDateRanges({ commit }, dateRanges): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { list: [] as any[], loaded: true };
    if (Array.isArray(dateRanges)) {
      data.list = dateRanges;
    } else if (dateRanges) {
      data.list = [dateRanges];
    }
    commit(CONSTANTS.SET_DATERANGES, data);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getDateRanges({ commit, state }, { advertiserId }): Promise<any> {
    if (!advertiserId) {
      commit(CONSTANTS.SET_DATERANGES, { loaded: true, error: 'empty' });
      return;
    }
    try {
      let rangeUrl = '/api/getDateRanges?';
      rangeUrl += `a=${advertiserId}`;

      if (latestApiCalls.getDateRanges === rangeUrl) {
        return state.dateRanges;
      }
      latestApiCalls.getDateRanges = rangeUrl;

      if (!window.noAdBlocker) {
        commit(CONSTANTS.SET_ADBLOCK, true);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cached: any = (urlCache as any)[rangeUrl];
      if (cached) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const obj: any = JSON.parse(cached);
        if (obj && obj.data) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const data: any = { list: [] as any[], loaded: true };
          if (Array.isArray(obj.data.DateRanges)) {
            data.list = obj.data.DateRanges;
          } else if (obj.data.DateRanges) {
            data.list = [obj.data.DateRanges];
          }
          commit(CONSTANTS.SET_DATERANGES, data);
          return data;
        }
        return { list: [], error: 'no data', loaded: true };
      }
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(rangeUrl);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (urlCache as any)[rangeUrl] = JSON.stringify(req);
      if (req && req.data) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = { list: req.data.DateRanges, loaded: true };
        commit(CONSTANTS.SET_DATERANGES, data);
        return data;
      }
      return { list: [], error: 'no data', loaded: true };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (exp.message === 'Network Error') {
        logError(exp, 'getDateRanges: network error');
        commit(CONSTANTS.SET_ADBLOCK, true);
        return { list: [], error: exp.message, loaded: true };
      }
      throw logError(exp, 'store.getDateRanges');
    }
  },
  async getSummaryDateRanges({ commit, state }, { advertiserId, viewName }): Promise<any> {
    if (!advertiserId) {
      commit(CONSTANTS.SET_SUMMARY_DATE_RANGES, { loaded: true, error: 'empty' });
      return;
    }
    try {
      const rangeUrl = `/api/getSummaryDateRanges?advertiserId=${advertiserId}&viewName=${viewName}`;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(rangeUrl);
      if (req && req.data) {
        commit(CONSTANTS.SET_SUMMARY_DATE_RANGES, req.data);
        return req.data;
      }
      return { list: [], error: 'no data', loaded: true };
    } catch (exp) {
      if (exp.message === 'Network Error') {
        logError(exp, 'getSummaryDateRanges: network error');
        commit(CONSTANTS.SET_ADBLOCK, true);
        return { list: [], error: exp.message, loaded: true };
      }
      throw logError(exp, 'store.getSummaryDateRanges');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDashboard({ commit }, dashboard: any): void {
    commit(CONSTANTS.SET_DASHBOARD, dashboard);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAdvertiserPolygons({ commit }, polygons: any): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { ...polygons, loaded: true };
    commit(CONSTANTS.SET_POLYGONS, data);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDMAPolygons({ commit }, polygons: any): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { ...polygons, loaded: true };
    commit(CONSTANTS.SET_DMA_POLYGONS, data);
  },
  setCurrentSection({ commit }, section: LayoutSection): void {
    commit(CONSTANTS.SET_CURRENT_SECTION, section);
    if (section && section.id) {
      commit(CONSTANTS.SET_NAV_TAB, section.id);
    } else {
      commit(CONSTANTS.SET_NAV_TAB, undefined);
    }
  },
  async getAuth({ commit }): Promise<AuthUser> {
    try {
      // fetch w/o interceptors ?
      const req: AxiosResponse<any> = await axios.get('/api/auth');
      commit('AUTHCHECK_DONE', true);
      if (req && req.data) {
        if (req.data.auth) {
          commit(CONSTANTS.SET_USER, req.data);
          return req.data;
        }

        return { auth: false, error: ERRORS.FAILED_AUTH };
      }
    } catch (exp) {
      const authCheck = { error: exp.message };
      commit(CONSTANTS.SET_USER, authCheck);
      throw logError(exp, 'store.getAuth');
    }

    return { auth: false, error: ERRORS.FAILED_AUTH };
  },
  async login({ commit }, { email, password, domain }): Promise<AuthUser> {
    try {
      const headers = { session_id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post(
        '/api/login',
        {
          email,
          password,
          domain,
        },
        { headers },
      );
      if (req && req.data) {
        if (req.data.auth) {
          commit(CONSTANTS.SET_USER, req.data);
          return req.data;
        }
        if (req.data.error) {
          return { auth: false, error: ERRORS.FAILED_LOGIN };
        }
        return { auth: false, error: ERRORS.FAILED_AUTH };
      }
      return { auth: false, error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      const authCheck = { error: exp.message };
      commit(CONSTANTS.SET_USER, authCheck);
      throw logError(exp, 'store.getAuth');
    }
  },
  async tokenlogin({ commit }, { token, domain }): Promise<AuthUser> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/tokenlogin', { token, domain });
      if (req && req.data) {
        if (req.data.auth) {
          commit(CONSTANTS.SET_USER, req.data);
          return req.data;
        }
        if (req.data.error) {
          return { auth: false, error: req.data.error };
        }
        if (req.data.notfound) {
          return { auth: false, notfound: true };
        }
        return { auth: false, error: ERRORS.FAILED_AUTH };
      }
      return { auth: false, error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      // I don't think we want to store an error user in state for token login
      // const authCheck = { error: exp.message };
      // commit(CONSTANTS.SET_USER, authCheck);
      throw logError(exp, 'store.tokenlogin');
    }
  },
  async ahlogin({ commit }, { token, domain }): Promise<AuthUser> {
    try {
      const headers = { session_id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/ahlogin', { token, domain }, { headers });
      if (req && req.data) {
        if (req.data.auth) {
          commit(CONSTANTS.SET_USER, req.data);
          return req.data;
        }
        if (req.data.error) {
          return { auth: false, error: req.data.error };
        }
        if (req.data.notfound) {
          return { auth: false, notfound: true };
        }
        return { auth: false, error: ERRORS.FAILED_AH_AUTH };
      }
      return { auth: false, error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      const authCheck = { error: exp.message };
      commit(CONSTANTS.SET_USER, authCheck);
      throw logError(exp, 'store.ahlogin');
    }
  },
  async ahswitchagency({ commit }, { agencyId }): Promise<AuthUser> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/ahswitchagency', { agencyId });
      if (req && req.data) {
        if (req.data.auth) {
          commit(CONSTANTS.SET_USER, req.data);
          return req.data;
        }
        if (req.data.error) {
          return { auth: false, error: req.data.error };
        }
        if (req.data.notfound) {
          return { auth: false, notfound: true };
        }
        return { auth: false, error: ERRORS.FAILED_AH_AUTH };
      }
      return { auth: false, error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      const authCheck = { error: exp.message };
      commit(CONSTANTS.SET_USER, authCheck);
      throw logError(exp, 'store.ahlogin');
    }
  },
  async updateAccessToken({ state }): Promise<String> {
    // TODO: not used (?)
    try {
      const headers = {
        Authorization: `Bearer ${state.user.accessToken}`,
      };
      const refreshToken = localStorage.getItem('ah:jwtToken');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post(
        `${process.env.VUE_APP_AH_API_ROOT}api/auth/token/refresh/`,
        { refresh: refreshToken },
        { headers: headers },
      );
      return req.data.refresh;
    } catch (exp) {
      throw logError(exp, 'store.ahtokenmp');
    }
  },
  async logout({ commit, state }, { token, error }): Promise<AuthUser> {
    try {
      if (!state.user && !error) {
        /* tslint-disable-next-line */
        window.location.href = '/';
        return { auth: false, success: 'logged out' };
      }
      const headers = { session_id };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(`/api/logout?token=${token}`, { headers });
      if (req && req.data) {
        commit(CONSTANTS.SET_USER, { success: 'logged out' });
        /* tslint-disable-next-line */
        window.location.href = '/';
        return { auth: false, success: 'logged out' };
      }
      return { auth: false, error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      const authCheck = { error: exp.message };
      commit(CONSTANTS.SET_USER, authCheck);
      throw logError(exp, 'store.logout');
    }
  },
  async shareLogout({ commit, state }): Promise<AuthUser> {
    try {
      if (!state.user) {
        return { auth: false, success: 'logged out' };
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get('/api/logout');
      if (req && req.data) {
        commit(CONSTANTS.SET_USER, { success: 'logged out' });
        return { auth: false, success: 'logged out' };
      }
      return { auth: false, error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      const authCheck = { error: exp.message };
      commit(CONSTANTS.SET_USER, authCheck);
      throw logError(exp, 'store.logout');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getLayout({ commit }, payload: any): Promise<any> {
    try {
      commit(CONSTANTS.SET_LAYOUT_LOADING, true);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(`/api/getLayout?agency=${payload.agency}`);
      commit(CONSTANTS.SET_LAYOUT_LOADING, false);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req && req.data.error) {
          return { error: req.data.error };
        }
        commit(CONSTANTS.SET_LAYOUT, req.data);
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      commit(CONSTANTS.SET_LAYOUT_LOADING, false);
      throw logError(exp, 'store.getLayout');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setLayoutType({ commit }, payload): Promise<any> {
    commit(CONSTANTS.SET_LAYOUT_TYPE, payload);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setLayoutAgencyName({ commit }, payload): Promise<any> {
    commit(CONSTANTS.SET_LAYOUT_AGENCY_NAME, payload);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setLayoutAdvertiserId({ commit }, payload): Promise<any> {
    commit(CONSTANTS.SET_LAYOUT_ADVERTISER_ID, payload);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setLayoutVersion({ commit }, payload): Promise<any> {
    commit(CONSTANTS.SET_LAYOUT_VERSION, payload);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setSharedView({ commit }): Promise<any> {
    commit(CONSTANTS.SET_DASHBOARD_SHARED, true);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setSharedDateRanges({ commit }, payload): Promise<any> {
    commit(CONSTANTS.SET_SHARED_DATERANGES, payload);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setSharedCampaigns({ commit }, payload): Promise<any> {
    commit(CONSTANTS.SET_SHARED_CAMPAIGNS, payload);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async setSharedLinkReady({ commit }): Promise<any> {
    commit(CONSTANTS.SET_SHARE_CONTENT_LOADING, false);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getSharedDashboard({ commit }, payload): Promise<any> {
    commit(CONSTANTS.SET_SHARE_CONTENT_LOADING, true);
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      let url = '/api/getSharedDashboard?';
      if (payload.shortId) {
        url += 'id=' + payload.shortId;
      } else {
        url += 't=' + payload.token;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(url);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data && req.data.dashboard && req.data.dashboard.etoken) {
        const token = req.data.dashboard.etoken;
        commit(CONSTANTS.SET_USER, { auth: true, token });
        return req.data.dashboard;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getSharedDashboard');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getSharedGoogleCredentials({ commit }, payload): Promise<any> {
    commit(CONSTANTS.SET_SHARE_CONTENT_LOADING, true);
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(`/api/getSharedGoogleCredentials?t=${payload.token}`);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getSharedGoogleCredentials');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setGoogleCredentialsId({ commit }, id: any): void {
    commit(CONSTANTS.SET_GOOGLE_CREDENTIALS, id);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getUsers({ commit }, payload): Promise<any> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/getUsers', payload);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      if (req && req.data.error) {
        return { error: req.data.error };
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getUsers');
    }
  },
  async getUser({ commit }, email: string): Promise<object> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/getUser', email);
      if (req && req.data) {
        return req.data;
      }
      if (req && req.data.error) {
        return { error: req.data.error };
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      throw logError(exp, 'store.getUser');
    } finally {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
    }
  },
  async createUser({ commit }, payload: { data: User }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/createUser', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.createUser');
    }
  },
  async updateUsers({ commit }, payload: { data: User }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/updateUsers', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.updateUsers');
    }
  },
  async updateUserPassword({ commit }, payload: {}): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/updateUserPassword', payload);
      return req.data;
    } catch (exp) {
      throw logError(exp, 'store.updateUserPassword');
    } finally {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
    }
  },
  async toggleUser({ commit }, payload: { data: User }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/toggleUser', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.toggleUser');
    }
  },
  async deleteUser({ commit }, payload: { data: User }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/deleteUser', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.deleteUser');
    }
  },
  async getUserAllRights({ commit }): Promise<{}> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get('/api/getAllRights');
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);

      if (req && req.data.error) {
        return { error: req.data.error };
      }

      if (req && req.data) {
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getUserAllRights');
    }
  },
  async createRight({ commit }, payload: { data: Rights }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/createRight', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.createRight');
    }
  },
  async updateRight({ commit }, payload: { data: Rights }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/updateRight', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.updateRight');
    }
  },
  async toggleRight({ commit }, payload: { data: Rights }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/toggleRight', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.toggleRight');
    }
  },
  async deleteRight({ commit }, payload: { data: Rights }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/deleteRight', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.deleteRight');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAllUserAccessGroups({ commit }, payload): Promise<any> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/getAllUserAccessGroups', payload);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      if (req && req.data.error) {
        return { error: req.data.error };
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getAllUserAccessGroups');
    }
  },
  async createUserAccessGroup({ commit }, payload: { data: UserAccessGroup }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/createUserAccessGroup', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.createUserAccessGroup');
    }
  },
  async updateUserAccessGroup({ commit }, payload: { data: UserAccessGroup }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/updateUserAccessGroup', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.updateUserAccessGroup');
    }
  },
  async toggleUserAccessGroup({ commit }, payload: { data: UserAccessGroup }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/toggleUserAccessGroup', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.toggleUserAccessGroup');
    }
  },
  async deleteUserAccessGroup({ commit }, payload: { data: UserAccessGroup }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/deleteUserAccessGroup', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.deleteUserAccessGroup');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async searchAssignedAdvertisersForUsers({ commit }, payload): Promise<any> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/searchAssignedAdvertisersForUsers', payload);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      if (req && req.data.error) {
        return { error: req.data.error };
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.searchAssignedAdvertisersForUsers');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async searchAssignedAdvertisersForAdvertiserGroups({ commit }, payload): Promise<any> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/searchAssignedAdvertisersForAdvertiserGroups', payload);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      if (req && req.data.error) {
        return { error: req.data.error };
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.searchAssignedAdvertisersForAdvertiserGroups');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAllAdvertiserGroups({ commit }, payload): Promise<any> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/getAllAdvertiserGroups', payload);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      if (req && req.data.error) {
        return { error: req.data.error };
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getAllAdvertiserGroups');
    }
  },
  async createAdvertiserGroup({ commit }, payload: { data: AdvertiserGroup }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/createAdvertiserGroup', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.createAdvertiserGroup');
    }
  },
  async updateAdvertiserGroup({ commit }, payload: { data: AdvertiserGroup }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/updateAdvertiserGroup', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.updateAdvertiserGroup');
    }
  },
  async toggleAdvertiserGroup({ commit }, payload: { data: AdvertiserGroup }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/toggleAdvertiserGroup', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.toggleAdvertiserGroup');
    }
  },
  async deleteAdvertiserGroup({ commit }, payload: { data: AdvertiserGroup }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/deleteAdvertiserGroup', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.deleteAdvertiserGroup');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAllUserRoles({ commit }, payload): Promise<any> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/getAllUserRoles', payload);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      if (req && req.data.error) {
        return { error: req.data.error };
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getAllUserRoles');
    }
  },
  async createUserRoles({ commit }, payload: { data: UserRoles }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/createUserRoles', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.createUserRoles');
    }
  },
  async updateUserRoles({ commit }, payload: { data: UserRoles }): Promise<{ error: string }> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/updateUserRoles', payload.data);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        if (req.data.message) return { error: req.data.message };
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.updateUserRoles');
    }
  },
  async getPermissionTypes({ commit }): Promise<{}> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get('/api/getPermissionTypes');
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getPermissionTypes');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAdvertisers({ commit }, payload): Promise<any> {
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/getAdvertisers', payload);
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      if (req && req.data.error) {
        return { error: req.data.error };
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getAdvertisers');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addNewModule({ commit, state }, payload: { newModule: any; makeFirst?: boolean; hasAdvLevel?: boolean }): void {
    const { newModule, makeFirst, hasAdvLevel } = payload;
    const compSetIndex = state.dynamicLayout.layoutCustomizations.config.components.findIndex(
      c => c.type !== 'summaryonly',
    );
    commit(CONSTANTS.ADD_MODULE, { compSetIndex, newModule, makeFirst, hasAdvLevel });
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOverrides({ commit, state }, payload: { compId: string; overrides: any }): void {
    const { compId, overrides } = payload;
    const compSetIndex = state.dynamicLayout.layoutCustomizations.config.components.findIndex(
      c => c.type !== 'summaryonly',
    );
    const compIndex = state.dynamicLayout.layoutCustomizations.config.components[compSetIndex].components.findIndex(
      c => c.id === compId,
    );

    commit(CONSTANTS.SET_OVERRIDES, { overrides, compSetIndex, compIndex });
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setLayout({ commit }, layout: any): void {
    commit(CONSTANTS.SET_LAYOUT, layout);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setLayoutSource({ commit }, layout: any): void {
    const source = layout;
    source.layoutCustomizations.pstate = `${source.layoutCustomizations.pstate}`;
    commit(CONSTANTS.SET_LAYOUT_SOURCE, source);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDefaultLayout({ commit }, layout: any): void {
    commit(CONSTANTS.SET_DEFAULT_LAYOUT, layout);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setEditTheme({ commit }, themeData: any): void {
    commit(CONSTANTS.SET_EDIT_THEME, themeData);
  },
  setTheme({ commit }, theme: Theme): void {
    const computedTheme: Theme = { ...theme };
    commit(CONSTANTS.SET_THEME, computedTheme);
  },
  setStaticLastModifiedDate({ commit }, staticLastModifiedDate: string): void {
    commit(CONSTANTS.SET_STATIC_LAST_MODIFIED_DATE, staticLastModifiedDate);
  },
  async checkGoogleOAuthNew({ commit }, payload): Promise<object> {
    if (!payload || !payload.token || !payload.agencyId) {
      return { error: 'missing parameter', payload };
    }
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.post('/api/checkGoogleOAuthNew', {
        agencyId: payload.agencyId,
        token: payload.token,
      });
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      if (req && req.data.error) {
        return { error: req.data.error };
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.checkGoogleOAuthNew');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAdvertisers({ commit }, advertisers: any): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = { list: advertisers, loaded: true };
    commit(CONSTANTS.SET_ADVERTISERS, data);
  },
  async setExportData({ commit }, payload): Promise<void> {
    commit(CONSTANTS.SET_EXPORT_CONTENT, payload);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getExportData({ commit }, payload): Promise<any> {
    commit(CONSTANTS.SET_EXPORT_CONTENT_LOADING, true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(`/api/getExportData?t=${payload.token}`);
      commit(CONSTANTS.SET_EXPORT_CONTENT_LOADING, false);
      // if (req && req.data && req.data.user && req.data.dashboard) {
      if (req && req.data) {
        commit(CONSTANTS.SET_EXPORT_CONTENT, req.data);
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_EXPORT_CONTENT_LOADING, false);
      throw logError(exp, 'store.getExportData');
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setExportState({ commit }, state: any): void {
    commit(CONSTANTS.SET_EXPORT_STATE, state);
  },
  async getAhAdvertiserCampaign({ commit }, payload): Promise<{}> {
    const { advertiserId, campaignId } = payload;
    const headers = { session_id };
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(
        `/api/getAhAdvertiserCampaign?advertiserId=${advertiserId}&campaignId=${campaignId}`,
        { headers },
      );
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.getAhAdvertiserCampaign');
    }
  },
  async updateAhAdvertiserSubagencyCampaign({ commit }, payload): Promise<{}> {
    const { advertiserId, subagencyId, campaignId } = payload;
    const headers = { session_id };
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(
        `/api/updateAhAdvertiserSubagencyCampaign?advertiserId=${advertiserId}&subagencyId=${subagencyId}&campaignId=${campaignId}`,
        { headers },
      );
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.updateAhAdvertiserSubagencyCampaign');
    }
  },
  async updateAhAdvertiserStationCampaign({ commit }, payload): Promise<{}> {
    const { advertiserId, stationId, campaignId } = payload;
    const headers = { session_id };
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(
        `/api/updateAhAdvertiserStationCampaign?advertiserId=${advertiserId}&stationId=${stationId}&campaignId=${campaignId}`,
        { headers },
      );
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.updateAhAdvertiserStationCampaign');
    }
  },
  async deleteAhAdvertiserSubagencyCampaign({ commit }, payload): Promise<{}> {
    const { advertiserId, subagencyId, campaignId } = payload;
    const headers = { session_id };
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(
        `/api/deleteAhAdvertiserSubagencyCampaign?advertiserId=${advertiserId}&subagencyId=${subagencyId}&campaignId=${campaignId}`,
        { headers },
      );
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.deleteAhAdvertiserSubagencyCampaign');
    }
  },
  async deleteAhAdvertiserStationCampaign({ commit }, payload): Promise<{}> {
    const { advertiserId, stationId, campaignId } = payload;
    const headers = { session_id };
    try {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const req: AxiosResponse<any> = await axios.get(
        `/api/deleteAhAdvertiserStationCampaign?advertiserId=${advertiserId}&stationId=${stationId}&campaignId=${campaignId}`,
        { headers },
      );
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      if (req && req.data) {
        return req.data;
      }
      return { error: ERRORS.NO_RESPONSE };
    } catch (exp) {
      commit(CONSTANTS.SET_NETWORK_ACTIVITY, false);
      throw logError(exp, 'store.deleteAhAdvertiserStationCampaign');
    }
  },
};
