export default {
  SET_LINEAR_ADMIN_CLIENTS: 'SET_LINEAR_ADMIN_CLIENTS',
  SET_LINEAR_ADMIN_ERROR: 'LINEAR_ADMIN_ERROR',
  SET_LINEAR_ADMIN_FACEBOOK_ERROR: 'LINEAR_ADMIN_FACEBOOK_ERROR',
  SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR: 'LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR',
  SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ACCOUNT_ERROR: ' LINEAR_ADMIN_GOOGLE_ANALYTICS_ACCOUNT_ERROR',
  SET_GOOGLE_ANALYTIC_ACCOUNT: 'GOOGLE_ANALYTIC_ACCOUNT',
  SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_TOKEN_ERROR: ' ADMIN_GOOGLE_ANALYTICS_TOKEN_ERROR',
  SET_GOOGLE_ANALYTICS_LOADING: 'GOOGLE_ANALYTICS_LOADING',
  SET_GOOGLE_ADS_LOADING: 'GOOGLE_ADS_LOADING',
  SET_GOOGLE_ADS_ERROR: 'GOOGLE_ADS_ERROR',
  SET_FACEBOOK_LOADING: 'FACEBOOK_LOADING',
  SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
  SET_LOADING: 'SET_LOADING',
  SET_ADVERTISER: 'SET_ADVERTISER',
  SET_ACCOUNT_LINKING: 'SET_ACCOUNT_LINKING',
  SET_GOOGLE_AD_MANAGER_ERROR: 'SET_GOOGLE_AD_MANAGER_ERROR',
  SET_GOOGLE_AD_MANAGER_SAVE_ERROR: 'SET_GOOGLE_AD_MANAGER_SAVE_ERROR',
  SET_GOOGLE_AD_MANAGER_LOADING: 'SET_GOOGLE_AD_MANAGER_LOADING',
  SET_GOOGLE_AD_MANAGER_ACCOUNT_LINKING: 'SET_GOOGLE_AD_MANAGER_ACCOUNT_LINKING',
  SET_CAMPAIGN_MOVE: 'SET_CAMPAIGN_MOVE',
  SET_UPDATE_CAMPAIGN_DATA: 'SET_CAMPAIGN_MOVE',
  SET_CAMPAIGN_UNHIDE: 'SET_CAMPAIGN_UNHIDE',
  SET_CAMPAIGN_HIDE: 'SET_CAMPAIGN_HIDE',
  SET_SHOW_ALL_TACTICS: 'SET_SHOW_ALL_TACTICS',
  SET_ASSOCIATE_ORDER_WITH_CAMPAIGN: 'SET_ASSOCIATE_ORDER_WITH_CAMPAIGN',
  SET_CAMPAIGN_MOVE_TO_PRODUCT: 'SET_CAMPAIGN_MOVE_TO_PRODUCT',
};
