
import Vue from 'vue';
import utils from '../../../../util';
import { Advertiser, AdvertiserDataApi } from '../../../../store/modules/customer/types';

export default Vue.extend({
  name: 'AdvertiserDetails',
  components: {},
  props: ['dropdownTarget', 'superUser', 'advertiserName', 'advertiserId', 'advertiser'],
  data: (): {
    searchValue: string | undefined;
    advertiserSearch: string;
    loadingAdvertisers: boolean;
    searchTimer: Function | null;
    initialLoad: boolean;
  } => ({
    searchValue: undefined,
    advertiserSearch: '',
    loadingAdvertisers: false,
    searchTimer: null,
    initialLoad: true
  }),
  created() {
    // if shared page do not call getUserAdvertisers, we don't want to expose that data.
    if (!this.isShared) {
      this.search(this.advertiserName || '');
    }
  },
  computed: {
    formattedSearchResult(): Advertiser[] {
      if (!this.advertiserSearchResults || !this.advertiserSearchResults.length) return [];
      const res = this.advertiserSearchResults.map(obj => ({
        PropertyId: obj.id,
        Name: obj.name,
        Agency: obj.agency_id,
      }));

      if (this.initialLoad && this.advertiserId) {
        this.searchValue = res.find((advertiser) => advertiser.PropertyId === this.advertiserId);
        this.initialLoad = false;
      }

      return res;
    },
    advertiserSearchResults(): AdvertiserDataApi[] {
      return this.$store.state?.linearAdmin?.linearAdminClients?.advertisers || [];
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedSelection?.aid;
    },
    nameKey(): string {
      return 'Name';
    },
    cssClasses(): string {
      return 'd-flex flex-row px-3 pt-5 ma-0';
    },
  },
  methods: {
    selectAdvertiser(): void {
      this.$emit('select-advertiser', this.searchValue);
      this.$router.replace({ query: { advertiser: this.searchValue?.PropertyId } });
    },
    search(val: string): void {
      this.loadingAdvertisers = true;
      this.$store
        .dispatch('linearAdmin/getLinearAdminClientsSearch', {
          name: val,
          partners: this.$route.query.agency ? `${this.$route.query.agency}` : '',
          limit: 50,
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .finally(() => {
          this.loadingAdvertisers = false;
        });
    },
    isDemoAdvertiser(advertiser: Advertiser): string | null {
      return utils.isDemoAdvertiser(advertiser);
    },
  },
  watch: {
    advertiserSearch(val: string, oldVal: string) {
      if (!val) {
        // wipes out the searchvalue object
        // this prevents a bug where you get 0 results when searching for the same string that's in the selected advertiser name
        this.searchValue = null;
        return;
      }

      if (val && val.length < 0) {
        return;
      }

      if (this.searchValue && this.searchValue.Name === val) {
        return;
      }

      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.search(val);
      }, 500);
    },
    '$route.query.advertiser': {
      handler: function(advertiser) {
        if (advertiser === undefined) {
          this.searchValue = '';
          this.search('');
        }
      },
      deep: true,
      immediate: true
    }
  },
});
