import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  // EXPORT Conversions
  [CONSTANTS.SET_EXPORT_CONVERSIONS_ERROR](state: StateType, error): void {
    Vue.set(state, 'exportConversionsError', error);
  },
  [CONSTANTS.SET_IS_EXPORT_CONVERSIONS_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isExportConversionsLoading', isLoading);
  },

  // EXPORT Conversion Breakdown
  [CONSTANTS.SET_EXPORT_CONVERSIONS_BREAKDOWN_ERROR](state: StateType, error): void {
    Vue.set(state, 'exportConversionBreakdownError', error);
  },
  [CONSTANTS.SET_IS_EXPORT_CONVERSIONS_BREAKDOWN_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isExportConversionBreakdownLoading', isLoading);
  },
};
