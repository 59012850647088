
import Vue from 'vue';
import utils from '../../../../util';
import EditModuleBtn from '../buttons/editModuleBtn.vue';
import Tooltips from '../tooltip/chartTooltip.vue';
import exportDownloadBtn from '../buttons/exportDownloadBtn.vue';

export default Vue.extend({
  inheritAttrs: false,
  name: 'vennDiagram',
  components: { EditModuleBtn, Tooltips, exportDownloadBtn },
  props: [
    'sectionConfig',
    'componentConfig',
    'title',
    'chartType',
    'dataSource',
    'isExporting',
    'exportData',
    'componentHeight',
  ],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
  } => ({
    chartInstance: undefined,
  }),
  watch: {
    breakpoints: {
      handler(): void {
        this.onWindowResize();
      },
    },
  },
  mounted() {
    if (this.isExporting) {
      if (this.isHiddenOnAdvLevel) {
        this.$emit('rendered', { empty: true });
        return;
      }
      if (this.isXLS) {
        this.exportTableData();
        return;
      }
    }
    if (this.inEditMode) {
      this.$emit('set-display', true);
    } else {
      this.$emit('set-display', !this.isHiddenOnAdvLevel);
    }
    setTimeout(() => {
      if (this.hasEnoughData && !this.isHiddenOnAdvLevel) {
        this.$emit('rendered', { empty: false });
      } else {
        this.$emit('rendered', { empty: true });
      }
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  computed: {
    isHiddenOnAdvLevel(): boolean {
      return this.componentConfig.hideFromUI;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    calculatedcomponentHeight(): string {
      return '300px';
    },
    loading(): boolean {
      return utils.isWaitingOnData(this);
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      }
      return this.chartData[0];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartData(): any {
      const data = utils.adDataForKey(this, this.dataSource);
      if (!data?.LINEAR || !data?.OTT || !data?.OVERLAP) return [];
      const { LINEAR, OTT, OVERLAP } = data;
      if (!LINEAR?.ShareOfImpressions && !OTT?.ShareOfImpressions && !OVERLAP?.ShareOfImpressions) return [];
      LINEAR.title = 'Linear';
      OTT.title = 'CTV';
      OVERLAP.title = 'Overlap';
      return [OTT, LINEAR, OVERLAP];
    },
    breakpoints(): boolean {
      return this.componentConfig.breakpoints;
    },
    linearImpsValue(): string {
      // if (!this.chartData.length) return '';
      let linearImpsValue = this.chartData?.find(obj => obj.title === 'Linear')?.ShareOfImpressions || 90;
      linearImpsValue = Math.round(linearImpsValue / 10) * 10;
      return linearImpsValue;
    },
    imageLink(): string {
      return require(`@/assets/staticVennDiagrams/Linear_${this.linearImpsValue}.png`);
    },
    headers(): any {
      return [
        {
          text: 'TYPE',
          value: 'title',
          align: 'left',
          sortable: false,
          width: '30%',
        },
        {
          text: 'SHARES OF IMPS',
          value: 'ShareOfImpressions',
          align: 'right',
          sortable: false,
          width: '15%',
        },
        {
          text: 'REACH PROPORTION',
          value: 'ReachProportion',
          align: 'right',
          sortable: false,
          width: '15%',
        },
        {
          text: 'AVG FREQUENCY',
          value: 'AverageFrequency',
          align: 'right',
          sortable: false,
          width: '15%',
        },
        {
          text: 'UNIQUE REACH',
          value: 'UniqueReach',
          align: 'right',
          sortable: false,
          width: '15%',
        },
      ];
    },
    chartTitle(): string {
      return this.componentConfig.title;
    },
    canExportToXLS(): boolean {
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return this.hasEnoughData && !!this.componentConfig?.exportableTableData;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          // console.log('chartInstance line resize', this.chartInstance.id);
          this.chartInstance.resize();
        }
      }, 250);
    },
    colorsMapper(title, isContainer): any {
      const colors = {
        CTV: `rgba(187, 202, 255, ${isContainer ? '0.5' : '1'})`,
        Linear: `rgba(29, 122, 251, ${isContainer ? '0.5' : '1'})`,
        Overlap: `rgba(218, 10, 166, ${isContainer ? '0.5' : '1'})`,
      };
      return {
        backgroundColor: colors[title],
      };
    },
    formatPercents(item, metric): string {
      if (item.title === 'Overlap') {
        if (item[metric] === 0) {
          return metric === 'UniqueReach' ? '' : '0.00%';
        }
        return `${Math.round(item[metric] * 100) / 100}%`;
      }
      return `${item[metric].toFixed(2)}%`;
    },
    formatDecimal(item, metric): string {
      return item[metric].toFixed(1);
    },
    exportTableData(): void {
      const headers = this.headers.map(h => h.text);
      let data = this.chartData;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data = data.map((item: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.headers.map((h: any) => {
          if (h.value === 'title') return item[h.value];
          if (h.value === 'AverageFrequency') return this.formatDecimal(item, h.value);
          return this.formatPercents(item, h.value);
        });
      });
      if (data.length === 0) {
        this.$emit('rendered', { empty: true });
      } else {
        this.$emit('rendered', { empty: false, headers, data, config: this.componentConfig });
      }
    },
  },
});
