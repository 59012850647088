// these tactics share the same top metrics.

import { SummaryMetrics, TopTacticGroupings, TopTacticsGroup, SummaryMetric, TopTacticsWithMetrics } from './types';
import utils from '../../../util';

// this format allows us to generate groups of tables that display the associated metrics
export const topTacticGroupings: Array<TopTacticGroupings> = [
  {
    tactics: ['OTT', 'PREROLL', 'GAMVIDEO', 'VIDEO', 'AUDIO', 'TV2OTT'],
  },
  {
    tactics: ['GOOGLEVIDEO'],
  },
  {
    tactics: ['DISPLAY', 'GAMDISPLAY', 'SEM', 'SOCIAL', 'TRUGEOFENCE'],
  },
  {
    tactics: ['BROADCAST'],
  },
  {
    tactics: ['SITEIMPACT'],
  },
  {
    tactics: ['LINEAR'],
  },
  {
    tactics: ['CALLTRACKING'],
  },
  {
    tactics: ['BROADSTREET'],
  },
];

// sets config info around the key metrics we care to show in the summary view
// can be expanded to include abbreviated names or other details
const metrics: SummaryMetrics = new Map();
metrics.set('aired', { name: 'Times Aired', value: 'Aired' });
metrics.set('clicks', { name: 'Clicks', value: 'Clicks' });
metrics.set('clickthrough', { name: 'CTR', value: 'ClickThrough' });
metrics.set('clicktoviewrate', { name: 'Click to View Rate', value: 'ClickToViewRate' });
metrics.set('householdImpressions', { name: 'Household Imps', value: 'Impressions' });
metrics.set('impressions', { name: 'Imps All Time', value: 'Impressions' });
metrics.set('liftperspot', { name: 'Visits / Airing', value: 'LiftPerSpot' });
metrics.set('product', { name: 'Product', value: 'TagName' });
metrics.set('vc100', { name: '100% Viewed', value: 'VC100' });
metrics.set('vcr', { name: 'VCR', value: 'VCR' });
metrics.set('visits', { name: 'Visits', value: 'Visits' });
metrics.set('views', { name: 'Views', value: 'Views' });
metrics.set('viewrate', { name: 'View Rate', value: 'ViewRate' });
metrics.set('totalprocessedcalls', { name: 'Total Calls', value: 'TotalProcessedCalls' });
metrics.set('durationavgprocessedcalls', { name: 'Avg Duration', value: 'DurationAvg_ProcessedCalls' });
metrics.set('hovers', { name: 'Hovers', value: 'Hovers' });
metrics.set('conversions', { name: 'Convs All Time', value: 'Conversions' });
metrics.set('cvr', { name: 'CVR %', value: 'CVR' });

// each tactic has key metrics customers care to see
// this mapping associates those metrics with each tactic
export function getTopTactics() {
  return [
    // group 1
    {
      id: 'OTT',
      metrics: [
        metrics.get('product'),
        metrics.get('impressions'),
        metrics.get('vc100'),
        metrics.get('vcr'),
        metrics.get('conversions'),
        // metrics.get('cvr'),
      ],
    },
    {
      id: 'PREROLL',
      metrics: [
        metrics.get('product'),
        metrics.get('impressions'),
        metrics.get('vc100'),
        metrics.get('vcr'),
        metrics.get('conversions'),
        // metrics.get('cvr'),
      ],
    },
    {
      id: 'GAMVIDEO',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('vc100'), metrics.get('vcr')],
    },
    {
      id: 'VIDEO',
      metrics: [
        metrics.get('product'),
        metrics.get('impressions'),
        metrics.get('vc100'),
        metrics.get('vcr'),
        metrics.get('conversions'),
        // metrics.get('cvr'),
      ],
    },
    {
      id: 'GOOGLEVIDEO',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('views'), metrics.get('viewrate')],
    },
    {
      id: 'AUDIO',
      metrics: [metrics.get('product'), metrics.get('impressions')],
    },
    {
      id: 'TV2OTT',
      metrics: [metrics.get('product'), metrics.get('impressions')],
    },
    // group 2
    {
      id: 'DISPLAY',
      metrics: [
        metrics.get('product'),
        metrics.get('impressions'),
        metrics.get('clicks'),
        metrics.get('clickthrough'),
        metrics.get('conversions'),
        // metrics.get('cvr'),
      ],
    },
    {
      id: 'TRUGEOFENCE',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('clicks'), metrics.get('clickthrough')],
    },
    {
      id: 'GAMDISPLAY',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('clicks'), metrics.get('clickthrough')],
    },
    {
      id: 'SEM',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('clicks'), metrics.get('clickthrough')],
    },
    {
      id: 'SOCIAL',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('clicks'), metrics.get('clickthrough')],
    },
    // group 3
    {
      id: 'BROADCAST',
      metrics: [metrics.get('product'), metrics.get('aired'), metrics.get('visits'), metrics.get('liftperspot')],
    },
    // group 4
    {
      id: 'SITEIMPACT',
      metrics: [metrics.get('product'), metrics.get('views'), metrics.get('clicks'), metrics.get('clicktoviewrate')],
    },
    // group 5
    {
      id: 'LINEAR',
      metrics: [metrics.get('product'), metrics.get('householdImpressions')],
    },
    // group 6
    {
      id: 'CALLTRACKING',
      metrics: [metrics.get('product'), metrics.get('totalprocessedcalls'), metrics.get('durationavgprocessedcalls')],
    },
    // group 7
    {
      id: 'BROADSTREET',
      metrics: [metrics.get('product'), metrics.get('impressions'), metrics.get('clicks'), metrics.get('hovers')],
    },
  ];
}

export const tacticIcons = {
  display: 'photo_camera_back',
  trugeofence: 'photo_camera_back',
  truegeo: 'photo_camera_back',
  preroll: 'slideshow',
  ott: 'personal_video',
  ottctv: 'personal_video',
  video: 'slideshow',
  digitalvideovideoott: 'personal_video',
  videoott: 'personal_video',
  innovidxp: 'personal_video',
  facebook: 'perm_identity',
  social: 'perm_identity',
  facebookads: 'perm_identity',
  sem: 'search',
  siteimpact: 'email',
  emailmarketing: 'email',
  broadcast: 'rss_feed',
  tv: 'rss_feed',
  gam: 'assessment',
  gamdisplay: 'assessment',
  'display-oo': 'assessment',
  'display-gam': 'assessment',
  gamvideo: 'assessment',
  'video-oo': 'assessment',
  'video-gam': 'assessment',
  googlevideo: 'personal_video',
  youtube: 'personal_video',
  sinclairrsn: 'tv',
  linear: 'tv',
  audio: 'volume_up',
  calltracking: 'phone_in_talk',
  broadstreet: 'assessment',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTopTacticData(summaryData: any, tactic: TopTacticsGroup): TopTacticsWithMetrics | null {
  // match tactic name with summary data
  let data = summaryData[tactic.id];
  if (tactic.id.toLowerCase() === 'tv') {
    data = summaryData['BROADCAST'];
  }
  if (data) {
    const tacticData: TopTacticsWithMetrics = {
      id: tactic.id,
      slug: utils.slugify(utils.tacticTitleMap(data.TagName)),
      metrics: [],
    };

    tacticData.metrics = tactic.metrics.map((metric: SummaryMetric) => {
      const metricsData = { headerName: metric.name, dataKey: metric.value, value: null };
      if (metric.value === 'Clicks ' && !data.hasOwnProperty('Clicks')) {
        metricsData.value = 0;
      } else {
        metricsData.value = utils.formatNumberWithCommas(data[metric.value]);
      }
      return metricsData;
    });
    return tacticData;
  }
  return null;
}

export const validProducts = [
  'DISPLAY',
  'PREROLL',
  'OTT',
  'VIDEO',
  'AUDIO',
  'SOCIAL',
  'SEM',
  'BROADCAST',
  'BROADSTREET',
  'GAM',
  'LINEAR',
  'GAMVIDEO',
  'GAMDISPLAY',
  'GOOGLEVIDEO',
  'SITEIMPACT',
  'CALLTRACKING',
  'TV2OTT',
  'TRUGEOFENCE',
];

export function renderComponent(cpnt: string, subComponent: string, isExporting: boolean): boolean {
  let render = false;
  if (isExporting && subComponent === cpnt) {
    render = true;
  } else if (!isExporting) {
    render = true;
  }
  return render;
}
