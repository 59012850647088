import { getCssVar } from '@c360/ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const allThemes: any = {};

import compulseTheme from './compulse';
allThemes.compulse = compulseTheme;

import sbgTheme from './sbg';
allThemes.sbg = sbgTheme;

import demo from './demo';
allThemes.demo = demo;

// Should always be last. Should only get used if no other theme matches
import defaultTheme from './default';
allThemes.default = defaultTheme;

import { ThemeConfig } from './variables';

// const theme = require(`./${name}`);

// This is your plugin object. It can be exported to be used anywhere.
const ThemePlugin = {
  // pass both href and hostname, in case we need some matching rule more complex than just the domain

  install(Vue, location: { href: string; hostname: string; search: string; origin: string }) {
    const params = new URLSearchParams(location.search);

    let clientOrigin = location.origin.toLowerCase();
    let clientDomain = location.hostname.toLowerCase();
    clientDomain = 'https://' + clientDomain.toLowerCase().replace(/\.[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+\.xip.io/, '');

    // There are multiple ways a user can get a url to the site where the domain doesn't match the desired theme.
    // One example is when requesting access to various auth platforms (google, facebook).
    // We only provide these systems with a small set of domains so that we don't have to validate one for every domain we use.

    const themeName = params.get('site'); // only gets set when the theme won't match the domain
    const originOverride = params.get('domainOverride');
    if (originOverride) {
      clientOrigin = originOverride;
    }

    let activeTheme; // will be used once we find a matching theme
    const allThemeNames = Object.keys(allThemes); // list of all the theme names

    if (
      themeName &&
      (location.href.toLowerCase().includes('grant-access') ||
        location.href.toLowerCase().includes('grant-google-access') ||
        location.href.toLowerCase().includes('/view/?gct'))
    ) {
      // lookup theme based on site param
      for (const name of allThemeNames) {
        const theme: ThemeConfig = allThemes[name];

        if (theme.name === themeName) {
          activeTheme = theme;
          break;
        }
      }
    } else {
      for (const name of allThemeNames) {
        // lookup theme based on url
        const theme: ThemeConfig = allThemes[name];
        const domains = Object.values(theme.domains);
        if (domains.includes(clientOrigin) || domains.includes(clientDomain)) {
          activeTheme = theme;
          break;
        }
      }
    }

    activeTheme = sbgTheme;

    const setTheme = (val: ThemeConfig): void => {
      // TODO: figure out exports
      // file exports use the "byName" method to return the theme

      Vue.prototype.Theme = { ...val, set: setTheme };
    };

    Vue.prototype.Theme = { ...activeTheme, set: setTheme };

    Vue.prototype.Theme.light.primary = getCssVar('--c360-color-compulse');
    Vue.prototype.Theme.light.progressBarGradient = getCssVar('--c360-gradient-accent');
  },
};

export { allThemes };
export default ThemePlugin;
