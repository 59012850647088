export const componentConfig = {
  chartColors: [
    '#9819B8',
    '#1700EB',
    '#059BFF',
    '#1FFFBC',
    '#DD0737',
    '#FE00EB',
    '#6F18FF',
    '#7CD10D',
    '#F7B500',
    '#FF1A12',
    '#13B1C4',
    '#00F162',
    '#53FF0B',
    '#9DEDF2',
    '#A6CCF2',
    '#AFB2F2',
    '#CFB8F2',
    '#ECC1F3',
    '#F4C9E7',
    '#F5D1DA',
    '#F6DFDA',
    '#F8F0E2',
    '#F8F9E9',
    '#F6FBF1',
    '#F8FDF8',
    '#9E9E9E',
    '#C2BDBD',
  ],
};

export const componentHeight = '410px';

export const baseChartConfig = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    formatter: (params: any[]) => {
      let result = `${params[0]?.axisValue}<br/>`;

      params.forEach(param => {
        const marker = param?.marker || '';
        const value = param?.value || 0;
        result += `${marker} ${value} &bull; ${param?.seriesName} <br/>`;
      });

      const totalConversions = params[0]?.data?.totalConversions;
      result += `<strong>${totalConversions} CONVERSIONS</strong><br/>`;

      return result;
    },
  },
  legend: {
    left: '3%',
    bottom: '0%',
    padding: [5, 10],
    icon: 'circle',
    itemWidth: 18,
    itemHeight: 18,
    itemGap: 15,
    align: 'right',
    textStyle: {
      color: '#3E5769',
      fontSize: 12,
      fontWeight: 600,
    },
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '10%',
    top: '10%',
    containLabel: true,
  },
  yAxis: {
    type: 'value',
  },
};
