
import Vue from 'vue';
import { C360Icon } from '@c360/ui';
import utils from '../../../../util';

export default Vue.extend({
  name: 'NewExportDownloadBtn',
  data: (): {
    isExporting: boolean;
    menuOpened: boolean;
  } => ({
    isExporting: false,
    menuOpened: false,
  }),
  components: {
    C360Icon,
  },
  props: {
    downloadFunction: {
      type: Function,
      required: true,
    },
    title: String,
  },
  computed: {
    isShared(): boolean {
      return this.$store.state.customer?.sharedDashboard;
    },
    inEditMode(): boolean {
      return this.$store.state.layoutEditor.editMode;
    },
    canViewExportButton(): boolean {
      if (this.isShared) {
        // Temporary
        return true;
      }
      return utils.hasRight(this.$store.state.customer.user, ['SHOW_NEW_EXPORT_BUTTON']);
    },
  },
  methods: {
    async handleExport(type: string): Promise<void> {
      this.$store.dispatch('showError', {
        message: 'export started, please wait for download to start...',
        errorType: 'success',
      });
      if (this.isExporting || !this.downloadFunction) return;

      this.isExporting = true;
      try {
        const response = await this.downloadFunction(type);
        this.downloadFile(response, type);
      } catch (error) {
        console.error(error);
        this.$store.dispatch('showError', {
          message: 'Export failed. Please try again later.',
          errorType: 'error',
        });
      } finally {
        this.isExporting = false;
      }
    },
    downloadFile(response, type): void {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const now = new Date().getTime();
      const advertiser = this.$store?.state?.advertiser?.advertiserInfo?.data?.name || 'file';
      link.download = `${advertiser}-${this.title}-${now}.${type}`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    },
  },
});
