
import Vue from 'vue';
import gradstop from 'gradstop';
import utils from '../../../../util';
import MapComponent from '@point/map-component';
import ERRORS from '../../../../errors';
import debounce from 'lodash.debounce';
import { getTooltipContent, normalizePolygonData } from './orderMapUtils';
import { OrderService } from '@/services/order-summary';
import { DataTableHeader } from 'vuetify';
import { getTopTactics, topTacticGroupings } from '../../../components/summary/utils';

const orderService = new OrderService();

let delayingMap = null;

export default Vue.extend({
  inheritAttrs: false,
  props: [
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'sectionConfig',
    'componentConfig',
    'config',
    'hasRequiredData',
    'hasOverlay',
    'hasMapData',
  ],
  data: (): {
    mapReady: boolean;
    fullyRendered: number;
    hoveredGeo: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    dmaMarketsHash: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    campaignDetails: object | null;
    renderDots: boolean;
    tooltipTitle: string | null;
    tooltipContent: string | null;
    exportBackgroundImage: string | null;
    mapId: string | null;
    orderSummaryData: Array<object>;
    summaryHeaders: DataTableHeader[];
    polygonData: object;
    loading: boolean;
    hideMap: boolean;
  } => ({
    mapReady: false,
    campaignDetails: null,
    fullyRendered: 0,
    renderDots: true,
    tooltipTitle: null,
    tooltipContent: null,
    exportBackgroundImage: null,
    mapId: null,
    hoveredGeo: null,
    dmaMarketsHash: {},
    orderSummaryData: [],
    summaryHeaders: [
      { text: 'Product', value: 'Product' },
      { text: 'Imps', value: 'Impressions' },
      { text: 'VCR', value: 'VCR' },
      { text: 'CVR', value: 'CVR' },
      { text: 'Clicks', value: 'Clicks' },
    ],
    polygonData: {},
    loading: true,
    hideMap: false,
  }),
  components: { MapComponent },
  mounted() {
    setTimeout(this.start, 1000);
  },
  methods: {
    debouncedFetchMapData: debounce(function (): void {
      this.fetchGeoData();
      this.fetchOrderSummary();
    }, 1000),
    async fetchGeoData(): Promise<void> {
      this.polygonData = {};
      // clear the map
      this.renderPolygons({ clear: true });
      const apiGeoData = await this.getOrderImpressionsByGeoList();
      this.loading = false;

      // Process data and fill polygonData with the correct structure
      apiGeoData.forEach((geo: any) => {
        const key = `${geo.GeoParamKey}-${geo.GeoParamVal}`;

        if (!this.polygonData[key]) {
          this.polygonData[key] = {
            geos: [],
            id: geo.GeoParamVal,
            key: geo.GeoParamKey,
            lat: geo.Lat ? parseFloat(geo.Lat) : null,
            lon: geo.Long ? parseFloat(geo.Long) : null,
            legacy: [],
            name: geo.City || geo.DMAName || geo.name,
            outerMarket: null,
            score: 0,
            table: {
              headers: [],
              data: [],
            },
            teams: [],
            uid: key,
          };
        }

        // Add the current geo to the geos array
        this.polygonData[key].geos.push({
          ...geo,
          name: geo.City || geo.DMAName || geo.name,
          tactic: geo.AnalyticsType,
        });
      });

      const mergedGeos = Object.values(this.polygonData);

      if (!mergedGeos || apiGeoData.length === 0 || mergedGeos.length === 0) {
        console.log('no summary polygons, fallback for summary map removed');
        this.hideMap = true;
        return;
      }

      const geoKeys: any = {};
      let hasLatLongs = false;

      mergedGeos.forEach((geo: any) => {
        if (!geoKeys[geo.key]) {
          geoKeys[geo.key] = [];
        }
        if (geoKeys[geo.key].length < 500) {
          if (!geo.id) {
            return;
          }
          if (typeof geo.id === 'string' && geo.id.length > 0) {
            if (geo.id === 'null') {
              return;
            }
          }
          geoKeys[geo.key].push(geo.id);
          if (!hasLatLongs && typeof geo.lat === 'number') {
            hasLatLongs = true;
          }
          if (geo.outerMarket) {
            if (!geoKeys[geo.outerMarket.GeoParamKey]) {
              geoKeys[geo.outerMarket.GeoParamKey] = [];
            }
            geoKeys[geo.outerMarket.GeoParamKey].push(geo.outerMarket.GeoParamVal);
          }
        }
      });

      if (Object.keys(geoKeys).length === 0) {
        console.log('no geo data', geoKeys, this.dataSource);
        return;
      }

      const hasDMAs = !!geoKeys['dmacodes'];

      if (this.isExporting && !hasDMAs && hasLatLongs) {
        this.renderDots = true;
        this.renderPolygons({ clear: true, doneRendering: true, beforePolygons: true });
        return;
      }

      if (!this.isExporting && hasLatLongs) {
        this.renderDots = true;
        this.renderPolygons({ clear: true, doneRendering: false, beforePolygons: true });
      }

      // DASH-5587 avoid getPolygons error on 'counties'
      if (geoKeys['counties']) delete geoKeys['counties'];
      if (Object.keys(geoKeys).length === 0) return;

      this.$store
        .dispatch('getPolygons', geoKeys)
        .then((data: any) => {
          if (!data) {
            console.log('getPolygons', 'no polygon response');
          }

          const map = this.$refs[this.mapCacheKey]?.Get();

          if (map) {
            const { hasPolygons, polygonData: normalizedPolygonData } = normalizePolygonData(
              data,
              this.polygonData,
              this.isExporting,
            );

            if (!hasPolygons) {
              console.log('getPolygons', ERRORS.NO_POLYGONS);
              return;
            }

            this.polygonData = normalizedPolygonData;
            this.renderPolygons({ clear: true, doneRendering: true });
          }
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          console.error(error);
        });
    },
    async fetchOrderSummary(): Promise<void> {
      this.orderSummaryData = [];
      const data = await orderService.getOrderSummary(this.orderSummaryPayload);
      const summary = data?.OrderList?.[0]?.Summary;
      if (!summary) return;
      this.orderSummaryData = summary;
    },
    async getOrderImpressionsByGeoList(): Promise<void> {
      const data = await orderService.getOrderImpressionsByGeoList(this.orderSummaryPayload);
      return data.map(geo => ({ ...geo, tactic: geo.AnalyticsType }));
    },
    start(): void {
      this.debouncedFetchMapData();
      setTimeout(() => {
        this.campaignDetails = utils.adDataForKey(this, this.componentConfig?.detailsSource);
      }, 10);
      setTimeout(() => {
        if (!this.hasRequiredData) {
          this.$emit('rendered', { empty: true });
        }
      }, 10);
    },
    debounceMapMoved(): void {
      clearTimeout(this.onMapMovedTimer);
      this.onMapMovedTimer = setTimeout(() => {
        const map = this.$refs[this.mapCacheKey]?.Get();
        if (!map || !map.host || !map.leaflet) {
          return;
        }
        const zoomLevel = map.GetZoom();
        const newVal = !(zoomLevel && zoomLevel > 9);
        if (newVal !== this.renderDots) {
          this.renderDots = newVal;
          this.renderPolygons({ clear: true });
        }
      }, 500);
    },
    renderPolygons(
      { clear, redrawing, doneRendering, beforePolygons } = {
        clear: false,
        redrawing: false,
        doneRendering: false,
        beforePolygons: false,
      },
    ): void {
      // console.log('render polygons', this.fullyRendered, { clear, redrawing, doneRendering, beforePolygons });

      const dmaGradient = this.Theme?.polygons?.light?.dmaGradient;
      if (!this.mapReady || !dmaGradient) {
        if (delayingMap) {
          clearTimeout(delayingMap);
        }
        delayingMap = setTimeout(() => {
          delayingMap = null;
          this.renderPolygons({ clear, redrawing, doneRendering, beforePolygons });
        }, 250);
        return;
      }

      if (delayingMap) {
        return;
      }

      if (doneRendering && this.fullyRendered === 0) {
        this.fullyRendered = 1;
      }

      const gradient = gradstop({
        stops: 101,
        inputFormat: 'hex',
        colorArray: dmaGradient || ['#B1C5CE', '#03A9F4', '#01579B'],
      });
      const map = this.$refs[this.mapCacheKey]?.Get();

      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      // console.log('render zip polygons', clear, redrawing);
      if (clear) {
        map.ClearMap();
      }

      let hasData = false;
      let hideZeros = !!this.componentConfig?.hideZeros;
      let maxPolygonLimit = 100;
      if (this.componentConfig?.layerLimit && this.componentConfig.layerLimit > 0) {
        maxPolygonLimit = this.componentConfig.layerLimit;
      }

      let useDots = this.renderDots;
      const zoomLevel = map.GetZoom();
      if (zoomLevel && zoomLevel > 9) {
        useDots = false;
        maxPolygonLimit = 200;
        hideZeros = false;
      }

      if (!useDots) {
        hideZeros = false;
      } else if (maxPolygonLimit < 500) {
        // we can show more dots than polygons
        maxPolygonLimit = 500;
      }

      maxPolygonLimit = this.isExporting ? 100 : maxPolygonLimit;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let polygons: any[] = Object.values(this.polygonData);
      polygons.sort((a, b) => (a.score > b.score ? 1 : b.score > a.score ? -1 : 0));
      if (hideZeros) {
        polygons = polygons.filter(a => a.score);
      }

      if (polygons.length > maxPolygonLimit) {
        polygons = polygons.slice(-1 * maxPolygonLimit);
        if (this.componentConfig?.legend) {
          this.legend = this.componentConfig.legend + ` (top ${maxPolygonLimit})`;
        }
      } else if (this.componentConfig?.legend) {
        this.legend = this.componentConfig.legend;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let topScoringGeos: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const topScoringLayers: any[] = [];
      if (
        this.componentConfig?.zoomToTopNPercents &&
        this.componentConfig?.zoomToTopNPercents > 0 &&
        this.componentConfig?.zoomToTopNPercents < 100
      ) {
        const add = (acc, x) => acc + x.score;
        const total = polygons.reduce(add, 0);
        const threshold = (total * this.componentConfig.zoomToTopNPercents) / 100.0;
        let i = 0;
        let cutScoreAt = 0;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        polygons.forEach((k: any) => {
          if (topScoringGeos.length > 2 && i > threshold && cutScoreAt && k.score < cutScoreAt) {
            return;
          }
          i += k.score;
          cutScoreAt = k.score;
          topScoringGeos.push({ s: k.score, k: k.uid });
        });
        // to be fair, remove all zips that have the cutting score
        const fair = topScoringGeos.filter(x => x.s > cutScoreAt);
        if (fair.length > 2) {
          topScoringGeos = fair;
        }
        topScoringGeos = topScoringGeos.map(x => x.k);
        // console.log('topN', polygons.length, topScoringZips.length, cutScoreAt);
      }

      let maxScore = 1;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygons.forEach((p: any) => {
        if (p.score > maxScore) {
          maxScore = p.score;
        }
      });

      // sort polygons by their zIndex, to render them in the right order
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let sorted: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygons.forEach((p: any) => {
        // try to never show dots for states and DMAs
        // order layers: high score polygons *below* low score polygons *below* low score dots *below* high score dots
        let score = Math.floor((1000.0 * p.score) / maxScore);
        p.score1000 = score;
        p.score100 = Math.floor(score / 10.0);
        p.useDot = false;
        switch (p.key) {
          case 'states':
            score = 2000 - score;
            break;
          case 'dmacodes':
            score = 3000 - score;
            break;
          case 'cities':
            if (useDots) {
              p.useDot = true;
              score = 4000 + score;
            } else {
              score = 4000 - score;
            }
            break;
          case 'zips':
            // cities and zip share the same score offset, as they can be smaller/bigger than other cities/zips
            if (useDots) {
              p.useDot = true;
              score = 4000 + score;
            } else {
              score = 4000 - score;
            }
            break;
          default:
            score = score + 5000;
            break;
        }
        sorted.push({ s: score, p });
      });
      sorted = sorted.sort((a, b) => (a.s > b.s ? 1 : b.s > a.s ? -1 : 0));
      polygons = sorted.map(kv => kv.p);

      const outerMarketsLayer = map.CreateLayer('outerMarket');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygons.forEach((p: any) => {
        if (p.outerMarket?.poly?.WKT_P100) {
          let hovered, layerPopup;
          const om = p.outerMarket;
          const layer = map.AddToLayer(outerMarketsLayer);
          // console.log('rendering polygon', key);
          map.SetWKT(layer, p.outerMarket.poly.WKT_P100, true);
          layer.setStyle(this.Theme?.polygons?.light?.outerMarket);
          this.dmaMarketsHash[om.GeoKey] = layer;

          // set mouse events for polygon to display tooltip on hover
          layer
            .on('mouseover', () => {
              this.hoveredGeo = om;
              if (!layer) {
                return;
              }
              hovered = true;
              layer.setStyle(this.Theme?.polygons?.light?.outerMarketHover);
              const lat = parseFloat(p.lat);
              const lon = parseFloat(p.lon);
              let latlon;
              if (!isNaN(lat) && !isNaN(lon)) {
                latlon = [lat, lon];
              }
              if (!latlon) {
                return;
              }
              this.tooltipTitle = `Outer Market: ${om.name} ${om.League} ${om.Team}`;
              this.tooltipContent = `
                <div class="tooltipContent">
                  <div class="tooltipContentLine">
                    <span class="grey--text font-weight-medium">Impressions:
                      <span class="tooltip-text font-weight-bold ma-0">
                      ${utils.formatNumberWithCommas(om.Impressions)}
                      </span>
                    </span>
                  </div>
                </div>`;
              p.tooltipTimeout = setTimeout(() => {
                if (!hovered) {
                  return;
                }
                const content = this.$refs.mapToolTip;
                if (!content) {
                  layerPopup = null;
                  return;
                }
                layerPopup = map.CreatePopup(content, latlon, {
                  closeButton: false,
                  offset: [2, 4],
                });
              }, 10);
            })
            .on('mouseout', () => {
              if (!layer) {
                return;
              }
              hovered = false;
              setTimeout(() => {
                if (hovered) {
                  return;
                }
                clearTimeout(p.tooltipTimeout);
                layer.setStyle(this.Theme?.polygons?.light?.outerMarket);
                map.ClosePopup(layerPopup);
                layerPopup = null;
              }, 50);
            });
        }
      });

      const geosLayer = map.CreateLayer('geos');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      polygons.forEach((p: any) => {
        if (!p.geos || !Array.isArray(p.geos) || p.geos.length === 0) {
          // eslint-disable-next-line no-console
          console.log('zipPoly no data', p);
          return;
        }
        let layer, style, hoverStyle, color, persistentTooltip;
        if (p.useDot || !p.poly?.WKT_P100) {
          if (isNaN(p.lat) || isNaN(p.lon) || p.lat === 0 || p.lon === 0) {
            // console.log('geoData no lat/lon', p);
            return;
          }
          layer = map.AddDivToLayer({ className: 'fenceIcon', zIndexOffset: p.score }, p.lat, p.lon, geosLayer);
          color = gradient[p.score100];
          if (layer._icon) {
            layer._icon.style.background = color;
          } else {
            setTimeout(() => {
              if (layer._icon) {
                layer._icon.style.background = color;
              }
            }, 200);
          }
          hasData = true;
        } else {
          layer = map.AddToLayer(geosLayer);
          map.SetWKT(layer, p.poly.WKT_P100, true);

          color = gradient[p.score100];
          const color1 = gradient[1];
          const color50 = gradient[50];
          const color99 = gradient[99];

          style = {
            ...this.Theme?.polygons?.light?.dma,
            color: color50,
            fillColor: color,
            weight: 1,
            fillOpacity: 0.3,
          };

          hoverStyle = {
            ...this.Theme?.polygons?.light?.dmaHover,
            color: color1,
            fillColor: color99,
            weight: 5,
            fillOpacity: 0.7,
          };
          layer.setStyle(style);
        }

        if (!layer) {
          // eslint-disable-next-line no-console
          console.error('failed to create map layer');
          return;
        }
        if (topScoringGeos.includes(p.uid)) {
          topScoringLayers.push(layer);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let layerPopup: any = null;
        let hovered = false;
        layer
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mouseover', (e: any) => {
            this.hoveredGeo = p;
            if (!layer) {
              return;
            }
            if (layer._icon) {
              // layer._icon.style.opacity = 1;
            } else if (layer.setStyle) {
              layer.setStyle(hoverStyle);
            }

            let latlon = e.latlng;
            if (p.useDot) {
              // use the dot's center
              if (!isNaN(p.lat) && !isNaN(p.lon)) {
                latlon = [p.lat, p.lon];
              }
            }
            hovered = true;
            this.tooltipTitle = null;
            this.tooltipContent = null;
            if (layer.setStyle) {
              layer.setStyle(hoverStyle);
            }
            if (Array.isArray(p.teams) && p.teams.length > 0) {
              persistentTooltip = true;
              if (!isNaN(p.lat) && !isNaN(p.lon)) {
                latlon = [p.lat, p.lon];
              }
              p.table.headers = [
                { text: 'Team', value: 'Team' },
                { text: 'League', value: 'League' },
                { text: 'Imps', value: 'Impressions' },
              ];
              p.table.data = p.teams.map(x => ({
                key: x.GeoKey,
                Team: x.Team,
                League: x.League,
                Impressions: utils.formatNumberWithCommas(x.Impressions),
              }));
              if (Array.isArray(p.legacy) && p.legacy.length > 0) {
                const impressions = p.legacy.reduce((sum, geo) => sum + geo.Impressions, 0);
                p.table.data.push({
                  key: 'legacy',
                  Team: 'n/a',
                  League: 'n/a',
                  Impressions: utils.formatNumberWithCommas(impressions),
                });
              }
            } else {
              const first = p.geos[0];
              switch (p.key) {
                case 'zips':
                  this.tooltipTitle = `Zip Code: ${p.id}`;
                  break;
                case 'dmacodes':
                  this.tooltipTitle = `DMA: ${first.name}`;
                  break;
                case 'states':
                  this.tooltipTitle = `${first.name}`;
                  break;
                case 'cities':
                  this.tooltipTitle = `${first.name}`;
                  break;
                default:
                  this.tooltipTitle = `${p.id}`;
                  // eslint-disable-next-line no-console
                  console.error('unhandled polygon type', p);
                  break;
              }
              this.tooltipContent = `<div class="tooltipContent">`;

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              p.geos.forEach((x: any) => {
                const { key, val } = getTooltipContent(x, p);

                this.tooltipContent += `<div class="tooltipContentLine"><span class="grey--text font-weight-medium">${key}: <span class="tooltip-text ont-weight-bold ma-0">${val}</span></span></div>`;
              });
              this.tooltipContent += '</div>';
            }
            p.tooltipTimeout = setTimeout(() => {
              if (!hovered) {
                return;
              }
              const content = this.$refs.rsnToolTip || this.$refs.mapToolTip;
              if (!content) {
                layerPopup = null;
                return;
              }
              layerPopup = map.CreatePopup(content, latlon, {
                closeButton: false,
                offset: [2, 4],
              });
              const popupEl = layerPopup._wrapper;
              popupEl.style.pointerEvents = 'none';
              if (persistentTooltip) {
                popupEl.style.pointerEvents = 'all';
                // console.log({ popupEl, layerPopup });
                popupEl.addEventListener(
                  'mousemove',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = true;
                  },
                  true,
                );
                popupEl.addEventListener(
                  'mouseover',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = true;
                  },
                  true,
                );
                popupEl.addEventListener(
                  'mouseout',
                  e => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    hovered = false;
                    setTimeout(() => {
                      if (!hovered) {
                        map.ClosePopup(layerPopup);
                        layerPopup = null;
                      }
                    }, 250);
                  },
                  true,
                );
              }
            }, 20);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .on('mousemove', (e: any) => {
            if (!persistentTooltip && !p.useDot && layerPopup) {
              layerPopup.setLatLng(e.latlng);
            }
          })
          .on('click', () => {
            // // eslint-disable-next-line no-console
            // console.log('polygon', p);
          })
          .on('mouseout', () => {
            if (!layer) {
              return;
            }
            hovered = false;
            setTimeout(() => {
              if (hovered) {
                return;
              }
              clearTimeout(p.tooltipTimeout);
              if (layer.setStyle) {
                layer.setStyle(style);
              }
              this.legend = null;
              if (!persistentTooltip && layerPopup) {
                map.ClosePopup(layerPopup);
                layerPopup = null;
              }
            }, 50);
          });
        hasData = true;
      });

      map.SetRenderCacheKey(this.mapRenderCacheKey);

      if (this.hasOrderMapData) {
        map.FitAllLayers({ force: true, animate: true, paddingTopLeft: [20, 20], paddingBottomRight: [20, 20] });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emitRendered(beforePolygons: boolean, map: any, retries = 5) {
      if (this.fullyRendered === 1) {
        if (this.isExporting) {
          setTimeout(
            () => {
              const mapEl = this.$refs[this.mapCacheKey]?.$el;
              if (!mapEl) {
                // eslint-disable-next-line no-console
                console.error('can not export, no map element');
                if (retries > 0) {
                  setTimeout(() => {
                    this.emitRendered(beforePolygons, map, retries - 1);
                  }, 500);
                } else {
                  setTimeout(() => {
                    this.$emit('rendered', { empty: true });
                  }, 10);
                }
                return;
              }
              const tileBackgroundList = mapEl.getElementsByClassName('mapboxgl-map');
              if (!tileBackgroundList || tileBackgroundList.length === 0) {
                // eslint-disable-next-line no-console
                console.error('orderMap, can not export, no map background');
                if (retries > 0) {
                  setTimeout(() => {
                    this.emitRendered(beforePolygons, map, retries - 1);
                  }, 500);
                } else {
                  setTimeout(() => {
                    this.$emit('rendered', { empty: true });
                  }, 10);
                }
                return;
              }
              const tileBackground = tileBackgroundList[0] as HTMLElement;

              const zoom = map.GetZoom() - 1; // why do we need the -1 ???
              const center = map.GetCenter();
              const staticMapByCenter = `https://api.maptiler.com/maps/${this.mapTilerMapId}/static/${center.lng},${center.lat},${zoom}/${mapEl.offsetWidth}x${mapEl.offsetHeight}@1x.png?key=CCrAlH25DTP89c6iJsO3`;
              var img = new Image();
              img.addEventListener(
                'load',
                () => {
                  this.exportBackgroundImage = staticMapByCenter;
                  tileBackground.style.display = 'none';
                  setTimeout(() => {
                    this.$emit('rendered', { empty: false });
                  }, 1000);
                },
                false,
              );
              img.src = staticMapByCenter;
              this.fullyRendered = 2;
            },
            beforePolygons ? 1000 : 1000,
          );
        } else {
          setTimeout(() => {
            this.$emit('rendered', { empty: false });
          }, 1000);
        }
      }
    },
    onMapReady(evt): void {
      const { id, redrawing } = evt;
      const map = this.$refs[id]?.Get();
      this.mapId = id;
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }
      if (!redrawing) {
        map.ZoomControlsOnRight();
      }

      this.mapReady = true;
      //   this.$store.dispatch('summary/setIsMapReady', true);

      if (this.mapRenderCacheKey !== map.renderCacheKey) {
        setTimeout(() => {
          map.Redraw();
        }, 20);
        setTimeout(() => {
          this.renderPolygons({ clear: true });
        }, 500);
      }
      const mapDiv = document.getElementById('orderMap');
      const resizeObserver = new ResizeObserver(() => {
        map.Resize();
      });

      resizeObserver.observe(mapDiv);
    },
    getImageLink(name: string): string {
      const tacticName = this.tacticTitleMap(name).toLowerCase();
      const imageName = utils.parsedTacticNames.includes(tacticName) ? tacticName : 'placeholder';
      return require(`@/assets/summaryIcons/${imageName}.svg`);
    },
    getTacticClass(name: string): string | null {
      if (!name) return null;
      return utils.removeWhiteSpaceLowerCase(name);
    },
    tacticTitleMap(name: string): string {
      if (!name) return '';
      return utils.tacticTitleMap(name);
    },
  },
  computed: {
    maxColumnCount(): number {
      return Math.max(...this.formattedSummaryData?.map(table => table.headers?.length));
    },
    hasOrderMapData(): boolean {
      return this.formattedSummaryData?.length > 0 && Object.keys(this.polygonData)?.length > 0;
    },
    formattedSummaryData() {
      let tactics = getTopTactics();

      // DASH-5519: filter out conversions for map sum on Order Details since it uses same mapping as Product Summary
      tactics = tactics.map(tactic => {
        tactic.metrics = tactic.metrics.filter(metric => metric.name !== 'Convs All Time');
        return tactic;
      });
      const summaryTables = [];

      topTacticGroupings.forEach((group, index) => {
        // Filter tactics that belong to this group and have data
        const groupTactics = tactics.filter(
          tactic => group.tactics.includes(tactic.id) && this.orderSummaryData[tactic.id],
        );

        if (groupTactics.length > 0) {
          // Use first tactic's metrics for headers (they share the same metrics)
          const headers = groupTactics[0].metrics.map(metric => ({
            text: metric.name,
            value: metric.value,
          }));

          // Create items array from all tactics in group
          const items = groupTactics.map(tactic => {
            const productData = this.orderSummaryData[tactic.id];
            return tactic.metrics.reduce(
              (acc, metric) => ({
                ...acc,
                [metric.value]: productData[metric.value],
                TagName: utils.tacticTitleMap(productData.TagName || tactic.id),
              }),
              {},
            );
          });

          // Filter out rows with all undefined values, ignoring TagName
          const filteredItems = items.filter(item =>
            Object.entries(item)
              .filter(([key]) => key !== 'TagName')
              .some(([_, val]) => val !== undefined),
          );

          if (filteredItems.length > 0) {
            const tableData = {
              title: `Group ${index + 1}`,
              headers,
              items: filteredItems,
            };

            summaryTables.push(tableData);
          }
        }
      });

      return summaryTables;
    },
    orderSummaryPayload(): object {
      return {
        advertiserId: this.$route.query.id || this.$store.state.advertiser?.advertiserInfo?.data?.id,
        orderId: this.$route.query.orderId,
        daterange: this.$store.state.order?.singleOrderParams?.daterange || 'thismonth',
      };
    },
    mapHeight(): number {
      if (this.isMobile) return 200;
      return this.$store.state?.summary?.dimensions?.mapHeight || 0;
    },
    mapRenderCacheKey(): string {
      return `_orderMap_${this.config.dataCacheKey}_light`;
    },
    mapCacheKey(): string {
      return `_orderMap_${this.config.dataCacheKey}`;
    },
    mapTilerMapId(): string {
      if (Array.isArray(this.componentConfig?.themes) && this.componentConfig.themes.length) {
        // if only one theme exist
        return utils.getMapId(this.componentConfig.themes[0]);
      }
      return 'hybrid';
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    orderSummaryPayload: {
      handler(): void {
        this.debouncedFetchMapData();
      },
      deep: true,
    },
  },
});
