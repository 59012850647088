
import Vue from 'vue';
import { C360Icon } from '@c360/ui';
import { format, parse } from 'date-fns';

export default Vue.extend({
  name: 'CampaignNarrativeSummaryModal',
  components: { C360Icon },
  props: ['showModal'],
  data: () => ({
    isLoading: true,
    isError: false,
    campaignSummaryContent: '',
    currentCampaignID: null,
    campaignName: '',
  }),
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
    formatDate(date: string): string {
      if (!date || date === '' || date === 'undefined') {
        return '';
      }
      try {
        const parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date()); // parse custom date format
        return format(parsedDate, 'MMM dd, yyyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err);
      }
      return '';
    },
    getCampaignSummaryNarrative(): void {
      this.isLoading = true;
      this.currentCampaignID = this.$route.query?.viewCampaigns;
      this.campaignName = this.selectedCampaign?.FriendlyName || this.selectedCampaign?.name;
      const type = this.$route.query?.tab?.toUpperCase() || this.$store.state.customer?.selection?.tab?.toUpperCase();
      this.fetchCampaignSummaryNarrative({
        id: this.currentCampaignID,
        type: type,
      }).then(data => {
        if (data.error) {
          this.isError = true;
          this.campaignSummaryContent = `
            There is not sufficient information to generate an AI Summary Narrative at this time.
          `;
        } else {
          this.campaignSummaryContent = data;
        }
        this.isLoading = false;
      });
    },
    async fetchCampaignSummaryNarrative(payload): Promise<void> {
      try {
        const response = await this.$store.dispatch('getCampaignNarrativeSummary', payload);
        return response;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    },
  },
  computed: {
    campaignRemainingDaysTxt() {
      const campaignRemainingDaysAbsolete = Math.abs(this.campaignRemainingDays) || 0;
      return `${campaignRemainingDaysAbsolete} day${
        campaignRemainingDaysAbsolete > 1 || campaignRemainingDaysAbsolete === 0 ? 's' : ''
      } left`;
    },
    campaignRemainingDays() {
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const currentTime = new Date().getTime();
      const endTime = this.selectedCampaign?.End_date ? new Date(this.selectedCampaign.End_date).getTime() : 0;
      const remainingDays = Math.floor((endTime - currentTime) / millisecondsPerDay);
      return remainingDays;
    },
    campaignStartDateFormatted(): string {
      return this.formatDate(this.selectedCampaign?.Start_date) || 'TBA';
    },
    campaignEndDateFormatted(): string {
      return this.formatDate(this.selectedCampaign?.End_date) || 'TBA';
    },
    campaignFlightDates() {
      return `${this.campaignStartDateFormatted} — ${this.campaignEndDateFormatted}`;
    },
    isActive() {
      return this.campaignRemainingDays >= 0;
    },
    selectedCampaign() {
      return this.$store.state.filters?.selectedCampaigns[0];
    },
  },
  watch: {
    '$route.params.viewCampaigns': {
      handler: function () {
        //Your code here
        if (this.$route.query?.viewCampaigns !== this.currentCampaignID) {
          this.getCampaignSummaryNarrative();
        }
      },
      deep: true,
      immediate: true,
    },
  },
});
