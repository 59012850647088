
import Vue from 'vue';
import { Advertiser, Product } from '../../../types/advertisers';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'AdvertiserByProductList',
  props: [
    'advertiser',
    'advertiserName',
    'advertiserData',
    'agencyList',
    'currentSection',
    'campaignData',
    'isShowAdvertiserList',
    'tacticData',
  ],
  components: {
    SearchBar: () => import('./searchBar.vue'),
    ExportMenu: () => import('../../components/toolbar/menus/exportMenu.vue'),
    C360Icon,
  },
  data: (): {
    selectedItem: Number;
    selectedPartners: object | null;
    selectedProduct: string;
  } => ({
    selectedItem: -1,
    selectedPartners: null,
    selectedProduct: '',
  }),
  computed: {
    loading(): boolean {
      return this.$store.state?.advertiserView?.loadingAdvertisers;
    },
    advertiserTotal(): string {
      return this.advertiserData?.Total ? `(${this.advertiserData.Total})` : null;
    },
    advertiserPageCount(): string {
      return this.advertiserData?.Total > 100
        ? 'Showing the first 100 advertisers. Please search to narrow your results.'
        : null;
    },
    hasCampaignData(): boolean {
      return this.campaignData && this.campaignData.length;
    },
    formatedExportData() {
      if (!this.hasCampaignData) return null;
      return {
        advertiserId: this.advertiser,
        advertiserName: this.advertiserName,
        campaigns: '',
        daterange: 'alltime',
        tab: 'summary',
      };
    },
    hasData(): boolean {
      return this.advertiserData && this.advertiserData?.length > 0;
    },
  },
  methods: {
    getAdvertiserTacticData() {
      const advertiserTactic = this.selectedProduct ? this.selectedProduct.toLowerCase() : 'summary';
      const filteredLayout = this.tacticData?.filter(section => section.id === advertiserTactic);
      return filteredLayout?.length > 0 ? filteredLayout[0] : null;
    },
    isSelected(id: string): boolean {
      return this.advertiser === id;
    },
    advertiserSelect(advertiser: Advertiser): void {
      this.$emit('advertiserSelect', advertiser);
    },
    updateSearch(params: { [key: string]: string }): void {
      this.$emit('updateSearch', params);
    },
    changeTactic(params: { [key: string]: string }): void {
      params = params === 'emailsi' ? 'siteimpact' : params;
      this.$emit('changeTactic', params);
    },
    changePartner(params: { [key: string]: string }): void {
      this.$emit('changePartner', params?.value || '');
    },
    openAdvertiser(advertiser: Advertiser): void {
      this.$emit('openAdvertiser', advertiser);
    },
    sortFilterTactics(data): Product[] {
      const removedTactics = [
        'Facebook Organic',
        'Google Search Console',
        'SEO',
        'Summary',
        'Order List',
        'Order Summary',
        'home new',
      ];
      if (!data) return [];
      const allTactics = data
        .map(t => {
          return {
            name: t.title,
            value: t.id,
          };
        })
        // DASH-4099 - remove "Advertiser View" - Remove "Google Search Console", "SEO" and "Facebook Organic"
        .filter(t => !removedTactics.includes(t.name));
      const sortTactics = allTactics.sort((a, b) => a.name.localeCompare(b.name));
      sortTactics.unshift({ name: 'All', value: '' });
      return sortTactics;
    },
    formatElement(data): Array<object> {
      if (!data) return [];
      const items = Array.isArray(data) ? data : [data];
      return items.map(d => {
        return {
          value: d.originId || d.PropertyId,
          text: d.name,
        };
      });
    },
  },
  watch: {
    advertiserData: {
      handler(): void {
        this.selectedItem = -1;
      },
    },
  },
});
