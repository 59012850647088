import { SectionComponent } from '../../types/layout';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sectionAttributes = (theme: any, component: SectionComponent): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const attributes: any = {};
  if (component && component.breakpoints) {
    component.breakpoints.forEach((b: string) => {
      attributes[b] = true;
    });
  }
  return attributes;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const componentClass = (component: SectionComponent, breakpoints: any): string => {
  let classes: string[] = [];
  // set the padding between components
  if (breakpoints.lgAndUp) classes.push('pa-2');
  else if (breakpoints.mdAndUp) classes.push('pa-2');
  else if (breakpoints.smAndUp) classes.push('pa-2');
  if (component.class) {
    classes.push(component.class);
  }
  if (component.classes && Array.isArray(component.classes)) {
    classes = classes.concat(component.classes);
  }
  return classes.join(' ');
};

export default {
  sectionAttributes,
  componentClass,
};
