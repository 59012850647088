import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import agencyAdmin from './modules/agencyadmin/index';
import customer from './modules/customer/index';
import linearAdmin from './modules/linearadmin/index';
import admin from './modules/admin/index';
import filters from './modules/filters/index';
import scheduledReports from './modules/scheduledreports/index';
import callRail from './modules/callRail/index';
import summary from './modules/summary/index';
import order from './modules/order/index';
import status from './modules/status/index';
import layoutEditor from './modules/layoutEditor/index';
import advertiserView from './modules/advertiserView/index';
import primary from './modules/primary/index';
import googleAds from './modules/googleAds/index';
import advertiser from './modules/advertiser';
import performance from './modules/performance';
import exports from './modules/exports';

export const apollo = () => Vue.prototype.$apollo;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logError(err: any, name: string): Error {
  const prefix = name || 'error';
  if (!err) {
    // eslint-disable-next-line no-console
    console.error(prefix, 'logError without error');
    return new Error(`${prefix}: logError without error`);
  }
  if (typeof err === 'string') {
    // eslint-disable-next-line no-console
    console.error(prefix, err);
    return new Error(err);
  }
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.message) {
        // eslint-disable-next-line no-console
        console.error(prefix, err.response.data.message, err.response);
        return new Error(err.response.data.message);
      }
      // eslint-disable-next-line no-console
      console.error(prefix, err.response.data, err.response);
      return new Error(err.response.data);
    }
    // eslint-disable-next-line no-console
    console.error(prefix, err.response);
    return new Error(`${prefix}: api error`);
  }
  if (err && err.message) {
    // eslint-disable-next-line no-console
    console.error(prefix, err.message);
    return new Error(err.message);
  }
  return new Error(`${prefix}: unhandled logError`);
}

axios.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  },
);
axios.interceptors.request.use(
  response => response,
  error => {
    return Promise.reject(error);
  },
);

Vue.use(Vuex);

interface Store {
  modules: {
    admin: object;
    advertiserView: object;
    agencyAdmin: object;
    customer: object;
    linearAdmin: object;
    filters: object;
    scheduledReports: object;
    callRail: object;
    summary: object;
    order: object;
    status: object;
    layoutEditor: object;
    primary: object;
    googleAds: object;
    advertiser: object;
    performance: object;
    exports: object;
  };
}
const store: Store = {
  modules: {
    admin,
    advertiserView,
    agencyAdmin,
    customer,
    linearAdmin,
    filters,
    scheduledReports,
    callRail,
    summary,
    order,
    status,
    layoutEditor,
    primary,
    googleAds,
    advertiser,
    performance,
    exports,
  },
};

export default new Vuex.Store(store);
