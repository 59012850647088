
import Vue from 'vue';
import _sortby from 'lodash.sortby';
import { Advertiser, RecentAdvertiser } from '../../../../store/modules/customer/types';
import analytics from '../../../../mixins/analytics';
import utils from '../../../../util';
import { C360AdvertisersSearch, C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'AdvertiserToolbarSearch',
  components: {
    C360AdvertisersSearch,
    C360Icon,
  },
  props: ['selectedAdvertiserName', 'recentAdvertisers'],
  mixins: [analytics],
  data: (): {
    key: number;
    advertiserSearch: string;
    showMenu: boolean;
    searchValue: string | undefined;
    loadingAdvertisers: boolean;
    searchTimer: Function | null;
  } => ({
    key: 0,
    searchValue: '',
    loadingAdvertisers: false,
    searchTimer: null,
    advertiserSearch: '',
    showMenu: false,
  }),
  computed: {
    advertiserText(): string {
      // TODO: use name from state when BE is implemented
      // if (this.isOrderPages) {
      //   return 'Demo Advertiser';
      // }
      return 'Select Advertiser';
    },
    isOrderPages(): boolean {
      return ['orderlist', 'ordersummary'].includes(this.$route?.query?.tab);
    },
    formattedSearchResult(): Partial<Advertiser>[] {
      if (!this.advertiserSearchResults || !this.advertiserSearchResults.length) return [];
      const res = this.advertiserSearchResults.map(obj => ({
        PropertyId: obj.id,
        Name: obj.name,
        Agency: this.isShowAgencyName ? obj.agency_name : null,
        name: obj.name,
        agency: this.isShowAgencyName ? obj.agency_name : null,
      }));
      return res;
    },
    formattedRecent(): Partial<RecentAdvertiser>[] {
      if (!this.sortedRecentAdvertisers || !this.sortedRecentAdvertisers.length) return [];
      const res = this.sortedRecentAdvertisers.map(obj => ({
        PropertyId: obj.PropertyId,
        Name: obj.Name,
        name: obj.Name,
        Agency: this.isShowAgencyName ? obj.Agency : null,
        agency: this.isShowAgencyName ? obj.Agency : null,
      }));
      return res;
    },
    sortedRecentAdvertisers(): Partial<RecentAdvertiser>[] {
      if (!this.recentAdvertisers || !this.recentAdvertisers.length) return [];
      const filtered = this.recentAdvertisers.filter((a: { Name?: string }) => a.Name);
      return _sortby([...filtered], ['Name']);
    },
    advertiserSearchResults(): Array<object> {
      return this.$store.state?.linearAdmin?.linearAdminClients?.advertisers || [];
    },
    user(): object {
      return this.$store.state?.customer?.user;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isShowAgencyName(): boolean {
      return this.user.is_superuser || this.user.permissions.includes('SHOW_AGENCY_NAME');
    },
  },
  methods: {
    onActivated(): void {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.search(this.searchValue);
      }, 500);
    },
    incrKey(): void {
      this.key = this.key + 1;
    },
    handleMenuItemClick($event): void {
      this.incrKey();
      this.$emit('select-advertiser', $event);
      this.analyticTrack(this.trackValue.EVENT_NAV_ADVERTISER, `Advertiser Search - ${$event.name}`);
    },
    search(val: string): void {
      // Lazily load input items
      this.loadingAdvertisers = true;
      this.$store
        .dispatch('linearAdmin/getLinearAdminClientsSearch', {
          name: val,
          partners: this.$route.query.agency ? `${this.$route.query.agency}` : '',
          limit: 100,
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .finally(() => {
          this.loadingAdvertisers = false;
        });
    },
  },
  watch: {
    searchValue() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.search(this.searchValue);
      }, 500);
    },
    advertiserSearch(val: string) {
      if (!val) {
        // wipes out the searchvalue object
        // this prevents a bug where you get 0 results when searching for the same string that's in the selected advertiser name
        this.searchValue = null;
        return;
      }
      if (val.length < 0) {
        return;
      }
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.search(val);
      }, 500);
    },
  },
});
