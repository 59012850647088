
import Vue from 'vue';
import utils from '../../util';
import { AccountLinking } from '../../types/ads';
import { getCssVar } from '@c360/ui';

// const googleSecret = 'DcdOkMZZPWd50J641ul6_MCG';
const debug = false;

export default Vue.extend({
  components: {
    AppGlobals: () => import('../global/globals.vue'),
    MainFooter: () => import('../global/footer.vue'),
    GaAccountsNew: () => import('../linearadmin/components/gaNew/gaAccountsNew.vue'),
    HeaderLogoC360: () => import('../components/toolbar/headerLogoC360.vue'),
  },
  data: () => ({
    authChecked: false,
    autoAskLocation: false,
    complete: false,
    accountLinking: null,
    loggedIn: false,
    saving: false,
    checkStatus: false,
    nextStep: false,
    advertiserId: '',
    gaData: null,
    userId: '',
    googleClientID:
      process.env.VUE_APP_GOOGLE_SIGNIN_CLIENT_ID ||
      '523663729702-vg84m845lg4603v3cbnhjnru96ishkr6.apps.googleusercontent.com', // todo: make this and public/index.html use the value from .env file(s)
    error: '',
    value: 0,
    showIntro: true,
    isDataApiTemplate: true,
    gaConnectors: null,
    selectedGA: null,
    gaAccounts: null,
    isAccountLoading: false,
  }),
  // async created(): Promise<void> {
  //   // // Hack to show new functionality endpoint=ganew
  //   if (this.$route.query.endpoint === 'ganew') {
  //     this.isDataApiTemplate = true;
  //   }
  //   if (!this.isDataApiTemplate) {
  //     this.loadAccountLinking();
  //   }
  // },
  mounted(): void {
    if (!this.$route.query.aid) {
      setTimeout(() => {
        this.authChecked = true;
        this.$store.dispatch('showError', 'not a valid landing page');
      }, 1000);
      return;
    }
    this.advertiserId = this.$route.query.aid;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).GoogleApiLoaded = this.googleApiLoaded;
    this.LoadGoogleApi();
  },
  computed: {
    buildInfo(): string {
      if (process && process.env && process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION.length > 8) {
        return `v.1.${process.env.VUE_APP_BUILD_VERSION.substr(3, 6)}`;
      }
      return 'n/a';
    },
    isSaving(): boolean {
      return this.saving || this.$store.state.linearAdmin.googleAnalyticsLoading;
    },
    progressText(): string | null {
      if (this.error) return 'Something went wrong';
      if (!this.authChecked) return 'Checking status...';
      if (this.saving) return 'Saving...';
      if (this.checkStatus) return 'Checking Google Connection...';
      if (this.nextStep) return 'Syncing to Google Account...';
      return null;
    },
    themeColor(): string {
      return getCssVar('--c360-color-compulse');
    },
    hasSelectedView(): boolean {
      if (!this.isDataApiTemplate) {
        return !!this.accountLinking?.GoogleAnalytics?.ViewId;
      } else if (this.selectedGA) {
        return true;
      } else return false;
    },
    hasAccounts(): boolean {
      return this.gaData?.Accounts?.length > 0;
    },
    showAccounts(): boolean {
      if (!this.isDataApiTemplate) {
        return this.gaData && !this.isSaving && this.hasAccounts;
      } else if (this.isDataApiTemplate) {
        return !this.isSaving && this.gaAccounts?.length > 0;
      } else return false;
    },
  },
  methods: {
    updateGa(): void {
      if (this.isDataApiTemplate) {
        this.addGaConnector();
      } else {
        this.updateGoogleAnalyticsConfig();
      }
    },
    async loadGA(): Promise<void> {
      this.value = 60;
      this.saving = false;
      this.nextStep = false;
      this.checkStatus = true;
      const resp = await this.$store
        .dispatch('linearAdmin/googleAnalyticsStatus', {
          client: true,
          id: this.$route.query.aid,
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          this.error = error;
          // eslint-disable-next-line no-console
          console.error(error);
        });
      this.value = 80;
      this.checkStatus = false;
      if (resp?.Connected && resp?.Accounts?.length > 0) this.gaData = resp;
      else this.error = 'Sorry, no views found.';
    },
    async loadGAAccounts(): Promise<void> {
      this.value = 60;
      this.saving = false;
      this.nextStep = false;
      this.checkStatus = true;
      this.isAccountLoading = true;
      await this.$store
        .dispatch('linearAdmin/getGaAvailableAccount', {
          id: this.$route.query.aid,
        })
        .then(data => {
          this.gaAccounts = data;
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          // eslint-disable-next-line no-console
          console.error(error);
          this.error = 'Sorry, no views found.';
        })
        .finally(() => {
          this.value = 80;
          this.checkStatus = false;
          this.isAccountLoading = false;
        });
    },
    selectGaConnectors(): void {
      const ga: Record<string, string> = {};
      if (!this.gaConnectors?.additional) return;
      if (this.gaConnectors?.additional?.type === 'ga4' && this.gaConnectors?.additional?.account) {
        ga.id = this.$route.query.ppid;
        ga.accountId = this.gaConnectors?.additional?.account?.match(/\d+/)[0];
        ga.propertyId = this.gaConnectors?.primaryId;
      }
      if (this.gaConnectors?.additional?.type === 'ga4' && this.gaConnectors?.additional?.ga_account_id) {
        ga.id = this.$route.query.ppid;
        ga.accountId = this.gaConnectors?.additional?.ga_account_id;
        ga.propertyId = this.gaConnectors?.primaryId;
      }
      if (this.gaConnectors?.additional?.type === 'ga') {
        ga.id = this.$route.query.ppid;
        ga.accountId = this.gaConnectors?.additional?.accountId;
        ga.propertyId = this.gaConnectors?.additional?.id;
        ga.viewId = this.gaConnectors?.primaryId;
      }
      this.selectedGA = ga;
    },
    async loadConnectors(): Promise<void> {
      await this.$store
        .dispatch('linearAdmin/getGaConnector', {
          id: this.$route.query.aid,
        })
        .then(connectors => {
          this.gaConnectors = connectors.data?.[0];
          this.selectGaConnectors();
        });
    },
    selectGa(accountId: string, propertyId: string, viewId?: string): void {
      this.selectedGA = { id: this.$route.query.aid, accountId, propertyId, viewId };
    },
    async addGaConnector(): Promise<void> {
      this.error = null;
      this.saving = true;
      try {
        if (this.selectedGA) {
          const params = this.selectedGA;
          const resp = await this.$store.dispatch('linearAdmin/addGaConnector', params);

          if (resp.error) {
            this.$store.dispatch('showError', resp.error);
            this.error = resp.error;
          }
          if (resp?.addGaConnector?.created) {
            this.value = 100;
            this.saving = false;
            this.complete = true;
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    async updateGoogleAnalyticsConfig(): Promise<void> {
      try {
        this.error = null;
        this.saving = true;
        const req = await this.$store
          .dispatch(`linearAdmin/updateGoogleAnalyticsConfig`, {
            id: this.$route.query.aid,
            config: this.accountLinking.GoogleAnalytics,
          })
          .catch((error: Error) => {
            this.$store.dispatch('showError', error);
            this.error = error;
            // eslint-disable-next-line no-console
            console.error(error);
          });
        if (req.error) {
          this.error = req.error;
          return;
        } else {
          this.value = 100;
          this.saving = false;
          this.complete = true;
        }
      } catch (exp) {
        this.error = exp.message;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    alreadySignedIn(googleUser: any): void {
      this.authChecked = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const _g = (window as any).gapi;
      const auth2 = _g.auth2.getAuthInstance();

      if (debug) {
        // eslint-disable-next-line no-console
        console.log('alreadySignedIn auth2', auth2, 'googleUser', googleUser);
      }

      if (googleUser) {
        this.loggedIn = true;
        const profile = googleUser.getBasicProfile();
        this.userId = profile.getId();
      }
      if (this.autoAskLocation) {
        if (debug) {
          // eslint-disable-next-line no-console
          console.log('alreadySignedIn', 'auto ask offline');
        }
        this.askOffline();
      }
    },
    googleApiLoaded(): void {
      setTimeout(() => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const _g = (window as any).gapi;
          _g.load('auth2', () => {
            _g.auth2.init({ client_id: this.googleClientID });
            setTimeout(() => {
              // this.initOAuth2(); don't sign into this page, directly ask for offline data access
              this.authChecked = true;
              this.loggedIn = true;
            }, 100);
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('auth2.load', err);
          this.$store.dispatch('showError', err);
        }
      }, 100);
    },
    initOAuth2(): void {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _g = (window as any).gapi;
        _g.signin2.render('google-button', {
          scope: 'https://www.googleapis.com/auth/analytics.readonly',
          width: 240,
          height: 36,
          longtitle: true,
          theme: 'dark',
          onsuccess: this.alreadySignedIn,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onfailure: (err: any): void => {
            if (err.error === 'popup_closed_by_user') {
              this.$store.dispatch('showError', 'Popup was closed');
              this.clear();
              return;
            }
            // eslint-disable-next-line no-console
            console.error('auth2.onfailure', err);
            this.error = err.details || err.message || err.error;
            this.$store.dispatch('showError', err);
          },
        });
        setTimeout(() => {
          this.authChecked = true; // show the 'google sign in' button
          this.autoAskLocation = true; // ask for location after google sign in
        }, 1000);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('auth2.init', err);
        this.$store.dispatch('showError', err);
      }
    },
    async addGAToken(code: string): Promise<void> {
      await this.$store.dispatch('linearAdmin/addGAToken', {
        id: this.advertiserId,
        token: code,
      });
    },
    askOffline(): void {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _g = (window as any).gapi;
        const auth2 = _g.auth2.getAuthInstance();
        this.value = 10;
        auth2
          .grantOfflineAccess({ scope: 'https://www.googleapis.com/auth/analytics.readonly' })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((resp: any) => {
            // note: no user ID now that we're not asking for logging in into google, just asking for offline access token
            this.saving = true;
            this.showIntro = false;

            // Code for showing new Ga connection from DataApi
            (() => this.addGAToken(resp.code))()
              .then(data => {
                if (data && data?.error) {
                  // eslint-disable-next-line no-console
                  console.log('askOffline data error', data);
                  this.$store.dispatch('showError', 'sorry, an error occurred');
                  return;
                }
                if (this.isDataApiTemplate) {
                  this.nextStep = true;
                  this.value = 30;
                  this.loadGAAccounts().then(() => {
                    if (this.gaAccounts && !this.isAccountLoading) {
                      this.loadConnectors();
                    }
                  });
                } else {
                  this.loadGA();
                  this.nextStep = true;
                  this.value = 30;
                }
              })
              .catch((error: Error) => {
                // eslint-disable-next-line no-console
                console.log('askOffline error', error);
                this.$store.dispatch('showError', 'sorry, an error occurred');
              });
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('askOffline error', err);
            if (err.error === 'popup_closed_by_user') {
              this.$store.dispatch('showError', 'Popup was closed');
              this.clear();
            }
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('auth2.load', err);
        this.$store.dispatch('showError', err);
      }
    },
    LoadGoogleApi(): void {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://apis.google.com/js/platform.js?onload=GoogleApiLoaded';
      document.head.appendChild(script);
    },
    async loadAccountLinking(): Promise<void> {
      await this.$store
        .dispatch('linearAdmin/getAccountLinking', {
          client: true,
          id: this.$route.query.aid,
        })
        .catch((error: Error) => {
          this.$store.dispatch('showError', error);
          this.error = error;
          // eslint-disable-next-line no-console
          console.error(error);
        });
      if (this.$store.state.linearAdmin.accountLinking)
        this.accountLinking = { ...this.$store.state.linearAdmin.accountLinking };
    },
    selectView(accountId: number, propertyId: number, viewId: number): void {
      const ga: AccountLinking = Object.assign({}, this.accountLinking);
      // GA UA Linking
      if (viewId !== 0 && viewId !== -1) {
        if (this.hasSelectedView !== viewId) {
          ga.GoogleAnalytics.AccountId = accountId;
          ga.GoogleAnalytics.WebPropertyId = propertyId;
          delete ga.GoogleAnalytics.GA4PropertyId;
          ga.GoogleAnalytics.ViewId = viewId;
        } else {
          delete ga.GoogleAnalytics.AccountId;
          delete ga.GoogleAnalytics.WebPropertyId;
          delete ga.GoogleAnalytics.GA4PropertyId;
          delete ga.GoogleAnalytics.ViewId;
        }
      } else {
        // GA4 Linking
        if (this.hasSelectedView !== propertyId) {
          ga.GoogleAnalytics.AccountId = accountId;
          ga.GoogleAnalytics.GA4PropertyId = propertyId;
          ga.GoogleAnalytics.WebPropertyId = propertyId;
          ga.GoogleAnalytics.ViewId = -1;
        } else {
          delete ga.GoogleAnalytics.AccountId;
          delete ga.GoogleAnalytics.WebPropertyId;
          delete ga.GoogleAnalytics.GA4PropertyId;
          delete ga.GoogleAnalytics.ViewId;
        }
      }
      this.accountLinking = ga;
    },
    tryAgain(): void {
      this.clear();
      this.askOffline();
    },
    clear(): void {
      this.error = '';
      this.gaData = null;
      this.showIntro = true;
      this.nextStep = false;
      this.value = 0;
    },
  },
});
