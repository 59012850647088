
import Vue from 'vue';
import { format, parse } from 'date-fns';
import { Campaign } from '../../../types/advertisers';
import xlsx from 'json-as-xlsx';
import utils from '../../../util';
import { C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'CampaignList',
  props: ['advertiserData', 'campaignData', 'tacticData', 'advertiser', 'advertiserName', 'product', 'loading'],
  components: {
    ExportMenu: () => import('../../components/toolbar/menus/exportMenu.vue'),
    SearchBar: () => import('./searchBar.vue'),
    C360Icon,
  },
  data: (): {
    selectedItem: string | null;
    search: string | null;
    isShowActive: boolean;
  } => ({
    selectedItem: null,
    search: null,
    isShowActive: false,
  }),
  computed: {
    data() {
      if (!this.campaignData || !Array.isArray(this.campaignData || !this.product)) return null;
      const product = this.product?.toLowerCase();
      const emailSICheck = product === 'emailsi' ? 'siteimpact' : product;
      if (!product) {
        return this.campaignData;
      }
      return this.campaignData.filter(
        (campaign: Campaign) =>
          campaign?.campaignType?.toLowerCase() === emailSICheck ||
          campaign?.CampaignType?.toLowerCase() === emailSICheck,
      );
    },
    hasData(): boolean {
      return this.data?.length > 0;
    },
    campaignCount(): string | null {
      return this.filteredData?.length ? `(${this.filteredData?.length})` : null;
    },
    campaignRecordCount(): string | null {
      return this.filteredData?.length >= 100
        ? 'Showing the first 100 campaigns. Please search to narrow your results.'
        : null;
    },
    allCampaigns(): string {
      return this.data?.map((campaign: Campaign) => campaign.id).join(',');
    },
    currentSection() {
      const filteredLayout = this.tacticData?.filter(section => section.id === this.product?.toLowerCase());
      return filteredLayout?.length > 0 ? filteredLayout[0] : null;
    },
    filteredData() {
      const currentDate = new Date();
      let shallowCopyData = this.data?.slice(0, 100);
      shallowCopyData?.map((campaign: Campaign) => {
        campaign.campaignName = campaign.campaignName || campaign.FriendlyName;
      });
      shallowCopyData = shallowCopyData?.filter((campaign: Campaign) => {
        return !(campaign?.Archived || campaign?.archived);
      });

      if (this.isShowActive) {
        shallowCopyData = shallowCopyData?.filter((campaign: Campaign) => {
          const startDate = campaign?.Start_date || campaign?.startDate;
          const endDate = campaign?.End_date || campaign?.endDate;
          const startDateObj = new Date(startDate);
          const endDateObj = new Date(endDate);

          return startDateObj <= currentDate && endDateObj > currentDate;
        });
      }
      if (!this.search) return shallowCopyData;
      shallowCopyData = this.data?.filter(
        (campaign: Campaign) =>
          campaign?.campaignName?.toLowerCase().includes(this.search?.toLowerCase()) ||
          campaign?.campaignId?.toLowerCase().includes(this.search?.toLowerCase()) ||
          campaign?.FriendlyName?.toLowerCase().includes(this.search?.toLowerCase()) ||
          campaign?.id?.toLowerCase().includes(this.search?.toLowerCase()),
      );
      return shallowCopyData.slice(0, 100);
    },
    message(): string {
      return this.advertiser ? 'No campaigns found' : '';
    },
  },
  methods: {
    selectedCampaign(campaign: Campaign): void {
      this.$emit('selectedCampaign', campaign);
    },
    formatDate(date: string): string {
      try {
        if (!date) return '';
        const parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date()); // parse custom date format
        return format(parsedDate, 'MMM d, yyyy');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('campaign date', date, err);
      }
      return '';
    },
    formatData(data, allCampaigns = false) {
      const startdate = this.formatDate(data?.Start_date);
      const enddate = this.formatDate(data?.End_date);
      return {
        advertiserId: data.advertiserId || this.advertiser,
        advertiserName: data.advertiserName || this.advertiserName,
        campaigns: allCampaigns ? this.allCampaigns : data.campaignId || data.id,
        startdate,
        enddate,
        daterange: 'alltime',
      };
    },
    getTacticData(campaignData: Campaign) {
      const campaignTactic = campaignData?.campaignType
        ? campaignData?.campaignType?.toLowerCase()
        : campaignData?.CampaignType?.toLowerCase();
      const filteredLayout = this.tacticData?.filter(section => section.id === campaignTactic);
      return filteredLayout?.length > 0 ? filteredLayout[0] : null;
    },
    calcStatus(campaign): string {
      const currentDate = new Date();
      const startDate = campaign?.Start_date || campaign?.startDate;
      const endDate = campaign?.End_date || campaign?.endDate;
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      if (!startDate && !endDate) {
        return 'Unknown';
      }

      if (startDate && endDate) {
        if (startDateObj <= currentDate && endDateObj > currentDate) {
          return 'Active';
        } else if (endDateObj < currentDate) {
          return 'Ended/Inactive';
        }
      } else if (startDate && startDateObj > currentDate) {
        return 'Planned';
      }
      return 'Unknown';
    },
    generateXLSX(): void {
      const currentDate = new Date();
      if (!this.filteredData) return;

      const selectedAdvertiser = this.advertiserData?.advertisers?.find(item => item.id === this.advertiser);
      const agencyName = selectedAdvertiser?.agency_name;
      const domain = window.location.origin;
      let filteredCampaigns = [];

      if (this.isShowActive) {
        filteredCampaigns = this.campaignData?.filter((campaign: Campaign) => {
          const startDate = campaign?.Start_date || campaign?.startDate;
          const endDate = campaign?.End_date || campaign?.endDate;
          const startDateObj = new Date(startDate);
          const endDateObj = new Date(endDate);

          return startDateObj <= currentDate && endDateObj > currentDate;
        });
      } else {
        filteredCampaigns = this.campaignData;
      }

      const preparedData = filteredCampaigns?.map(campaign => {
        return {
          advertiserName: this.advertiserName,
          agencyName: agencyName,
          linkToAdvertiser: `${domain}/home/?id=${this.advertiser}&tab=summary`,
          productName: utils.tacticTitleMap(campaign.CampaignType),
          campaignName: campaign.FriendlyName,
          linkToCampaign: `${domain}/home/?id=${
            this.advertiser
          }&tab=${campaign.CampaignType.toLowerCase()}&viewCampaigns=${campaign.id}`,
        };
      });

      const data = [
        {
          sheet: `${this.advertiserName} campaigns`,
          columns: [
            { label: 'Advertiser Name', value: 'advertiserName' },
            { label: 'Agency (Partner) Name', value: 'agencyName' },
            { label: 'Link to Advertiser', value: 'linkToAdvertiser' },
            { label: 'Product Name', value: 'productName' },
            { label: 'Campaign Name', value: 'campaignName' },
            { label: 'Link to Campaign', value: 'linkToCampaign' },
          ],
          content: preparedData,
        },
      ];

      const settings = {
        fileName: `${this.advertiserName}_campaigns_by_product_${this.product}_${format(
          new Date(),
          'yyyy-MM-dd-HH-mm-ss',
        )}`,
        extraLength: 3,
        writeMode: 'writeFile',
        writeOptions: {},
      };

      this.$store.dispatch('showError', {
        message: 'Export started, please wait for download to start...',
        errorType: 'success',
      });

      xlsx(data, settings);
    },
    updateSearch(searcValue): void {
      this.search = searcValue;
    },
  },
  watch: {
    campaignData(): void {
      this.selectedItem = null;
      this.search = null;
    },
  },
});
