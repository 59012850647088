/*  ***WARNING***
  this is an output file that should not be modified by hand*/
import deepmerge from 'ts-deepmerge';
import defaultTheme from './default';
import defaultValues from './default.scss';
import themeValues from './sbg.scss'; 
import { Variables, ThemeConfig } from './variables';
import { setVariableColors, cleanupConfig } from './utils';
const variables: Variables = { ...defaultValues, ...themeValues };
const config: ThemeConfig = {
  name: 'sbg',
  companyId: 'Sinclair',
  companyName: 'SBG',
  rootDomain: 'sbganalytics.com',
  parentAgency: 'none',
  domains: {
    production: 'https://sbganalytics.com',
    staging: 'https://staging.sbganalytics.com',
    dev: 'https://dev.sbganalytics.com',
    local: 'https://localhost:8080',
  },
  ...setVariableColors(variables),
  logo: { file: 'sbg_white.png', emailLogo: 'sbg.png' },
  favicon: 'compulse',
  config: {
    chartColors: ['#00b0ff', '#ff5252', '#ffc400', '#00c853', '#2979ff', '#ab47bc'],
    pieCharts: {
      stacked: false,
      firstItem: 'legend',
    },
    maps: {
      themes: ['sbgAnalytics'],
    },
  },
};
let theme: ThemeConfig = deepmerge(defaultTheme, config);
theme = cleanupConfig(theme, config);
export default theme;
