import mutations from './mutations';
import actions from './actions';
import { StateType } from './types';

const state: StateType = {
  isExportConversionsLoading: false,
  isExportConversionBreakdownLoading: false,
  exportConversionsError: null,
  exportConversionBreakdownError: null,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
