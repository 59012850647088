
import Vue from 'vue';
import utils from '../../../../../util';
import { SummaryMetric, Header, Table } from '../../types';
import { tacticIcons } from '../../utils';
import Tooltips from '../../../charts/tooltip/chartTooltip.vue';
import ExportDownloadBtn from '../../../charts/buttons/exportDownloadBtn.vue';
import { Tooltip } from '../../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';

export default Vue.extend({
  name: 'summaryTopMetricsOverlay',
  inheritAttrs: false,
  components: {
    Tooltips,
    ExportDownloadBtn,
  },
  props: [
    'config',
    'customSort',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'hasMapData',
    'sectionConfig',
    'componentConfig',
  ],
  computed: {
    mapHeight(): number {
      return this.$store.state.summary?.dimensions?.mapHeight || 0;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    overlayStyles(): any {
      const top = this.$store.state.summary?.dimensions?.summaryTopPosition;
      if (!top) return {};

      let opacity = 1;
      if (this.hasMap && !this.isMapReady && !this.isExporting && this.mapHeight) {
        opacity = 0;
      }
      return { top: `${top}px`, opacity };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataTables(): Array<any> {
      return this.$store.state?.summary?.groupedTopTactics;
    },
    hasProductWithManyHeaders(): boolean {
      return this?.dataTables?.some(table => table.headers.length > 5);
    },
    isMapReady(): boolean {
      return this.$store.state?.summary?.map?.ready;
    },
    isXLS(): boolean {
      return this.isExporting && this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    canExportToXLS(): boolean {
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return (
        Array.isArray(this.dataTables) && this.dataTables.length > 0 && !!this.componentConfig?.exportableTableData
      );
    },
    hideMap(): boolean {
      return this.componentConfig?.hideSummaryMap;
    },
    hasMap(): boolean {
      return !this.hideMap && this.hasMapData;
    },
    shrinkedOverlay(): boolean {
      return this.hasMap && this.hasProductWithManyHeaders;
    },
  },
  methods: {
    getHeaders(table: Table): Header[] {
      const headers: Header[] = [];
      const headerLength = table.headers.length;

      const headerWidthsMapping = [
        {
          cells: 2,
          widths: [25, 75],
        },
        {
          cells: 3,
          widths: [25, 17, 65],
        },
        {
          cells: 4,
          widths: [27, 17, 17, 49],
        },
        {
          cells: 5,
          widths: [25, 17, 17, 15, 30],
        },
        {
          cells: 6,
          widths: [25, 17, 15, 15, 15, 15],
        },
      ];

      table.headers.forEach((header, index) => {
        const headerWidths = headerWidthsMapping.find(o => o.cells === headerLength);
        header['width'] = `${headerWidths?.widths[index]}%`;
        if (header.text === 'Product' && !this.hasProductWithManyHeaders) {
          header['width'] = '34%';
        }
        headers.push(header);
      });

      return headers;
    },
    getTooltip(metric: string): Array<Tooltip> {
      return [utils.getTooltipsFromMetric(metric)];
    },
    headersContains(headers: Array<SummaryMetric>, metricName: string): boolean {
      const found = headers.find(header => header.value.toLowerCase() === metricName.toLowerCase());
      if (found) return true;
      return false;
    },
    formatNumber(item, value, metricName = ''): string {
      if (metricName === 'Conversions' && (value === 'undefined' || typeof value === 'undefined')) {
        return 'N/A';
      }
      if (
        (item.TagName === 'OTT' && value === 0) ||
        (item.TagName === 'Digital Video (Video + OTT)' && value === 0) ||
        typeof value === 'undefined'
      ) {
        return '—';
      }
      return utils.formatNumberWithCommas(value);
    },
    formatClickThrough(item, value): string {
      if ((item.TagName === 'OTT' && value === '0%') || typeof value === 'undefined') {
        return '—';
      }
      // console.log('formatClickThrough', item, value);
      return value;
    },
    getTacticClass(name: string): string | null {
      if (!name) return null;
      return utils.removeWhiteSpaceLowerCase(name);
    },
    getIcon(name: string): string | null {
      if (!name) return null;
      return tacticIcons[utils.removeWhiteSpaceLowerCase(name)];
    },
    getImageLink(name: string) {
      name = this.getTacticClass(this.tacticTitleMap(name)).toLowerCase();
      const imageName = utils.parsedTacticNames.includes(name) ? name : 'placeholder';
      return require(`@/assets/summaryIcons/${imageName}.svg`);
    },
    setTopPosition(): void {
      // compares map and summary height to figure out the summary positioning
      setTimeout(() => {
        if (!this.$refs?.overlayContainer?.clientHeight) {
          return;
        }

        const position = (this.mapHeight - this.$refs.overlayContainer.clientHeight) / 2;

        this.$store.dispatch('summary/setSummaryHeight', this.$refs.overlayContainer.clientHeight);
        this.$store.dispatch('summary/setSummaryTopPosition', position);
      }, 100);
    },
    tacticTitleMap(tactic: string): string {
      return utils.tacticTitleMap(tactic);
    },
    tacticTitleShorten(tactic: string): string {
      if (tactic === 'Digital Video (Video + OTT)') {
        return 'Digital  Video';
      }
      // console.log(tactic);
      return tactic;
    },
    exportTableData(): void {
      const tables = [];
      if (Array.isArray(this.dataTables)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.dataTables.forEach((tbl: any) => {
          const map = tbl.headers;
          const headers = map.map(h => h.text);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const table = tbl.tactics.map((tactic: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const row = map.map((h: any) => {
              switch (h.value) {
                case 'TagName':
                  return utils.tacticTitleMap(tactic[h.value]);
                default:
                  return tactic[h.value];
              }
            });
            return row;
          });
          tables.push({ headers, table });
        });
      }
      // console.log('summaryTopMetricsOverlay', tables);

      if (tables.length === 0) {
        this.$emit('rendered', { empty: true });
      } else {
        this.$emit('rendered', { empty: false, tables, config: this.componentConfig });
      }
    },
    disableSort(tactics): boolean {
      if (!tactics) return false;
      return tactics?.length === 1;
    },
  },
  watch: {
    mapHeight: {
      handler(): void {
        this.setTopPosition();
      },
    },
    dataTables: {
      handler(): void {
        if (this.isExporting && this.isXLS) {
          this.exportTableData();
          return;
        }
        if (!this.hasMap) {
          if (Array.isArray(this.dataTables) && this.dataTables.length === 0) {
            this.$emit('rendered', { empty: true });
          } else {
            this.$emit('rendered', { empty: false });
          }
        }
        this.setTopPosition(); // set height based on data
      },
    },
  },
});
