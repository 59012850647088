
import Vue from 'vue';
import utils from '../../util';
import { useLink } from '@c360/ui';

export default Vue.extend({
  components: {
    HeaderLogoC360: () => import('../components/toolbar/headerLogoC360.vue'),
    MainFooter: () => import('../global/footer.vue'),
    companyLogo: () => import('../global/companyLogo.vue'),
    AppGlobals: () => import('../global/globals.vue'),
    Status: () => import('../global/status.vue'),
  },
  data(): {
    error: string | undefined;
    email: string;
    password: string;
    loading: boolean;
    linkLoading: boolean;
    tokenLoading: boolean;
    errorMessage: string | null;
  } {
    return {
      error: '',
      email: process.env.VUE_APP_LOGIN_EMAIL || '',
      password: process.env.VUE_APP_LOGIN_PASSWORD || '',
      loading: false,
      linkLoading: false,
      tokenLoading: false,
      errorMessage: null,
    };
  },
  computed: {
    isSinclair(): boolean {
      return utils.isSinclair(this.Theme);
    },
    isMyAnalyticsHubWhitelabel(): boolean {
      return window.location.host.includes('myanalyticshub.com');
    },
    redirectLink(): string {
      const agency = utils.getAgencyFromURL();
      return useLink({
        product: 'compulse360',
        agency,
        env: utils.getEnvForProductLink(),
      });
    },
    errorMessageDialog(): boolean {
      return this.errorMessage != null;
    },
    adfsURLs(): object {
      if (!process || !process.env || !process.env.VUE_APP_ADFS_AH_SSO || process.env.VUE_APP_ADFS_AH_SSO.length < 1) {
        return {};
      }
      return {
        ah:
          (process.env.VUE_APP_ADFS_AH_SSO || 'https://backend.admin.stg.adportal.io/saml/login/?next=') +
          encodeURIComponent(`https://${window.location.host}/login${window.location.search}`),
      };
    },
  },
  mounted() {
    const currentURL = document.location.host;
    if (utils.urlsToRedirect.includes(currentURL)) {
      window.location.href = utils.compulseRedirectURL;
      return;
    }
    let hasToken = false;
    if (this.$route.query?.token) {
      hasToken = true;
      this.handleToken(this.$route.query.token as string);
    } else if (this.$route.query?.refresh_token) {
      hasToken = true;
      this.handleRefreshToken(this.$route.query.refresh_token as string);
    }

    const nextUrl = this.$route.query?.nextUrl;
    if (nextUrl) {
      localStorage.setItem('nextUrl', nextUrl);
    } else {
      localStorage.removeItem('nextUrl');
    }

    // redirect to Compulse360 login page only for new domains and
    // if we don't use token auth for Impersonate user link
    if (!hasToken) {
      const redirectURI = encodeURIComponent(`${window.location.origin}${nextUrl}`);
      window.location.href = `${this.redirectLink}/login?redirectUri=${redirectURI}`;
      return;
    }
  },
  methods: {
    getPortalLink(): string {
      return useLink({
        product: 'compulse360',
        agency: 'sinclair',
        env: utils.getEnvForProductLink(),
      });
    },
    login(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const form = this.$refs.form as any;
      this.error = undefined;
      if (form.validate()) {
        this.loading = true;
        this.$store
          .dispatch('login', {
            email: this.email.toLowerCase(),
            password: this.password,
            domain: window.location.hostname,
          })
          .then(user => {
            this.loading = false;
            if (typeof user === 'undefined' || !user || !user.email) {
              this.error = user.error || 'login failed';
              return;
            }
            localStorage.setItem('ah:products', JSON.stringify(user.products));
            // TODO: rename
            localStorage.setItem('ah:jwtToken', user.refreshToken); // short refresh supposed to ne
            localStorage.setItem('ah:refreshToken', user.refreshToken); // full refresh

            if (this.$route.query.nextUrl) {
              this.$router.push(this.$route.query.nextUrl as string);
            } else {
              this.$router.push({ name: 'home', query: { recent: 'true' } });
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            this.loading = false;
            // eslint-disable-next-line no-console
            console.error('login error', error);
          });
      }
    },
    handleToken(token: string): void {
      this.tokenLoading = true;
      this.$store
        .dispatch('tokenlogin', { token, domain: window.location.hostname })
        .then(user => {
          if (!user.email) {
            this.tokenLoading = false;
            this.error = user.error || 'tokenlogin failed';
            return;
          }
          localStorage.setItem('ah:products', JSON.stringify(user.products));
          // TODO: rename
          localStorage.setItem('ah:jwtToken', user.refreshToken); // short refresh supposed to ne
          localStorage.setItem('ah:refreshToken', user.refreshToken); // full refresh

          // don't reset this.tokenLoading, so it doesn't flash the login box before navigating away
          if (this.$route.query.nextUrl) {
            this.$router.push(this.$route.query.nextUrl as string);
          } else {
            this.$router.push({ name: 'home', query: { recent: 'true' } });
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.tokenLoading = false;
          // eslint-disable-next-line no-console
          console.error('tokenlogin error', error);
        });
    },
    handleRefreshToken(token: string): void {
      this.tokenLoading = true;
      this.$store
        .dispatch('tokenlogin', { token, domain: window.location.hostname })
        .then(user => {
          if (!user.email) {
            this.tokenLoading = false;
            this.error = user.error || 'tokenlogin failed';
            return;
          }
          localStorage.setItem('ah:products', JSON.stringify(user.products));
          // TODO: rename
          localStorage.setItem('ah:jwtToken', user.refreshToken); // short refresh supposed to ne
          localStorage.setItem('ah:refreshToken', user.refreshToken); // full refresh
          // don't reset this.tokenLoading, so it doesn't flash the login box before navigating away
          if (this.$route.query.nextUrl) {
            this.$router.push(this.$route.query.nextUrl as string);
          } else {
            this.$router.push({ name: 'home', query: { recent: 'true' } });
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.tokenLoading = false;
          // eslint-disable-next-line no-console
          console.error('tokenlogin error', error);
        });
    },
  },
});
