
import Vue from 'vue';
import { componentConfig, componentHeight, baseChartConfig } from './utils/configRegisteredConversionsStackedBarChart';
import { format } from 'date-fns';
import NoChartData from '../../../pages/components/charts/no-data/NoChartData.vue';
import StackedBarChart from '../../../pages/components/charts/bar/stackedBarChart.vue';

export default Vue.extend({
  name: 'RegisteredConversionsStackedBarChart',
  components: { NoChartData, StackedBarChart },
  props: {
    sectionConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => {
    return {
      loading: true,
      componentConfig,
      componentHeight,
      localStorageName: 'metric_full_primary',
      baseChartConfig,
      chartData: {
        ...baseChartConfig,
        legend: {
          ...baseChartConfig.legend,
          data: [],
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        series: [],
      },
      selectedResolution: 'Monthly',
      resolutionMap: {
        Daily: 'day',
        Weekly: 'week',
        Monthly: 'month',
      },
      selectedLine: null,
      percentageChange: null,
      totalConversions: null,
    };
  },

  mounted() {
    this.fetchData();
  },

  computed: {
    comparisonPeriod(): string {
      switch (this.daterange) {
        case 'thisweek':
          return 'Last 7 days';
        case 'thisyear':
          return 'Last 365 days';
        default:
          return 'Last 30 days';
      }
    },

    daterange(): string {
      return this.$store.state.order?.singleOrderParams?.daterange;
    },

    showNoDataChart(): boolean {
      if (this.loading) return false;
      const hasData = this.totalConversions > 0;
      return !hasData;
    },
  },

  watch: {
    daterange(newVal) {
      if (newVal) {
        this.fetchData();
      }
    },
  },

  methods: {
    async fetchData(): Promise<void> {
      try {
        this.loading = true;

        const payload = {
          advertiserId: this.$route.query?.id || '',
          orderId: this.$route.query.orderId,
          resolution: this.resolutionMap[this.selectedResolution],
          daterange: this.daterange || 'thismonth',
        };

        const data = await this.$store.dispatch('performance/getOrderConversions', payload);

        this.totalConversions = data.total.conversions;
        this.percentageChange = data.total.conversionsChangeRate
          ? Number(data.total.conversionsChangeRate).toFixed(2)
          : null;

        this.chartData = this.formatChartData(data.data);
      } catch (error) {
        console.error({ error });
      } finally {
        this.loading = false;
      }
    },

    handlePeriodChange(uiValue: string) {
      this.selectedResolution = uiValue;
      this.fetchData();
    },

    formatDateString(dateStr: string): Date {
      const [year, month, day] = dateStr.split('-').map(Number);
      return new Date(year, month - 1, day || 1);
    },

    getFormattedAxisLabel(date: string): string {
      const dateObj = this.formatDateString(date);

      switch (this.selectedResolution) {
        case 'Daily':
          return format(dateObj, 'MM/dd/yy');
        case 'Weekly': {
          const endDate = new Date(dateObj);
          endDate.setDate(dateObj.getDate() + 6);
          return `${format(dateObj, 'MM/dd/yy')} - ${format(endDate, 'MM/dd/yy')}`;
        }
        case 'Monthly':
          return format(dateObj, 'MMM').toUpperCase();
        default:
          return date;
      }
    },

    getSortedData(data: Array<{ date: string; data: Array<{ type: string; conversions: number }> }>) {
      return [...data].sort(
        (a, b) => this.formatDateString(a.date).getTime() - this.formatDateString(b.date).getTime(),
      );
    },

    createSeriesData(conversionsData: Array<{ date: string; data: Array<{ type: string; conversions: number }> }>) {
      const uniqueTypes = [...new Set(conversionsData.flatMap(item => item.data?.map(d => d.type)))] as string[];
      const seriesData: Record<string, number[]> = {};
      const totalsByDate: number[] = new Array(conversionsData.length).fill(0);

      uniqueTypes.forEach(type => {
        seriesData[type] = new Array(conversionsData.length).fill(0);
      });

      conversionsData.forEach((item, index) => {
        let dateTotal = 0;
        item.data.forEach(typeData => {
          if (seriesData[typeData.type]) {
            seriesData[typeData.type][index] = typeData.conversions;
            dateTotal += typeData.conversions;
          }
        });
        totalsByDate[index] = dateTotal;
      });

      return { uniqueTypes, seriesData, totalsByDate };
    },

    formatChartData(data: Array<{ date: string; data: Array<{ type: string; conversions: number }> }>) {
      const conversionsData = this.getSortedData(data);
      const xAxisData = conversionsData?.map(item => this.getFormattedAxisLabel(item.date));
      const { uniqueTypes, seriesData, totalsByDate } = this.createSeriesData(conversionsData);

      const series = uniqueTypes
        .map((type, index) => ({
          name: type,
          type: 'bar',
          stack: 'Ad',
          barMaxWidth: 20,
          emphasis: { focus: 'series' },
          itemStyle: {
            color: this.componentConfig.chartColors?.[index],
          },
          data: seriesData[type].map((value, i) => ({
            value,
            totalConversions: totalsByDate[i],
          })),
        }))
        .reverse();

      return {
        ...this.baseChartConfig,
        legend: {
          ...this.baseChartConfig.legend,
          data: uniqueTypes,
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: {
            interval:
              this.selectedResolution === 'Daily' || this.selectedResolution === 'Weekly'
                ? Math.floor(xAxisData.length / 5)
                : 0,
            rotate: 0,
          },
        },
        series,
      };
    },
  },
});
