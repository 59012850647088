
import Vue from 'vue';
import { format } from 'date-fns';
import { AdvertiserDataApi } from '../../store/modules/customer/types';
import utils from '../../util';

export default Vue.extend({
  name: 'linearAdminAdvertiser',
  props: {
    advertiser: {
      required: true,
      type: Object as () => AdvertiserDataApi,
    },
  },
  data(): {
    hasError: boolean;
    error: string;
    loadingGA: boolean;
  } {
    return {
      hasError: false,
      error: '',
      loadingGA: false,
    };
  },
  methods: {
    openDetails(): void {
      this.$router.push({
        query: { ppid: this.advertiser.id },
      });
    },
    openAction(type: string): void {
      this.$emit('open-action', type);
      this.$router.push({
        query: { ppid: this.advertiser.id },
      });
    },
    status(status: string): boolean {
      return status?.toLowerCase() === 'active';
    },
  },
  computed: {
    isLarge(): boolean {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    shortUrl(): string {
      return this.advertiser.url?.replace(/(https?:\/\/www.)/gi, '') || '—';
    },
    friendlyStations(): string {
      if (Array.isArray(this.advertiser.stations)) {
        if (this.advertiser.stations.length > 3) {
          const stations = this.advertiser.stations.slice(0, 3);
          return `${stations.join(', ')} (${this.advertiser.stations.length - 3} more)`;
        }
        return this.advertiser.stations.join(', ');
      }
      return '—';
    },
    gaAccessStyle(): string {
      let success = true;
      const gaSite = this.advertiser.GASite;
      if (!gaSite || gaSite === 'blocked') success = false;
      return success ? 'green--text' : 'red--text';
    },
    friendlyLastCampaignEndDate(): string {
      if (!this.advertiser.last_campaign_date) return '—';
      const parsedDate = new Date(this.advertiser.last_campaign_date);
      return format(parsedDate, 'MM/d/yy');
    },
    friendlyDateCreated(): string {
      if (!this.advertiser.created_at) return '—';
      const parsedDate = new Date(this.advertiser.created_at);
      return format(parsedDate, 'MM/d/yy');
    },
    isShowAgencyInfo(): boolean {
      return utils.hasRight(this.$store.getters.user, ['SHOW_AGENCY_INFO']);
    },
  },
});
