export type Tooltip = {
  title: string;
  message: string;
  key?: string;
};

const METRIC_TOOLTIPS: { [key: string]: Tooltip } = {
  AUDIENCE: {
    title: 'Audience',
    message: 'The number of recipients who were sent this ad via email.',
  },
  AIRED: {
    title: 'Aired',
    message: 'Number of times ad appeared on TV.',
  },
  AVG_SESSION_DURATION: {
    title: 'Avg. Session Duration',
    message:
      'The average amount of time a user spends on a website. Total time across all sessions divided by the number of sessions.',
  },
  AVG_DURATION: {
    title: 'Avg. Duration',
    message:
      'The average number of minutes and seconds of each Call conducted = Total number of minutes and seconds / Calls.',
  },
  BOUNCE_RATE: {
    title: 'Bounce Rate',
    message: 'Percentage of sessions to a website where only 1 page was viewed',
  },
  COMPLETION_RATE: {
    title: 'VCR (Video Completion Rate)',
    message:
      'Percentage of campaign impressions that are viewed to completion. If 10 impressions are served, and 9 are viewed to completion, VCR is 90%. A campaign is not likely ever going to reach 100%. A VCR of at least 90% is good.',
    key: 'completionrate',
  },
  PREROLL_COMPLETION_RATE: {
    title: 'VCR (Video Completion Rate)',
    message:
      'Percentage of campaign impressions that are viewed to completion. If 10 impressions are served, and 8 are viewed to completion, VCR is 80%. The national average Video Completion Rate ranges from 70-80%.',
    key: 'prerollcompletionrate',
  },
  OTT_COMPLETION_RATE: {
    title: 'VCR (Video Completion Rate) for CTV',
    message:
      'Percentage of campaign impressions that are viewed to completion. If 10 impressions are served, and 9 are viewed to completion, VCR is 90%. A campaign is not likely ever going to reach 100%. A VCR of at least 85% is good.',
    key: 'completionrate',
  },
  DVIDEO_COMPLETION_RATE: {
    title: 'VCR (Video Completion Rate) for Digital Video',
    message:
      'Percentage of campaign impressions that are viewed to completion. If 10 impressions are served, and 8 are viewed to completion, VCR is 80%. A campaign is not likely ever going to reach 100%. A VCR of at least 75% is good.',
    key: 'completionrate',
  },
  CAMPAIGN_TARGETING: {
    title: 'Campaign Targeting',
    message:
      'This module shows the locations where the campaign is targeted to and not where impressions were actually served. Where impressions were served are shown in the other modules on this page.',
  },
  CTR: {
    title: 'CTR (Click Through Rate)',
    message:
      'Percentage of times a user clicked on an ad (clicks/impressions). For Display, the industry average is 0.05% to 0.10%. For Preroll, industry average is 0.10% to 0.30%.',
  },
  CLICKS: {
    title: 'Clicks',
    message: 'The number of times an ad was clicked on.',
  },
  CALLS: {
    title: 'Calls',
    message: 'The number of calls made to potential customers.',
  },
  CONVERSIONS: {
    title: 'Conversions',
    message: `Conversions is defined as sending traffic to priority URLs (not "Lead Gen") and can be applied to Pre-roll/Video, Display and CTV regardless of Inventory/Publication. 
    The driver of Conversions is determined by audience size considered with the configuration of the Inventory + Geo-targeting + Behavioral/Demographic targeting as well as the quality of the creative and click-through URL.
    <br><br>
    A conversion pixel is used to track user actions in response to an Advertiser's creatives, such as visiting a specific page on an Advertiser's website, like a "Contact Us" or a "Submit Info" form page. Specific conversion and event set-ups are contingent on the campaign goals.
    <br><br>
    The conversion pixel code must be implemented on the Advertiser website, so that segment audiences can be leveraged to create "look-a-like audiences". If it is not installed by the Advertiser, then they could use Google UTMs which then is reported on in the Advertiser's Google Analytics. 
    If this method is used, there will be a sizable discrepancy between CTV reporting and Google Analytics reporting, due to the different technologies.`,
  },
  CONVERSIONS_BREAKDOWN: {
    title: 'Conversion breakdown',
    message: 'Conversion breakdown shows all conversions associated with this campaign.',
  },
  CONVERSIONS_OTT: {
    title: 'Conversions',
    message:
      'A customer action like a page view, direction click or store visit that is considered valuable to campaign success, ROI calculation and business goals.',
  },
  ALLCONVERSIONS: {
    title: 'All Conversions',
    message: `Gives you a view of all the conversions that your Google Ads campaigns drive. This metric includes any conversion actions that you've chosen not to include in your main "Conversions" metric.
    <br><br>
    “All conversions” includes the data in your “Conversions” metric plus conversion actions you’ve chosen not to include in your “Conversions” metric, store visits, certain phone calls and more.
    <br><br>
    These additional conversions can give you a more accurate understanding of how users interact with your business and this helps you better calculate the effectiveness of your advertising.`,
  },
  CPC: {
    title: 'CPC (Cost Per Click)',
    message:
      'Cost per Click, represented in Dollars = Dollars spent on a campaign / number of clicks the campaign achieved.',
  },
  CPM: {
    title: 'CPM (Cost Per Thousand Impressions)',
    message:
      'Cost per Mille (Thousand), represented in Dollars = Dollars spent on a campaign / number of Impressions / 1,000.',
  },
  CVR: {
    title: 'Conversion Rate',
    message: `Conversion rate is Conversions divided by Clicks. A good conversion rate depends on your business type and
    campaign goals. To improve your conversion rate, you should consider both the messaging in your ads as well as how well your website or landing page drives users towards a conversion.`,
  },
  CPV: {
    title: 'Average CPV',
    message:
      "Average cost-per-view (CPV) is the average amount an advertiser pays for a view of their video ad. CPV fluctuates based on ad length, creative quality, targeting, and auction dynamics among other factors. It's calculated as Spend divided by Views.",
  },
  DAYPART: {
    title: 'Dayparts',
    message:
      '<ul><li>Morning: 4:00:00AM to 8:59:59AM</li><li>MidDay: 9:00:00AM to 1:59:59PM</li><li>Afternoon: 2:00:00PM to 5:59:59PM</li><li>Night: 6:00:00PM to 11:59:59PM</li><li>Overnight: 12:00:00AM to 3:59:59AM</li></ul>',
  },
  FACEBOOK_PAGE_ENGAGEMENTS: {
    title: 'Page Engagements',
    message:
      'The total number of actions that people took on a Facebook Page and its posts, attributed to ads. Page engagement includes actions such as liking the FB Page, loving a post, checking in to a location, clicking a link, etc.',
  },
  FACEBOOK_VIDEO_VIEWS: {
    title: 'Video Views',
    message:
      'The number of times people watched your video ad on Facebook for at least 2 continuous seconds, or 15 seconds or longer',
  },
  FACEBOOK_PAGE_FOLLOWERS: {
    title: 'Page Followers',
    message: 'The number of people who FOLLOW a Facebook Page.',
  },
  FACEBOOK_PAGE_LIKES: {
    title: 'Page Likes',
    message: 'The number of times the LIKE button was clicked for a Facebook Page',
  },
  FACEBOOK_PAGE_VIEWS: {
    title: 'Page Views',
    message: `Page views are the number of times a Page's profile has been viewed by people, including people who are logged into Facebook and those who aren't.`,
  },
  FACEBOOK_ACTIONS_ON_PAGE: {
    title: 'Actions On Page',
    message:
      `Number of actions that people do once they’ve landed on a Facebook Page. Actions are activities that occur on your ad and may include link clicks, outbound clicks, post engagement and video views. ` +
      `Actions that happen as a result of your ad include website conversions, website leads, mobile app installs, mobile app purchases and offline conversions.`,
  },
  FACEBOOK_POST_ENGAGEMENTS: {
    title: 'Post Engagements',
    message:
      'The total number of actions that people took on a Facebook post, attributed to ads. Includes actions such as liking the FB Page, loving a post, checking in to a location, clicking a link, etc.',
  },
  FACEBOOK_CLICKS: {
    title: 'Clicks',
    message:
      'Clicks (all) includes Link Clicks plus clicks on other parts of an ad, including clicks on post likes, comments or shares, clicks to a Facebook Page or Instagram profile and clicks to expand a photo or video to full screen. Clicks will be equal to or greater than Link Clicks.',
  },
  FACEBOOK_LINK_CLICKS: {
    title: 'Link Clicks',
    message:
      'The number of clicks on links within the ad that led to advertiser-specified destinations, on or off Facebook.',
  },
  FACEBOOK_CTR: {
    title: 'CTR (Click Through Rate)',
    message: 'Percentage of times a user clicked on an ad (clicks/impressions).',
  },
  FACEBOOK_LINK_CTR: {
    title: 'Link CTR (Click Through Rate)',
    message: 'The number of clicks on links within the ad divided by Impressions.',
  },
  FACEBOOK_UNIQUE_CLICKS: {
    title: 'Total Unique Clicks',
    message: 'The total number of people who performed a click (all). This metric counts people, not actions.',
  },
  FACEBOOK_POST_REACH: {
    title: 'Post Reach',
    message: 'Number of people who saw your post at least once.',
  },
  FACEBOOK_AUDIENCE_NETWORK: {
    title: 'Audience Network',
    message: `Audience Network lets you extend your ad campaigns beyond Facebook to reach your audiences on websites and apps across devices such as computers, mobile devices and connected TVs.
    Facebook uses the same targeting, measurement and delivery to help improve the performance and efficiency of your campaigns.`,
  },
  FACEBOOK_GEO_DATA: {
    title: 'Geo Data',
    message:
      'Although Facebook allows us to target by zip code, city, county or radius of an address, they only provide data in their API at the DMA or State level.',
  },
  FREQUENCY: {
    title: 'Frequency',
    message:
      'The number of times a unique user within a target demographic sees a marketing message within the campaign period.',
  },
  GOAL: {
    title: 'Goal',
    message: `The target number of Impressions. When selecting a custom date range, the Impression Goal for that date range is the Impression Goal for the entire campaign divided evenly throughout the life of the campaign.
    <br><br>
    The impression goal when selecting a custom date range is divided by the number of days you select into the total impression goal.
    <br><br>
    If this campaign has different monthly or weekly goals through the life of the campaign, what is shown here will not match the different monthly or weekly Impression Goals.`,
  },
  HOURS: {
    title: 'Hours',
    message: 'The total number of Hours that a video creative was watched.',
  },
  HUNDRED_PERCENT_VIEWED: {
    title: '100% Viewed',
    message: 'The number of video ad Impressions which were viewed in their entirety.',
  },
  IMPRESSIONS: { title: 'Impressions', message: 'The number of times your ads were on screen.' },
  IMPS_ALL_TIME: {
    title: 'Impressions All Time',
    message: 'The number of times an ad was presented to a user over the life of this campaign.',
  },
  IMPRESSIONS_PERCENT: {
    title: 'Impressions %',
    message:
      'Percentage of the Total Impressions. It is calculated as Impressions divided by Total Impressions. Same as “% of Total”.',
  },
  IMPRESSIONS_GOAL: {
    title: 'Impressions Goal',
    message: 'The target number of Impressions.',
  },
  PACING: {
    title: 'Pacing',
    message: `Refers to the pace this campaign is to tracking in order to meet its goal by the time the campaign ends.
    <br><br>
    0% means that it’s perfectly on track.
    <br><br>
    A positive value means you are pacing ahead of your goal.
    <br><br>
    A negative value means you are pacing behind your goal.
    <br><br>
    The value also indicates by how much you will beat or miss your goal, if your performance continues as is. If this campaign is pacing below -20%, check if the campaign has been paused or ended.
    <br><br>
    Note that pacing is calculated evenly for the duration of the campaign. It does not take into consideration weighted delivery, where different levels of spend are applied at different times during the campaign.`,
  },
  INNOVIDXP_IMRESSION_SHARE: {
    title: 'Impression Share',
    message: 'Percentage of Impressions in relation to the total number of Impressions',
  },
  INNOVIDXP_RESPONSES: {
    title: 'Responses',
    message: 'Number of times a user responded to an ad being shown to them',
  },
  INNOVIDXP_RESPONSE_RATE: {
    title: 'Response Rate',
    message: 'Percentage of “Responses” compared to “Impressions”',
  },
  INDEX: {
    title: 'Index',
    message: `“Index” for a Creative is calculated as “Response Share for that Creative” divided by “% of Total for that Creative”
      (aka "Impressions Share"). For example, if the "Response Share" for a Creative is “47.50%” and the "Impressions Share" is “33.68%, then "Index" is 47.50 divided by 33.68 and we get “141%”.`,
  },
  PERCENT_OF_GOAL: {
    title: '% of Goal',
    message: `Percentage of the Goal that was fulfilled. It is calculated as Impressions divided by Goal.`,
  },
  PAGE_VIEWS: {
    title: 'Screen Page Views',
    message:
      'The total number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted.',
  },
  VIEW_RATE: {
    title: 'View Rate',
    message:
      'View rate is the total number of views of your video ad divided by impressions. The view rate is a good indicator of how compelling viewers find your video. The higher the view rate, the more engaged viewers are with your content.',
  },
  PAGES_PER_SESSION: {
    title: 'Pages Per Session',
    message:
      'Average number of pages a person views in a given session. This metric is calculated by dividing the number of screen page views by the total number of sessions.',
  },
  PERCENT_OF_TOTAL: {
    title: '% of Goal',
    message: 'Percentage of the Goal that was fulfilled = Impressions / Goal.',
  },
  LIFT_SPOT: {
    title: 'Lift per Spot',
    message:
      'The number of incremental increase in website visits or sales / the number of 15s, 30s and 60s ad spots that aired on TV',
  },
  LIFT_VISITS: {
    title: 'Lift Visits',
    message: 'The number of incremental increase in website visits due to an ad campaign.',
  },
  NEW_SESSIONS: {
    title: 'New Sessions',
    message: 'Number of Sessions by Users who had never visited the website before, reported by Google Analytics.',
  },
  NEW_SESSIONS_PERCENTAGE: {
    title: 'New Sessions',
    message: 'Percentage of Sessions by Users who had never visited the website before.',
  },
  OTT: {
    title: 'OTT (Over the Top)',
    message: 'Content delivered to viewers via streaming and on-demand video services.',
  },
  REACH: {
    title: 'Reach',
    message:
      'Number of people who saw your ads at least once. Reach is different from impressions, which may include multiple views of your ads by the same people.',
  },
  SEARCH_IMP_SHARE: {
    title: 'Search Impressions Share',
    message:
      'The percentage of impressions that an ad receives through Search compared to the estimated number of impressions that an ad could get.',
  },
  SEARCH_POSITION: {
    title: 'Position',
    message:
      'A relative ranking of the position of your link on Google, where 1 is the topmost position, 2 is the next position, and so on. See more details below.',
  },
  SESSIONS: {
    title: 'Sessions',
    message: `The total number of sessions that began on a website or application.
      A session is a period of time during which a user interacts with a website or app.
      This number includes "Engaged Sessions" which are the number of sessions that lasted 10 seconds or longer, or had 1 or more conversion events or 2 or more page or screen views.`,
  },
  SEM: {
    title: 'SEM (Search Engine Marketing)',
    message: 'Paid advertisements that appear in search engine results',
  },
  SPEND: {
    title: 'Spend',
    message: 'The dollar amount spent on a campaign.',
  },
  // GroundTruth
  ESTIMATED_VISITS: {
    title: 'Estimated Visits (Projected Store Visits)',
    message: `This is a probabilistic GroundTruth term. It applies a multiplier to the observed visits based on background data. For example, GT’s reach covers about
    40% of those with cellphones in the US. Assuming that there is another 60% unaccounted for, the multiplier uses data to fill in the gaps on visits that
    likely occurred due to their advertising, but that they were not able to track. This value is can be otherwise known as "Estimated Store Visits", rather than a projection.`,
  },
  STORE_VISITS: {
    title: 'Store Visits',
    message: `'Store Visits' only applies if this is a Foot Traffic Attribution campaign. In order to count a store visit a person must have been served a campaign ad previously, entered the customer store front, and was then served an additional ad while in that store.`,
  },
  FTA: {
    title: 'FTA',
    message: `Visits are recorded when a user is exposed to the mobile ad and later arrives within the blueprinted store location. This “raw” visitation metric is the actual observed measurement.
    Since the user must both see the ad and be on a mobile device when they visit the targeted location, visits reported will be lower than the actual visitors from ad spend.`,
  },
  GEO_FENCING_LOCATIONS: {
    title: 'Geofencing Locations',
    message: `Geofencing defines a set geographic perimeter around a location. Geo-fencing Locations are each of these locations. Oftentimes the output of this method is to prompt an
    advertisement based on location data signals derived through a mobile phone. These alerts are triggered when a mobile device enters the radius around a pre-established point of
    interest on a map and users have opted into the app’s location services. Geofencing is most often used in advertising to send an ad to a user through an app.`,
  },
  GEO_FENCING_LOCATIONS_SHORT: {
    title: 'Geofencing Locations',
    message: `Geofencing Locations defines a set geographic perimeter around a specific address.
    <br><br>Impressions targeted to “Behavior Audience”, “Retargeting Audience” and “Geotargeting” are not served to specific geofencing addresses, since these audiences are not at a specific business location in order to be served an ad. 
    The locations where these impressions are served are tracked and reported as latitude/longitude coordinates. That is what is provided by the ad platform when there is no business address available.`,
  },
  GEO_FENCING_LOCATIONS_BEESWAX: {
    title: 'Geofencing Locations',
    message: `Virtual fences or imaginary boundaries around specific addresses or buildings. Advanced location-based services are used to determine when a device being tracked is within or has exited the geofencing location.`,
  },
  GEO_FENCING_LOCATIONS_IMPRESSIONS: {
    title: 'Impressions',
    message: `This ad server attributes impressions to potentially multiple geofencing locations. That value is divided up across the multiple locations. This results in impressions reported with up to two decimal places.`,
  },
  GEOFENCING_LOCATIONS_OTHER: {
    title: 'Other',
    message: 'Geofencing locations that are not determinable by the ad serving platform.',
  },
  GEO_FENCING_CITIES: {
    title: 'Geofencing Cities',
    message: `Geofencing Cities defines a set geographic perimeter within a city.`,
  },
  GEOCOOKIE_IMPRESSIONS: {
    title: 'Historical Impressions',
    message: `'Historical Impressions' only applies if this is a Location Audience campaign. It represents the number of Impressions served to a targeted audience, based on visitations
    to select stores or a specific brand, or based on demographic information to form audience profiles which define consumer lifestyle and interests.`,
  },
  GEOBEHAVIOR_IMPRESSIONS: {
    title: 'Behavioral Impressions',
    message: `'Behavioral Impressions' only applies if this is a Behavior Audience campaign. It represents the number of Impressions served to a targeted audience, based on consumer visitation patterns.`,
  },
  GEORETARGETING_IMPRESSIONS: {
    title: 'Retargeting Impressions',
    message: `'Retargeting Impressions' only applies if this is a Retargeting campaign. It represents the number of Impressions served to a retargeted audience. Consumers that fall within the determined
    fence are captured. Once these users have received an ad, they’re targeted to receive a retargeting ad. This tactic is commonly deployed for event targeting. We would geofence the event, then retarget these users later.`,
  },
  GEOVIDEO_VCR: {
    title: 'GeoVideo VCR',
    message: `GeoVideo are location-aware video ads that are served to the right people at the right time and place. VCR (video completion rate) is the percentage of videos that were watched to completion.`,
  },
  GEOVIDEO_NODATA_FOOT_TRAFFIC: {
    title: 'No Data',
    message: `This module is blank, because this campaign is not targeted for Foot Traffic Attribution`,
  },
  GEOVIDEO_NODATA_AUDIENCE: {
    title: 'No Data',
    message: `This module is blank, because this campaign is not targeted for Location Audience, Behavior Audience or Retargeting Audience`,
  },
  GEOVIDEO_NODATA_GEO_FENCING: {
    title: 'No Data',
    message: `This module is blank, because this campaign has no geofencing locations data`,
  },
  GEOVIDEO_NODATA_PREVIOUS_GEO: {
    title: 'Possibly due to implementation date',
    message: `This module contains data only from April 2022 on, when the module was implemented`,
  },
  COST_PER_CREATIVE: {
    title: 'Cost',
    message: 'The cost of Impressions served by this Creative times the CPM (cost per thousand) set for this Campaign.',
  },
  // End GroundTruth

  TACTIC: {
    title: 'Tactic',
    message: 'Also known as "Product" in the Dashboard, such as Display, Preroll, OTT, Email, Facebook, etc.',
  },
  TIMES_AIRED: {
    title: 'Times Aired',
    message:
      'Number of times a Broadcast ad was aired on TV. Airings with spot duration less than 4.0 seconds are not counted.',
  },
  USERS: {
    title: 'Total Users',
    message: `The number of distinct users who have logged at least one event,
      regardless of whether the site or app was in use when that event was logged.`,
  },
  VC100: {
    title: 'Complete (Video Impressions)',
    message: 'Number of impressions where user completed video.',
  },
  VISITS: {
    // specific to broadcast
    title: 'Visits',
    message:
      `Number of visits tracked to the intended destination. This metric is only available if a Google Analytics account is connected to this Advertiser. ` +
      `We use Google Analytics Page Views (not Sessions/Visits), because Google Analytics can provide data for Page Views which occur within 8 minutes of the ad airing. ` +
      `Note that there could be individual days with “0” Visits, even if Google Analytics is connected, but you will see "0" for all days, if no Google Analytics account is connected.`,
  },
  VISITS_AIRING: {
    title: 'Visits Per Airing',
    message: 'Average number of visits for every time a broadcast ad was aired on TV.',
  },
  CLICK_TO_VIEW_RATE: {
    title: 'Click to View Rate',
    message: 'For Email Marketing campaigns, the percentage of times a user clicked on an ad (clicks/views).',
  },
  HH_IMPRESSIONS: {
    title: 'Household Impressions',
    message:
      'Household Impressions are impressions tracked in the attribution campaign associated to this linear campaign.',
  },
  BROADCAST_INNOVID_DAYPARTS: {
    title: 'Dayparts',
    message: `<ul>
    <li>Early Morning: 6:00AM-8:59AM (Mon-Fri)</li>
    <li>Daytime: 9:00AM-3:59PM (Mon-Fri)</li>
    <li>Early Fringe: 4:00PM-6:59PM (Mon-Fri)</li>
    <li>Primetime: 7:00PM-10:59PM (Mon-Sun)</li>
    <li>Late Fringe: 11:00PM-1:59AM (Mon-Sun)</li>
    <li>Overnight: 2:00AM-5:59AM (Mon-Sun)</li>
    <li>Weekend Morning: 6:00AM-11:59AM (Sat, Sun)</li>
    <li>Weekend Daytime: 12:00PM-6:59PM (Sat, Sun)</li>
    <li>Unknown</li>
    </ul>`,
  },
  BROADCAST_INNOVID_BYDAYOFWEEK: {
    title: 'Performance By Day Of Week',
    message: `This chart the distribution of household impressions across different days of the week.`,
  },
  BROADCAST_INNOVID_DAYPART: {
    title: 'Performance By Daypart',
    message: 'This chart the distribution of household impressions across different dayparts.',
  },
  BROADCAST_INNOVID_HHIMPRESSIONS: {
    title: 'Household Impressions',
    message: 'The number of all impressions to households from viewers who saw the ad on TV.',
  },
  BROADCAST_INNOVID_TVRESPONSES: {
    title: 'TV Responses',
    message: 'Total number of visitors driven to advertiser’s website as a result of viewers seeing the ad on TV.',
  },
  BROADCAST_INNOVID_AIRINGS: {
    title: 'Airings',
    message: 'The number of times the ad was shown on TV.',
  },
  BROADCAST_LINEAR_IMPS_SHARE: {
    title: 'Share Of Impressions',
    message: `Among all the impressions that were served in the campaign, this is the percentage that went to households 
    who were served Linear impressions only or CTV impressions only. Overlap is the percentage of impressions that were 
    served to households that were exposed to BOTH CTV and Linear impressions.`,
  },
  BROADCAST_LINEAR_REACH_PROPORTION: {
    title: 'Reach Proportion',
    message: `Among all of the HHs that were reached in the campaign, this is the percentage reached by Linear only or CTV only. 
    Overlap is the percentage of households that were exposed to BOTH CTV and Linear impressions.`,
  },
  BROADCAST_LINEAR_AVG_FREQ: {
    title: 'Avg Frequency',
    message: `The average number of CTV impressions served to each household exposed to Linear only or CTV only. 
    Overlap is the average number of CTV impressions that were exposed to BOTH CTV and Linear impressions.`,
  },
  BROADCAST_LINEAR_UNIQUE_REACH: {
    title: 'Unique Reach',
    message: `The number of unduplicated households reached by a single publisher or platform 
    divided by the number of households reached, displayed as a percentage.`,
  },
  BROADCAST_LINEAR_OVERLAP: {
    title: 'Overlap',
    message: `The percentage of impressions that were served to households that were exposed to BOTH CTV and Linear impressions.`,
  },
  // YouTube / Google Video
  GOOGLEVIDEO_PUBLISHERS: {
    title: 'Publishers',
    message: 'Publishers are websites where the YouTube video ad appeared.',
  },
  IMPRESSIONS_NOTE: {
    title: 'NOTE',
    message:
      'The Google API does not provide data by Publisher for every Impression served. So, the sum of Impressions in this module will not match the Impressions shown in the “Campaign Summary” module.',
  },
  GOOGLEVIDEO_ENGAGEMENTS: {
    title: 'Engagements',
    message:
      'Engagements show you the number of times users have watched your video ad for at least 10 seconds, or the complete video ad if it is shorter than 10 seconds.',
  },
  GOOGLEVIDEO_ENGAGEMENT_RATE: {
    title: 'Engagement Rate',
    message:
      'Engagement rate is the number of engagements that your ad receives divided by the number of times your ad is shown.',
  },
  GOOGLEVIDEO_VIEW_RATE: {
    title: 'View Rate',
    message:
      'The number of views a TrueView video ad receives divided by its number of impressions, including thumbnail impressions for TrueView in-display ads.',
  },
  GOOGLEVIDEO_25PERCENT_VIEWED: {
    title: '25% Viewed',
    message: 'First Quartile (Q1): The number of impressions where the user watched 25% of the video ad.',
  },
  GOOGLEVIDEO_50PERCENT_VIEWED: {
    title: '50% Viewed',
    message: 'Second Quartile (Q2): The number of impressions where the user watched 50% of the video ad.',
  },
  GOOGLEVIDEO_75PERCENT_VIEWED: {
    title: '75% Viewed',
    message: 'Third Quartile (Q3): The number of impressions where the user watched 75% of the video ad.',
  },
  GOOGLEVIDEO_100PERCENT_VIEWED: {
    title: '100% Viewed',
    message:
      'The number of impressions where the viewer watched all of the video ad. A user could watch a video ad to its completion multiple times, which increments this value, while only one impression is counted.',
  },
  GOOGLEVIDEO_CTR: {
    title: 'CTR (Click Through Rate)',
    message: 'Percentage of times a user clicked on an ad (clicks/impressions).',
  },
  // TVSquared
  TVSQUARED_IMPRESSIONS: {
    title: 'Impressions	',
    message: 'Number of times an ad was served',
  },
  TVSQUARED_RESPONSES: {
    title: 'Responses	',
    message: 'Number of times a user responded to an ad that was served to them',
  },
  TVSQUARED_RESPONSE_RATE: {
    title: 'Response Rate',
    message:
      'Percentage of Impressions that resulted in a Response (Responses / Impressions). A Response can be either an Appointment Request or when Contact Information was provided.',
  },
  TVSQUARED_APPOINTMENT_REQUEST_CONVERSION_RATE: {
    title: 'Appointment Request Conversion Rate',
    message:
      'Percentage of Impressions that resulted in a request for an appointment (Appointment Request Responses / Impressions)',
  },
  TVSQUARED_CONTACT_INFO_CONVERSION_RATE: {
    title: 'Contact Info Conversion Rate',
    message:
      'Percentage of Impressions that resulted in a user providing their contact information (Contact Info Responses / Impressions)',
  },
  TVSQUARED_IMPRESSION_SHARE: {
    title: 'Impression Share',
    message:
      'Percentage of Impressions served by a Creative compared to the total number of Impressions for all Creatives (Impressions / Total Impressions)',
  },
  TVSQUARED_RESPONSE_SHARE: {
    title: 'Response Share',
    message:
      'Percentage of Responses as a result of a Creative Impression compared to the total number of Responses as a result of all Creative Impressions (Responses / Total Responses)',
  },
  TVSQUARED_RESPONSES_OVER_TIME: {
    title: 'Responses Over Time',
    message: 'Number of days from the time the ad was served until the day the user responsed to the ad',
  },
  TVSQUARED_HOUSEHOLDS_REACHED: {
    title: 'Households Reached',
    message: 'Number of unique households which saw an ad',
  },
  TVSQUARED_AVERAGE_IMPRESSIONS_PER_HOUSEHOLD: {
    title: 'Average Impressions Per Household',
    message: 'Number of total Impressions served divided by the number of unique Households served',
  },
  TVSQUARED_TOTAL_MEDIA_SPEND: {
    title: 'Total Media Spend',
    message: 'Dollar amount spent on this campaign',
  },
  TVSQUARED_AIRINGS: {
    title: 'Airings',
    message: 'The number of times this ad aired on TV',
  },
  TVSQUARED_CPM: {
    title: 'CPM',
    message: 'Cost per thousand (mille) impressions',
  },
  TVSQUARED_HOUSEHOLD_IMPRESSIONS: {
    title: 'Household Impressions',
    message:
      'Household Impressions are impressions tracked in the attribution campaign associated to this CTV campaign.',
  },
  TVSQUARED_RESPONSES_FROM_TV: {
    title: 'TV Responses',
    message: 'Total number of visitors driven to advertiser’s website as a result of viewers seeing the ad on TV.',
  },
  TVSQUARED_COST_PER_RESPONSE: {
    title: 'CPR',
    message: '“Cost Per Response (CPR)” is “Spend $” divided by the number of total Responses',
  },
  TVSQUARED_RESPONSE_PERCENTAGE: {
    title: 'Response Rate',
    message: 'Number of TV Responses divided by Household Impressions.',
  },
  // Display
  DISPLAY_PERF_BY_SITE_OTHER: {
    title: 'Other',
    message: 'Consolidation of all Sites beyond the Top {count}.',
  },
  OTHER: {
    title: 'Other',
    message: 'Consolidation of records that could not be categorized',
  },
  MANAGED_NETWORK: {
    title: 'Managed Networks',
    message:
      'Uses a highly curated list of sites that index high with the desired audience. Examples: Mom network, Sports, Arts & Entertainment, Political and others.',
  },
  AUDIENCE_TARGETING: {
    title: 'Audience Targeting',
    message:
      'Serves ads utilizing 3rd party data sources to target specific audiences by behavior, demographic, interest and intent. Examples: Gender, Household Income, Health Enthusiasts, Parents and others.',
  },
  RETARGETING: {
    title: 'Retargeting',
    message:
      'Ads are delivered to your most valuable customers and prospects after they visit your website. Less than 2% of consumers convert on a first-time visit. Conversions can go up as much as 300% with retargeting.',
  },
  VIEWS: {
    title: 'Views',
    message:
      'Views show you the number of times people watched or engaged with your video ad. For campaigns with non-skippable ads, Views is always "0".',
  },
  EMAIL_VIEWS: {
    title: 'Views',
    message:
      'For Email Marketing campaigns, Views show you the number of times people engaged with your email message to generate impressions.',
  },
  LINEAR_OTHER_LEAGUE_TEAMS: {
    title: 'Other',
    message:
      'The "League" and "Team" names are listed for impressions served in live in-game broadcasts or pregame/postgame studio shows. Where "Other" appears, those impressions were served against ROS (run-of-site) units outside of the live broadcasts.',
  },
  // CTV and Digital Video
  OTTDVIDEO_REACH: {
    title: 'Reach',
    message:
      'Unique households reached based on CTV IP addresses for the lifetime of the campaign. It is not date-specific.',
  },
  OTTDVIDEO_FREQUENCY: {
    title: 'Frequency',
    message:
      'Average number of times the ad was shown to the same household for the lifetime of the campaign. It is not date-specific.',
  },
  NETWORK_LOGOS: {
    title: 'Informational only',
    message:
      `This module is informational only. No performance metrics are included. The networks/channels shown herein are a small sample of the networks where this campaign is being served across all publishers. ` +
      `The lack of a network logo does not indicate the lack of impressions on that network and the inclusion of a network logo does not indicate the delivery of impressions only to that network. This list is alphabetical and is not ranked by the number of impressions served.`,
  },
  OTT_PLATFORM: {
    title: 'Performance By Platform',
    message: `“Other” includes the number of Impressions served on all Platforms that are NOT in the “Top 9” Platforms.
      “Other” can include Impressions served on other Samsung models, Amazon models, LG TV models, etc.
      There could be a few hundred platforms where impressions were served.
      Impressions from all these other Platforms are combined into “Other”.`,
  },
  // Digital Video
  VIDEO_IMPRESSIONS: {
    title: 'Video Impressions',
    message: 'Impressions only from online video, not from OTT devices.',
  },
  DVIDEO_CTR: {
    title: 'CTR (Click Through Rate)',
    message: 'CTR is Clicks divided by Video Impressions. It does not include OTT Impressions.',
  },
  DVIDEO_IMPRESSIONS: {
    title: 'Impressions',
    message:
      'Impressions for Digital Video is the total impressions for online video plus total impressions for OTT devices.',
  },
  // Summary
  LAST_UPDATED: {
    title: 'Last Updated',
    message: `The date when this tactic's data feed was last processed.<br />It doesn't necessarily mean that data for this campaign is complete.<br />Contact Support if you suspect that some data is missing.`,
  },
  CAMPAIGN_SUMMARY_DELAYED: {
    title: 'ATTENTION',
    message: `As of Sep 1, 2023, data from the ad serving platform for “Geofence - Display”, “Geofence - Video” and “True Geo” campaigns
    will be lagging by one day. For example, data from yesterday’s results will not appear in Dashboard until tomorrow. Rather than having reporting available within 12 hours of the day ending, it’ll now be up to 36 hours after the day ends.`,
  },
  // BROADCAST_TV
  DAYPART_BROADCAST: {
    title: 'Dayparts',
    message: `<ul>
    <li>Overnight: 2:00AM–5:00AM</li>
    <li>Early Morning: 5:00AM–9:00AM</li>
    <li>Daytime: 9:00AM–3:00PM</li>
    <li>Early Fringe: 3:00PM–5:00PM</li>
    <li>Early News: 5:00PM–7:00PM</li>
    <li>Prime Access: Monday-Saturday 7:00PM–8:00PM</li>
    <li>Prime: Sunday 7:00PM-8:00PM or Monday-Sunday 8:00PM-23:30PM</li>
    <li>Late Fringe: 23:30PM–2:00AM (next day)</li>
    </ul>`,
  },
  // Other and CSV/XLSX Export for Device Type
  OTHER_DEVICE_TYPE: {
    title: 'Other',
    message:
      'Represents Device Types that are unknown and cannot be categorized into one of the other Device Types listed.',
  },
  CSV_XLSX_EXPORT_DEVICE_TYPE: {
    title: 'CSV/XLSX export',
    message: 'Contains all Device Types.',
  },
  // Other and CSV/XLSX Export tips for CTV by Publisher
  OTHER_OTT_CTV_PUBLISHER: {
    title: 'Other',
    message: `In this module, it represents the sum of all the Publishers that are not amongst the top 99 Publishers.
      This module displays only 100 records.`,
  },
  CSV_XLSX_EXPORT_OTT_CTV_PUBLISHER: {
    title: 'CSV/XLSX export',
    message: `Contains only 100 Publishers. "Other" in the exports represents Publishers that are unknown and cannot
        be categorized into one of the other Publishers listed or are not amongst the top 99 Publishers.`,
  },
  // Other and CSV/XLSX Export for Digital Video
  OTHER_DIGITAL_VIDEO: {
    title: 'Other',
    message: `In this module, it represents the sum of all the Publishers that are not amongst the top 99 Publishers.
        This module displays only 100 records.`,
  },
  CSV_XLSX_EXPORT_DIGITAL_VIDEO: {
    title: 'CSV/XLSX export',
    message: `Contains only 100 Publishers. "Other" in the exports represents Publishers that are unknown and cannot
        be categorized into one of the other Publishers listed or are not amongst the top 99 Publishers.`,
  },
  // Other and CSV/XLSX Export tips for CTV by Perfomance Device Type
  OTHER_OTT_CTV_DEVICE_TYPE: {
    title: 'Other',
    message:
      `"Other" represents those which come back to Dashboard in the data feed as not being Mobile, Desktop, Connected TV, Digital Home Assistant or is not provided by the device back to us. ` +
      `Some devices and some users, for privacy purposes, don't reveal what device they are, so those come to Dashboard as "Unknown" or "Other" and we combined all of these cases into just "Other".`,
  },
  CSV_XLSX_EXPORT_OTT_CTV_DEVICE_TYPE: {
    title: 'CSV/XLSX export',
    message: `Contains all Device Types. "Other" in the exports represents Device Types that are unknown and
        cannot be categorized into one of the other Device Types listed.`,
  },
  // Other and CSV/XLSX Export tips for CTV by Perfomance Platform
  OTHER_OTT_CTV_PLATFORM: {
    title: 'Other',
    message: 'In this module, it represents the sum of all the Platforms that are not amongst the top 5 Platforms.',
  },
  CSV_XLSX_EXPORT_OTT_CTV_PLATFORM: {
    title: 'CSV/XLSX export',
    message: `Contains all Platforms. "Other" in the exports represents Platforms that are unknown and cannot be
        categorized into one of the other Platforms listed.`,
  },
  // Other and CSV/XLSX Export tips for CTV by Zip Code
  OTHER_OTT_CTV_ZIP_CODE: {
    title: 'Other',
    message: `In this module, it represents the sum of all the Zip/Postal Codes that are not amongst the top 99
        Zip/Postal Codes. This module displays only 100 records.`,
  },
  CSV_XLSX_EXPORT_OTT_CTV_ZIP_CODE: {
    title: 'CSV/XLSX export',
    message: `Contains only 100 Zip/Postal Codes. "Other" in the exports represents Zip/Postal Codes that are
        unknown and cannot be categorized into one of the other Zip/Postal Codes listed or are not
        amongst the top 99 Zip/Postal Codes.`,
  },
  // Orders
  ORDERS_PRODUCTS_OVERVIEW: {
    title: 'Products Overview',
    message: '% of Total Impressions across each Product',
  },
  ORDERS_BY_WEEKDAY: {
    title: 'Performance By Day Of Week',
    message: 'See which day of the week performs well for this campaign',
  },
  ORDERS_BY_GEO: {
    title: 'Performance By Geo',
    message: 'Geographic location, such as city and state',
  },
  // Retargeting Summary
  RETARGETING_SUMMARY: {
    title: 'Retargeting Summary',
    message: 'Retargeting campaigns associated with this campaign.',
  },
};

export default {
  METRIC_TOOLTIPS,
};
