
import Vue from 'vue';
import utils from '../../util';
import analytics from '../../mixins/analytics';
import {
  AdvertiserAgencyQuery,
  Report,
  GetReportPayload,
  Advertiser,
  KeyContacts,
  Campaigns,
  AdvertiserProducts,
  ReportProducts,
  Campaign,
} from '../../store/modules/scheduledreports/types';
import { AdvertiserInfo } from '@point/utility-classes';

export default Vue.extend({
  name: 'report',
  mixins: [analytics],
  data: (): {
    report: Report | null;
    success: string | null;
    reportError: string | null;
    contacts: Array<string>;
    products: Array<string>;
    missingProducts: boolean;
    errorProducts: boolean;
    campaigns: Array<Campaign>;
    advertiserTheme: string;
  } => ({
    report: null,
    success: null,
    reportError: null,
    contacts: [],
    products: [],
    missingProducts: false,
    errorProducts: false,
    campaigns: [],
    advertiserTheme: 'agency',
  }),
  components: { ReportUi: () => import('./reportUI.vue') },
  created(): void {
    if (this.isUpdate) {
      // if we have a report/edit mode
      // get the details
      this.getSingleReport();
    } else if (this.$route.query.advertiser) {
      // if we know the advertiser, but are creating a new report
      // get full advertiser details
      this.getAdvertiserDetails(this.$route.query.advertiser, true);
      // get product types from campaign API.
      this.getCampaigns(this.$route.query.advertiser);
    } else {
      // new report
      this.newReport();
    }
  },
  methods: {
    async getAdvertiserDetails(id: string, newReport: boolean): Promise<void> {
      const details: { data: AdvertiserInfo } = await this.$store.dispatch('advertiser/getAdvertiserInfo', { id });
      if (!details?.data?.name) return;
      this.advertiserTheme = details.data.Theme || 'agency';
      // only if a new report
      if (newReport) {
        // create a blank report with the known advertiser details filled in
        const report: Report = {
          ParentAdvertiser: {
            PropertyId: details.data?.PropertyId,
            Name: details.data?.name,
            AgencyName: details?.data?.AgencyPartnerName || 'No agency name',
            AgencyPartner: details?.data?.AgencyPartner,
            SubAgencyPartner: details?.data?.SubAgencyPartner || 'No subagency partner name',
            Theme: this.advertiserTheme,
          },
          ReportDateRangeKind: 'AllTime',
          Products: [],
          EmailTo: [],
          PropertyId: '',
        };
        this.products = [];
        this.contacts = [];
        this.errorProducts = false;
        this.missingProducts = false;
        Vue.set(this, 'report', report);
      }
      // get a array of emails
      if (details.data?.KeyContacts) {
        this.contacts = details.data.KeyContacts.reduce((acc: Array<string>, x: KeyContacts): Array<string> => {
          if (x.Email && x.Email !== '') acc.push(x.Email);
          // remove duplicates
          return Array.from(new Set(acc));
        }, []);
      }
    },
    availableProducts(products): Array<ReportProducts> {
      if (!products) return [];
      return products.reduce((acc: Array<ReportProducts>, x: AdvertiserProducts) => {
        if (x.label) {
          acc.push({
            ReportName: utils.getTacticName(x.label),
            ReportValue: x.label,
          });
        }
        return utils.sortByProperty(acc, 'ReportName');
      }, []);
    },
    async getCampaigns(id: string): Promise<void> {
      // need set a the cache parameter to false to get product data
      await this.$store
        .dispatch('getCampaigns', {
          advertiserId: id,
          daterange: '',
          startdate: '',
          enddate: '',
          campaigns: [],
          cache: false,
        })
        .then((camps: { data: Campaigns }) => {
          // get a array of the campiagn types
          if (camps?.data?.CampTypes) {
            this.products = this.availableProducts(camps.data?.CampTypes);
            if (!this.products.length) this.missingProducts = true;
          } else this.missingProducts = true;

          if (camps?.data?.campaignlist) {
            this.campaigns = camps.data.campaignlist;
          }
        })
        .catch(() => {
          this.errorProducts = true;
        });
    },
    async getSingleReport(): Promise<void> {
      const payload: GetReportPayload = {
        reportId: this.$route.query.reportId,
      };
      if (this.$route.query.advertiser) payload.advertiser = this.$route.query.advertiser;
      const details: Report = await this.$store.dispatch('scheduledReports/getSingleReport', payload);
      if (!details?.PropertyId) return;
      const advertiserID: string = details?.ParentAdvertiser?.PropertyId;
      // get the contacts and products
      await this.getAdvertiserDetails(advertiserID, false);
      this.getCampaigns(advertiserID);
      // change old values from None to Last.
      if (details?.ReportDateRangeKind === 'None') details.ReportDateRangeKind = 'Last';
      Vue.set(this, 'report', details);
    },
    async deleteReport(reportId: string): Promise<void> {
      await this.$store
        .dispatch(`scheduledReports/deleteReport`, {
          reportId,
          requestedBy: this.$store.getters.user.email,
        })
        .then(() => {
          // backend needs time to process.
          setTimeout(() => this.goBack(), 2000);
          this.analyticDeleteReportTrack(
            this.trackValue.EVENT_MANAGE_REPORTS,
            this.trackValue.TRACK_REPORT_DELETE,
            reportId,
            this.$store.getters.user.email,
          );
        });
    },
    newReport(): void {
      // set a blank report
      const report: Report = {
        ParentAdvertiser: {
          PropertyId: '',
          Name: '',
          AgencyPartner: '',
          AgencyName: '',
          SubAgencyPartner: '',
          Theme: 'agency',
        },
        Products: [],
        EmailTo: [],
        ReportDateRangeKind: 'AllTime',
      };
      this.report = report;
      this.products = [];
      this.contacts = [];
      this.errorProducts = false;
      this.missingProducts = false;
    },
    resetAdvertiser(): void {
      const report: Report = {
        ParentAdvertiser: {
          PropertyId: '',
          Name: '',
          AgencyPartner: '',
          AgencyName: '',
          SubAgencyPartner: '',
          Theme: 'agency',
        },
        Products: [],
        EmailTo: this.report.EmailTo,
        Format: this.report.Format,
        IntervalType: this.report.IntervalType,
        MinuteOfHour: this.report.MinuteOfHour,
        OneTimeOnly: this.report.OneTimeOnly,
        ReportDateRangeKind: this.report.ReportDateRangeKind,
        Name: '',
        Campaigns: [],
      };
      if (this.report.DayOfWeek) {
        report.DayOfWeek = this.report.DayOfWeek;
      }
      if (this.report.DayOfMonth) {
        report.DayOfMonth = this.report.DayOfMonth;
      }
      if (this.report.DayOfQuarter) {
        report.DayOfQuarter = this.report.DayOfQuarter;
      }
      this.report = report;
      this.products = [];
      this.contacts = [];
      this.errorProducts = false;
      this.missingProducts = false;
      this.$router.replace({ query: null });
    },
    goBack(): void {
      const query: AdvertiserAgencyQuery = {};
      if (this.$route.query.agency) query.agency = this.$route.query.agency;
      else if (this.$route.query.advertiser) query.advertiser = this.$route.query.advertiser;
      query.tab = 'advertiser_reports';
      this.$store.dispatch('scheduledReports/setSuccessMessage', this.success);
      this.$router.push({ name: 'scheduledreports', query });
    },
    saved(name: string): void {
      this.success = this.isUpdate
        ? `The report ${name} has been updated successfully.`
        : `The report ${name} has been scheduled successfully.`;
      this.dismissAlerts();
      this.goBack();
    },
    onError(): void {
      this.reportError = 'Error occured, please check report data and try again.';
      this.dismissAlerts();
    },
    dismissAlerts(): void {
      if (this.success) {
        setTimeout(() => {
          this.success = null;
        }, 5000);
      }
      if (this.reportError) {
        setTimeout(() => {
          this.reportError = null;
        }, 5000);
      }
    },
  },
  computed: {
    contactList(): Array<string> {
      const existing = this.report && this.report.EmailTo ? this.report.EmailTo : [];
      // merge current user, existing report contacts, and key contacts into single array.
      const contactArrays = [...[this.userEamil], ...this.contacts, ...existing];
      return Array.from(new Set(contactArrays));
    },
    missing(): string | null {
      if (this.missingProducts) return 'Advertiser missing products';
      if (!this.contactList.length) return 'Advertiser missing contacts';
      return null;
    },
    error(): string {
      if (this.errorProducts) return 'Error getting products';
      return this.$store.state?.scheduledReports?.error;
    },
    showSuccess(): boolean {
      return this.success !== null;
    },
    showError(): boolean {
      return this.reportError !== null;
    },
    isUpdate(): boolean {
      return this.$route.query?.reportId?.length > 1;
    },
    userEamil(): object {
      return this.$store?.state?.customer?.user?.email;
    },
    loading(): boolean {
      return this.$store.state?.scheduledReports?.loading;
    },
  },
});
