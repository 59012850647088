/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios';
import CONSTANTS from './constants';
import { logError } from '../../index';
import { session_id } from '../../../main';

const getExportConversions = async ({ commit }, payload): Promise<unknown> => {
  const {
    advertiserId,
    campaignDashboardIds,
    resolution,
    daterange,
    type,
    startdate = '',
    enddate = '',
    token,
  } = payload;
  commit(CONSTANTS.SET_EXPORT_CONVERSIONS_ERROR, null);

  if (!advertiserId || !daterange || !campaignDashboardIds || !resolution || !type) {
    commit(CONSTANTS.SET_EXPORT_CONVERSIONS_ERROR, 'Missing parameters');
    return;
  }

  let conversionsUrl;
  const headers = { session_id };

  if ((!startdate || !enddate) && daterange) {
    conversionsUrl = `/api/export/getConversions?advertiserId=${advertiserId}&daterange=${daterange}&campaignDashboardIds=${campaignDashboardIds}&resolution=${resolution}&type=${type}`;
  } else {
    conversionsUrl = `/api/export/getConversions?advertiserId=${advertiserId}&campaignDashboardIds=${campaignDashboardIds}&resolution=${resolution}&type=${type}&startDate=${startdate}&endDate=${enddate}`;
  }

  if (token) {
    conversionsUrl += `&token=${token}`;
  }

  try {
    commit(CONSTANTS.SET_IS_EXPORT_CONVERSIONS_LOADING, true);
    const response: AxiosResponse<any> = await axios.post(conversionsUrl, { headers }, { responseType: 'blob' });
    return response;
  } catch (exp) {
    commit(CONSTANTS.SET_EXPORT_CONVERSIONS_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.export.getExportConversions');
  } finally {
    commit(CONSTANTS.SET_IS_EXPORT_CONVERSIONS_LOADING, false);
  }
};

const getExportConversionsBreakdown = async ({ commit }, payload): Promise<unknown> => {
  const { advertiserId, campaignsIds, type, daterange, exportType, startdate = '', enddate = '', token } = payload;
  commit(CONSTANTS.SET_EXPORT_CONVERSIONS_BREAKDOWN_ERROR, null);

  if (!advertiserId || !daterange || !campaignsIds || !exportType) {
    commit(CONSTANTS.SET_EXPORT_CONVERSIONS_BREAKDOWN_ERROR, 'Missing parameters');
    return;
  }

  const campaigns = campaignsIds?.map(c => {
    return {
      id: c,
      type: type,
    };
  });

  const body: Record<string, unknown> = {
    advertiserId,
    daterange,
    campaigns,
    exportType,
  };

  const headers = { session_id };
  const conversionBreakdownUrl = `/api/export/getConversionBreakdown`;

  if (!startdate || !enddate) {
    body.daterange = daterange;
  } else {
    body.startDate = startdate;
    body.endDate = enddate;
    body.daterange = 'alltime';
  }

  if (token) {
    body.token = token;
  }
  try {
    commit(CONSTANTS.SET_IS_EXPORT_CONVERSIONS_BREAKDOWN_LOADING, true);
    const response: AxiosResponse<any> = await axios.post(conversionBreakdownUrl, body, {
      responseType: 'blob',
      headers,
    });
    return response;
  } catch (exp) {
    commit(CONSTANTS.SET_EXPORT_CONVERSIONS_BREAKDOWN_ERROR, exp?.response?.data || exp?.message);
    throw logError(exp, 'store.export.getExportConversions');
  } finally {
    commit(CONSTANTS.SET_IS_EXPORT_CONVERSIONS_BREAKDOWN_LOADING, false);
  }
};

export default {
  getExportConversions,
  getExportConversionsBreakdown,
};
